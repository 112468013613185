import type { ApolloClient } from '@apollo/client';

import { GET_NOTIFICATIONS_SETTINGS } from '../graphql/localQueries/notifications';

interface GetNotificationsSettingsResponse {
  notificationsSettings: {
    disableComputer: boolean;
    disableTab: boolean;
    muteComputer: boolean;
  };
}

interface NotificationsSettings {
  disabled: boolean;
  muted: boolean;
}

/**
 *
 * @param client
 */
export function getSettings(client: ApolloClient<any>): NotificationsSettings {
  const data = client.readQuery<GetNotificationsSettingsResponse>({
    query: GET_NOTIFICATIONS_SETTINGS
  });

  return {
    disabled:
      data?.notificationsSettings.disableComputer ||
      data?.notificationsSettings.disableTab ||
      false,
    muted: data?.notificationsSettings.muteComputer || false
  };
}
