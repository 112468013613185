import { FC } from 'react';

import {
  JsonContentElement,
  JsonContentElementProps
} from '../../../organisms/JsonContent/JsonContent';

import { supported } from './supportedComponents';

export const JsonLayers: FC<{ content?: JsonContentElementProps }> = (
  props
) => {
  if (!props.content) {
    return null;
  }

  try {
    return JsonContentElement('map-layers', supported)(props.content);
  } catch (error) {
    console.error(error);
  }

  return null;
};
