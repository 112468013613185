import { ApolloClient, FetchResult, Observable } from '@apollo/client';
import { IconName } from '@blueprintjs/icons';

import { REMOTE_COMMAND } from '../../graphql/queries/remote-command';
import { addNotification } from '../add';
import {
  NotificationHandler,
  NotificationIntent,
  NotificationSound
} from '../notification-types';

interface RemoteAlertData {
  body?: string;
  force?: boolean;
  handler?: [NotificationHandler, ...any[]];
  icon?: IconName;
  intent?: NotificationIntent;
  message: string;
  sound?: NotificationSound;
  timeout?: number;
  title: string;
}

type RemoteAlertCommand = FetchResult<{
  remoteCommand: {
    command: 'ALERT' | 'LOGOUT';
    data: string | RemoteAlertData;
  };
}>;

/**
 *
 * @param client
 */
export function remoteCommands(client: ApolloClient<any>): Promise<() => void> {
  const remoteCommandObserver: Observable<RemoteAlertCommand> =
    client.subscribe({
      query: REMOTE_COMMAND
    });

  const subscription = remoteCommandObserver.subscribe(
    (message: RemoteAlertCommand): void => {
      if (!message.data) {
        return;
      }

      const command: string = message.data.remoteCommand.command;
      let data: RemoteAlertData | null = null;

      if (typeof message.data.remoteCommand.data === 'string') {
        try {
          data = JSON.parse(message.data.remoteCommand.data);
        } catch (error) {
          console.error(error);
          // do nothing
        }
      } else {
        data = message.data.remoteCommand.data;
      }

      if (command !== 'ALERT' || !data) {
        return;
      }

      const values = new Uint8Array(8);
      window.crypto.getRandomValues(values);

      addNotification(client, {
        handler: null,
        id: Array.from(values)
          .map((value: number) => value.toString(16))
          .join(''),
        ...data,
        message: data.message || data.body || 'Message'
      });
    }
  );

  return Promise.resolve(() => subscription.unsubscribe());
}
