import { FC, Fragment, memo } from 'react';
import MarkerClusterGroup from 'react-leaflet-cluster';

import { getClusterIcon } from '../Cluster/Cluster';
import type { Marker } from '../types';

import { Marker as MarkerEl } from './Marker';

export interface MarkerWrapperOptions {
  useMarkerCluster?: boolean;
}

interface MarkerWrapperProps {
  markers?: Marker[];
  options?: MarkerWrapperOptions;
}

export const MarkerWrapper: FC<MarkerWrapperProps> = memo((props) => {
  if (!props.markers?.length) {
    return null;
  }

  const markers = props.markers?.map((marker) => (
    <MarkerEl
      {...marker.props}
      position={[marker.lat, marker.lng]}
      key={marker.id}
      onClick={marker.onClick}
    />
  ));

  if (props.options?.useMarkerCluster) {
    return (
      <MarkerClusterGroup
        iconCreateFunction={(cluster: any) =>
          getClusterIcon(cluster.getChildCount())
        }
        maxClusterRadius={20}
        spiderfyDistanceMultiplier={1.5}
        spiderfyOnMaxZoom={true}
      >
        {markers}
      </MarkerClusterGroup>
    );
  }

  return <Fragment>{markers}</Fragment>;
});
