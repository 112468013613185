import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import Map, { MapProps } from './Map';
import { MapError } from './MapError';

/**
 *
 * @param props
 */
export const MapWrapper: FC<MapProps> = (props) => (
  <ErrorBoundary FallbackComponent={() => <MapError {...props} />}>
    <Map {...props} />
  </ErrorBoundary>
);
