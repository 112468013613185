import { Card, Elevation } from '@blueprintjs/core';
import { useEffect, useState, FC } from 'react';

import { Map, pointsToBounds } from '../../../../molecules/Map';
import type {
  HeatmapLocation,
  MapOptions,
  Marker
} from '../../../../molecules/Map';
import { useHeatmap } from '../InsightsProvider/useHeatmap';
import { useStreams } from '../InsightsProvider/useStreams';
import { useSelected } from '../SelectedProvider/useSelected';

import styles from './InsightsMap.module.scss';

/**
 *
 * @param props
 */
export const InsightsMap: FC = () => {
  const [selected] = useSelected();
  const streams = useStreams();
  const weights = useHeatmap();

  const [options, setOptions] = useState<MapOptions>({
    center: {
      lat: 0,
      lng: 0
    },
    zoom: 1
  });
  const [heatmap, setHeatmap] = useState<HeatmapLocation[]>([]);
  const [markers, setMarkers] = useState<Marker[]>([]);

  // Sync heatmap
  useEffect(() => {
    /* eslint-disable security/detect-object-injection */

    const h = Object.entries(weights)
      .filter(([id]) => streams[id])
      .map<HeatmapLocation>(([id, weight]) => {
        const stream = streams[id];

        return {
          id,
          lat: stream.lat,
          lng: stream.lon,
          weight
        };
      });

    if (h.length === 0) {
      return;
    }

    setHeatmap(h);
    setOptions((o) => ({
      ...o,
      bounds: pointsToBounds(h),
      center: undefined,
      zoom: undefined
    }));
  }, [streams, weights]);

  // Change map on selected change
  useEffect(() => {
    if (!selected) {
      return setMarkers([]);
    }

    const s = Object.values(streams).filter(
      (stream) => stream.id === selected || stream.siteId === selected
    );

    // Stream
    if (s.length === 1) {
      setMarkers([
        {
          id: s[0].id,
          lat: s[0].lat,
          lng: s[0].lon
        }
      ]);

      return setOptions((o) => ({
        ...o,
        bounds: undefined,
        center: { lat: s[0].lat, lng: s[0].lon },
        zoom: 14
      }));
    }

    // Site
    const m: Marker[] = [];

    for (const stream of s) {
      m.push({
        id: stream.id,
        lat: stream.lat,
        lng: stream.lon
      });
    }

    setMarkers(m);
    setOptions((o) => ({
      ...o,
      bounds: pointsToBounds(m),
      center: undefined,
      zoom: undefined
    }));
  }, [selected, streams]);

  return (
    <Card className={styles.card} elevation={Elevation.ONE}>
      <Map
        heatmap={selected ? [] : heatmap}
        markers={selected ? markers : []}
        options={options}
      />
    </Card>
  );
};
