import { FC, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { CurrentUserContext } from '../../context/current-user/current-user-context';

export const SignOut: FC = () => {
  const { currentUser, logout } = useContext(CurrentUserContext);
  const location = useLocation();

  if (currentUser) {
    logout();
  }

  return (
    <Navigate
      state={{ ignoreRedirect: true }}
      to={location.pathname}
      replace={true}
    />
  );
};
