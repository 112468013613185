import { RefObject, useCallback, useEffect, useState } from 'react';

import { captureError } from '../utils/sentry';

interface UseFullscreenResult {
  isFullscreen: boolean;
  request: () => void;
}

/**
 *
 * @param ref
 */
export function useFullscreen(
  ref: RefObject<HTMLElement>
): UseFullscreenResult {
  const [isFullscreen, setFullscreen] = useState<boolean>(false);

  /**
   *
   */
  const request = useCallback((): void => {
    ref.current?.requestFullscreen().catch(captureError);
  }, [ref]);

  /**
   *
   */
  useEffect((): void => {
    ref.current?.addEventListener(
      'fullscreenchange',
      (): void => {
        setFullscreen(
          Boolean(ref.current) && document.fullscreenElement === ref.current
        );
      },
      false
    );
  }, [ref]);

  return {
    isFullscreen,
    request
  };
}
