import { useQuery } from '@apollo/client';

import { SEARCH_PLACES, SEARCH_STREAMS } from './query';
import { SearchResult } from './types';

interface Site {
  id: string;
  name: string;
}

interface Place {
  id: string;
  lat: number;
  lon: number;
  primary: string;
  secondary: string;
  text: string;
  type: 'generic' | 'google';
}

interface Stream {
  active: boolean;
  id: string;
  name: string;
  site: Site;
}

interface SearchPlacesResult {
  searchPlaces: Place[];
}

interface SearchStreamsResponse {
  searchStreams: Stream[];
}

/**
 *
 * @param query
 */
export function useSearchResults(query: string): SearchResult[] {
  const { data: streamsData } = useQuery<SearchStreamsResponse>(
    SEARCH_STREAMS,
    {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      skip: query.length < 3,
      variables: { active: true, query }
    }
  );

  const { data: placesData } = useQuery<SearchPlacesResult>(SEARCH_PLACES, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    skip: query.length < 3,
    variables: { query }
  });

  if (!streamsData?.searchStreams && !placesData?.searchPlaces) {
    return [];
  }

  return [
    ...(streamsData?.searchStreams || [])
      .filter((stream) => stream.active)
      .map<SearchResult>((stream: Stream) => ({
        id: stream.id,
        primary: stream.name,
        secondary: stream.site.name,
        text: stream.name + ' @ ' + stream.site.name,
        type: 'stream'
      })),
    ...(placesData?.searchPlaces || []).map<SearchResult>((place: Place) => ({
      ...place,
      placeType: place.type,
      type: 'place'
    }))
  ];
}
