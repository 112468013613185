import { Callout, Intent } from '@blueprintjs/core';
import { captureException } from '@sentry/react';
import { GraphQLError } from 'graphql';
import { CSSProperties, Fragment, MouseEvent, FC } from 'react';

import { useCurrentUser } from '../../../lib/hooks/current-user';
import Sentry from '../../../lib/utils/sentry';

import { ErrorDebug } from './ErrorDebug';

interface ErrorCalloutProps {
  error: any;
  report?: boolean;
  retry?: () => any | Promise<any>;
  style?: CSSProperties;
}

/**
 *
 * @param props
 */
export const ErrorCallout: FC<ErrorCalloutProps> = (props) => {
  let message = 'Sorry, something has gone wrong.';
  let report: boolean = props.report || false;

  if (props.error.networkError) {
    report = true;
  }

  let forbiddenError = false;

  try {
    forbiddenError =
      props.error.graphQLErrors &&
      props.error.graphQLErrors.filter(
        (error: GraphQLError) => error.extensions.code === 'FORBIDDEN_ERROR'
      ).length > 0;

    if (forbiddenError) {
      message = 'You do not have permission to perform this action.';
    }
  } catch (error: any) {
    captureException(error, {
      extra: {
        'props.error': props.error
      }
    });
  }

  const { email, name } = useCurrentUser();

  const onClick = () =>
    Sentry.showReportDialog({
      user:
        email && name
          ? {
              email,
              name
            }
          : undefined
    });

  const onRetry = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (props.retry === undefined) {
      return;
    }

    Promise.resolve(props.retry()).catch((err: Error) => {
      throw err;
    });
  };

  return (
    <Callout
      className="vg-error-callout"
      intent={Intent.DANGER}
      style={props.style}
    >
      {message}
      {report && (
        <Fragment>
          <br />
          We&apos;d really appreciate it if you{' '}
          <a onClick={onClick}>reported feedback</a>.
        </Fragment>
      )}
      {Boolean(props.retry) && !forbiddenError && (
        <Fragment>
          <br />
          <a onClick={onRetry}>Try again.</a>
        </Fragment>
      )}
      <ErrorDebug error={props.error} />
    </Callout>
  );
};
