/**
 *
 */
export class PermissionCode {
  /**
   *
   */
  private _bits: boolean[];

  /**
   *
   * @param code
   */
  constructor(code: string) {
    this._bits = parseInt(code, 36)
      .toString(2)
      .slice(1)
      .split('')
      .map<boolean>((c) => c === '1');
  }

  /**
   *
   * @param i
   */
  public isAllowed(i: number): boolean {
    // eslint-disable-next-line security/detect-object-injection
    return this._bits[i];
  }
}
