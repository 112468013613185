import { useContext } from 'react';

import { LocalisationContext } from '../../components/context/LocalisationContext';
import type { Localisation } from '../../data/localisation';

/**
 *
 */
export function useLocalisation(): Localisation {
  return useContext<Localisation>(LocalisationContext);
}
