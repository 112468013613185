import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { PAGE_VIEW } from '../graphql/queries/debug';

/**
 *
 */
export const useRouteUpdate = () => {
  const client = useApolloClient();
  const location = useLocation();

  useEffect(() => {
    client
      .mutate({
        mutation: PAGE_VIEW,
        variables: { path: location.pathname }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }, [location.pathname]);
};
