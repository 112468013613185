import { createElement, FC, ReactNode, useState } from 'react';

import { SelectedContext } from './SelectedContext';

/**
 *
 * @param props
 */
export const SelectedProvider: FC<{ children: ReactNode }> = (props) => {
  const value = useState<string | null>(null);

  return createElement(SelectedContext.Provider, { value }, props.children);
};
