import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { FC } from 'react';

import { MapProps } from './Map';
import styles from './Map.module.scss';

/**
 *
 * @param props
 */
export const MapError: FC<MapProps> = (props) => (
  <div className={[styles.map, styles.error, props.className].join(' ').trim()}>
    <div className={styles.callout}>
      <Icon icon={IconNames.ERROR} size={30} />
      <p>
        Sorry, something has gone wrong.
        <br />
        We were unable to load the map.
      </p>
    </div>
  </div>
);
