import { Callout, Intent } from '@blueprintjs/core';
import type { DocumentNode } from 'graphql';
import { FC, SyntheticEvent, useCallback } from 'react';

import { ErrorBoundary } from '../../atoms/ErrorBoundary';

import { FormOptions, FormResult, useForm } from './useForm';
import { FormSchema, FormValues } from './validate';

interface FormProps extends FormOptions {
  readonly className?: string;
  readonly children: FC<FormResult>;
  readonly defaultValues: FormValues;
  readonly mutation: DocumentNode;
  readonly schema: FormSchema;
  readonly preventSubmit?: boolean;
}

/**
 *
 * @param props
 */
export const Form: FC<FormProps> = (props) => {
  const result = useForm(props.mutation, props.schema, props.defaultValues, {
    ignoreNoChanges: props.ignoreNoChanges,
    onCompleted: props.onCompleted,
    onUpdate: props.onUpdate,
    parseValues: props.parseValues,
    refetch: props.refetch
  });

  const onSubmit = useCallback(
    (event: SyntheticEvent<any>) => {
      event.preventDefault();

      if (!props.preventSubmit) {
        result.submit(event);
      }
    },
    [result]
  );

  return (
    <ErrorBoundary>
      <form className={props.className} method="post" onSubmit={onSubmit}>
        {result.formError && (
          <Callout intent={Intent.DANGER}>{result.formError}</Callout>
        )}

        {props.children(result)}
      </form>
    </ErrorBoundary>
  );
};
