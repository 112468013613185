import { memo } from 'react';

interface ErrorDebugProps {
  error: any;
}

/**
 *
 * @param error
 */
export const ErrorDebug = memo<ErrorDebugProps>(
  () => null,
  () => true
);
