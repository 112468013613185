import type { FC, ReactNode } from 'react';

interface DetailTableRowProps {
  readonly children: ReactNode;
  readonly header: string;
}

/**
 *
 * @param props
 */
export const DetailTableRow: FC<DetailTableRowProps> = (props) => (
  <tr>
    <th>{props.header}</th>
    <td>{props.children}</td>
  </tr>
);
