import { captureError } from '../../utils/sentry';
import { GET_PLAYING_STREAMS } from '../localQueries/streams';

export const query = GET_PLAYING_STREAMS;

/**
 *
 * @param result
 */
export function subscriber(result: { data: any }): void {
  setTimeout((): void => {
    let data = '{}';
    try {
      data = JSON.stringify(result.data);
    } catch (e) {
      captureError(e);

      return;
    }

    localStorage.setItem('vgrid_streams', data);
  }, 100);
}
