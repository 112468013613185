import { gql } from '@apollo/client';

export const CREATE_VAULT_UPLOAD = gql`
  mutation vaultCreateUpload(
    $request: VaultUploadRequestInput!
    $details: VaultUploadDetailsInput
  ) {
    vaultCreateUpload(request: $request, details: $details) {
      _meta {
        permissions
      }
      businessName
      caseEventId
      city
      comment
      dob
      email
      end
      files {
        createdAt
        fileName
        fileSize
        hash
        id
        url
      }
      id
      name
      owner {
        email
        id
        name
      }
      ownerId
      phoneNumber
      start
      streetAddress
      suburb
      timeDifference
    }
  }
`;

export const GET_VAULT_ANPR_APPROVAL = gql`
  query vaultAnprApproval($token: String!) {
    vaultAnprApproval(token: $token) {
      caseEventId
      end
      message
      plates
      start
      status
      owner {
        email
        name
      }
    }
  }
`;

export const GET_VAULT_UPLOAD = gql`
  query vaultUpload($id: ID!) {
    vaultUpload(id: $id) {
      _meta {
        permissions
      }
      businessName
      caseEventId
      city
      comment
      dob
      email
      end
      files {
        createdAt
        fileName
        fileSize
        hash
        id
        url
      }
      groups {
        id
        name
      }
      id
      message
      name
      owner {
        email
        id
        name
      }
      ownerId
      phoneNumber
      plates
      start
      status
      streetAddress
      type
      suburb
      timeDifference
    }
  }
`;

export const GET_VAULT_UPLOADS = gql`
  query vaultUploads(
    $limit: Int
    $offset: Int
    $query: String
    $groupId: String
    $type: VaultUploadTypeEnum
  ) {
    vaultUploads(
      limit: $limit
      offset: $offset
      query: $query
      groupId: $groupId
      type: $type
    ) {
      _meta {
        count
        permissions
      }
      items {
        caseEventId
        email
        fileCount
        groups {
          id
          name
        }
        id
        status
        type
        updatedAt
      }
    }
  }
`;

export const GET_VAULT_UPLOAD_EVENTS = gql`
  query vaultUploadEvents($id: ID!, $page: Int!) {
    vaultUploadEvents(id: $id, page: $page) {
      currentPage
      events {
        action
        dateTime
        id
        meta
        user {
          email
        }
        userId
      }
      totalPages
    }
  }
`;

export const GET_VAULT_UPLOAD_GROUP = gql`
  query getVaultUploadGroup($id: ID!) {
    vaultUploadGroup(id: $id) {
      active
      id
      name
    }
  }
`;

export const GET_VAULT_UPLOAD_GROUPS = gql`
  query vaultUploadGroups {
    vaultUploadGroups {
      id
      name
    }
  }
`;

export const GET_VAULT_UPLOAD_GROUPS_ITEMS = gql`
  query vaultUploadGroupsQuery($query: String) {
    vaultUploadGroupsQuery(query: $query) {
      items {
        active
        id
        name
      }
    }
  }
`;

export const GET_VAULT_LIST_FILTER_UPLOAD_GROUPS = gql`
  query vaultListFilterUploadGroups {
    vaultListFilterUploadGroups {
      default
      id
      name
    }
  }
`;

export const REMOVE_VAULT_UPLOAD = gql`
  mutation vaultRemoveUpload($id: ID!) {
    vaultRemoveUpload(id: $id) {
      id
      removed
      type
    }
  }
`;

export const REQUEST_FILES = gql`
  mutation vaultRequestFiles($payload: VaultUploadRequestInput!) {
    vaultRequestFiles(payload: $payload) {
      caseEventId
      email
      fileCount
      id
      status
      updatedAt
    }
  }
`;

export const UPDATE_VAULT_UPLOAD = gql`
  mutation vaultUpdateUpload(
    $id: ID!
    $request: VaultUploadRequestInput!
    $details: VaultUploadDetailsInput
  ) {
    vaultUpdateUpload(id: $id, request: $request, details: $details) {
      _meta {
        permissions
      }
      businessName
      caseEventId
      city
      comment
      dob
      email
      end
      files {
        createdAt
        fileName
        fileSize
        hash
        id
        url
      }
      id
      name
      owner {
        email
        id
        name
      }
      ownerId
      phoneNumber
      start
      streetAddress
      suburb
      timeDifference
    }
  }
`;

export const SET_VAULT_ANPR_APPROVAL = gql`
  mutation vaultAnprApproval($token: String!, $approved: Boolean!) {
    vaultAnprApproval(token: $token, approved: $approved)
  }
`;

export const SET_VAULT_UPLOAD_STATUS = gql`
  mutation vaultSetStatus(
    $id: ID!
    $organisationId: String
    $status: VaultUploadStatusEnum!
    $disableEmailSend: Boolean
  ) {
    vaultSetStatus(
      id: $id
      organisationId: $organisationId
      status: $status
      disableEmailSend: $disableEmailSend
    )
  }
`;
