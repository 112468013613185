import { Intent, Tag } from '@blueprintjs/core';
import { ReactElement } from 'react';

import styles from './Logos.module.scss';

/**
 *
 */
export const DevTag: ReactElement = (
  <Tag className={styles.devTag} intent={Intent.WARNING}>
    DEV
  </Tag>
);
