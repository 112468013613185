/**
 *
 * @param obj
 * @param props
 */
export function omit(obj: Record<string, any>, props: string[]): any {
  return Object.keys(obj)
    .filter((key: string): boolean => !props.includes(key))
    .reduce<any>((result: any, key: string): any => {
      // eslint-disable-next-line security/detect-object-injection
      return { ...result, [key]: obj[key] };
    }, {});
}
