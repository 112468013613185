import { FC } from 'react';
import { useMatch } from 'react-router-dom';

import { usePermissions } from '../../../../lib/hooks/permissions';

import styles from './Search.module.scss';
import { SearchSuggest } from './SearchSuggest';

/**
 *
 */
export const Search: FC = () => {
  const match = useMatch({ path: '/streams' });
  const isHelios = useMatch({ path: '/streams/helios' });
  const { pro } = usePermissions();

  if (!match || isHelios) {
    return <div className={styles.wrapper} />;
  }

  if (!pro) {
    return (
      <div className={styles.wrapper}>
        <SearchSuggest placeholder="Search cameras" />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <SearchSuggest placeholder="Search cameras and locations" />
    </div>
  );
};
