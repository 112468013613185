/**
 *
 * @param value
 */
export function formatDate(value: Date | string | undefined): Date | undefined {
  if (value instanceof Date) {
    return value;
  }

  return value ? new Date(value) : undefined;
}

/** SUBMIT FORMATTERS */

/**
 *
 * @param value
 * @returns
 */
export function parseNullableFloat(value: string | null) {
  return value ? parseFloat(value) : null;
}

/**
 *
 * @param value
 * @returns
 */
export function parseNullableInt(value: string | null) {
  return value ? parseInt(value) : null;
}

/**
 *
 * @param value
 * @returns
 */
export function trim(value: string) {
  return value.trim();
}
