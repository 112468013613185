import { HeatmapLayerFactory } from '@vgrid/react-leaflet-heatmap-layer';
import { FC, memo } from 'react';

import type { HeatmapLocation } from './types';

const HeatmapLayer = HeatmapLayerFactory<HeatmapLocation>();

export const Heatmap: FC<{ points?: HeatmapLocation[] }> = memo((props) => {
  if (!props.points?.length) {
    return null;
  }

  const max = Math.max(...props.points.map((p) => p.weight));

  return (
    <HeatmapLayer
      aggregateType="sum"
      blur={20}
      intensityExtractor={(p) => p.weight}
      latitudeExtractor={(p) => p.lat}
      longitudeExtractor={(p) => p.lng}
      max={max}
      minOpacity={0.75}
      opacity={1}
      radius={16}
      useLocalExtrema={true}
      points={props.points}
    />
  );
});
