export type FieldValue = any;

type FieldFormatter = (value: FieldValue) => FieldValue;

export interface FormValues {
  [name: string]: FieldValue;
}

type FieldValidatorTest =
  | boolean
  | ((value: FieldValue, values: FormValues) => boolean)
  | RegExp;

interface FieldValidator {
  message: string;
  test: FieldValidatorTest;
}

export interface FieldSchema {
  dependencies?: string[];
  formatter?: FieldFormatter;
  preSubmitFormatter?: FieldFormatter;
  validators?: FieldValidator[];
}

export interface FormSchema {
  [name: string]: FieldSchema;
}

export type FormSchemaRequiredField<T extends keyof FieldSchema> = Record<
  string,
  Required<Pick<FieldSchema, T>>
>;

/**
 *
 * @param value
 * @param test
 * @param values
 */
function test(
  value: FieldValue,
  test: FieldValidatorTest,
  values: FormValues
): boolean {
  if (test === true) {
    return Boolean(value);
  }

  if (typeof test === 'function') {
    return test(value, values);
  }

  if (typeof value === 'string' && test instanceof RegExp) {
    return test.test(value);
  }

  return true;
}

/**
 *
 * @param value
 * @param validators
 * @param values
 */
export function validate(
  value: FieldValue,
  validators: FieldValidator[] | undefined,
  values: FormValues
): string | null {
  if (validators === undefined || !validators.length) {
    return null;
  }

  for (const validator of validators) {
    if (!test(value, validator.test, values)) {
      return validator.message;
    }
  }

  return null;
}
