import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';

import { fetchSiteMapData } from './siteMapData';

export const useLocalisationInit = (values: Record<string, string>) => {
  const client = useApolloClient();

  /**
   * ANPR
   */

  const siteMapJson = values['ui.anpr.siteMapJsonPath'];
  useEffect(() => {
    if (!siteMapJson) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fetchSiteMapData(client, siteMapJson).catch(() => {});
  }, [client, siteMapJson]);
};
