import { InsightsResponse, Streams } from './types';

/**
 *
 * @param data
 */
export function getStreams(data?: InsightsResponse): Streams {
  const streams: Streams = {};

  if (!data) {
    return streams;
  }

  for (const stream of data.streams) {
    streams[stream.id] = stream;
  }

  return streams;
}
