import { Callout, Intent } from '@blueprintjs/core';
import { createElement, FC, ReactElement } from 'react';

import { supported } from './SupportedComponents';

export interface JsonContentElementProps {
  component: string;
  props?: Record<string, unknown>;
  children?: JsonContentElementProps[] | string;
}

interface JsonContentProps {
  content: string;
  componentKey: string;
  fallback?: ReactElement;
}

/**
 *
 * @param key
 */
export const JsonContentElement: (
  key: string,
  supportedComponents?: Record<string, any>
) => FC<JsonContentElementProps> = (key, supportedComponents = supported) => {
  return (props) => {
    let component = props.component;

    /* eslint-disable security/detect-object-injection */
    // Might be a component from a library, e.g. Blueprint
    if (typeof component === 'string') {
      if (component.indexOf('.') !== -1) {
        const [library, ...name] = component.split('.');
        if (supportedComponents[library]) {
          if (supportedComponents[library][name.join('.')] === undefined) {
            return null;
          }

          component = supportedComponents[library][name.join('.')];
        }
      }

      if (supportedComponents[component]) {
        component = supportedComponents[component];
      }
      /* eslint-enable security/detect-object-injection */
    }

    return createElement(
      component,
      {
        key,
        ...(props.props || {})
      },
      typeof props.children === 'string'
        ? props.children
        : Array.isArray(props.children)
          ? props.children.map((child, index) =>
              JsonContentElement(`${key}-${index}`, supportedComponents)(child)
            )
          : null
    );
  };
};

export const JsonContent: FC<JsonContentProps> = (props) => {
  try {
    // TODO: Validate the JSON
    const content: JsonContentElementProps = JSON.parse(props.content);
    return JsonContentElement(props.componentKey)(content);
  } catch (error) {
    console.error(error);
  }

  if (props.fallback) {
    return props.fallback;
  }

  return (
    <Callout intent={Intent.DANGER}>
      There was an error loading this content
    </Callout>
  );
};
