import {
  addNotification,
  clearNotifications,
  removeNotification,
  setNotificationsSetting
} from './notifications';
import {
  changeLayout,
  clearLayout,
  closeStream,
  setPlayingStream,
  setStretch
} from './streams';

export const resolvers = {
  Mutation: {
    addNotification,
    changeLayout,
    clearLayout,
    clearNotifications,
    closeStream,
    removeNotification,
    setNotificationsSetting,
    setPlayingStream,
    setStretch
  }
};
