const regex =
  '[^\\.\\s@:](?:[^\\s@:]*[^\\s@:\\.])?@[^\\.\\s@]+(?:\\.[^\\.\\s@]+)*';

/**
 *
 * @param value
 */
export function isEmail(value: string): boolean {
  // eslint-disable-next-line security/detect-non-literal-regexp
  return new RegExp(`^${regex}$`).test(value);
}

export const isPhone = /^\+\d{10,}$/;

/**
 *
 * @param length
 */
export function minLength(length: number): (value: any) => boolean {
  return (value: any): boolean => String(value).length >= length;
}

/**
 *
 * @param length
 */
export function maxLength(length: number): (value: any) => boolean {
  return (value: any): boolean => String(value).length <= length;
}

export function isNullableFloat(value: string | number | null) {
  if (!value) return true;
  value = String(value);
  const val = parseFloat(value);
  if (isNaN(val)) return false;
  const numDecimals = value.split('.')[1]?.length || 0;

  return String(val.toFixed(numDecimals)).length === value.length;
}

export function isNullableInt(value: string | number | null) {
  if (!value) return true;
  value = String(value);
  const val = parseInt(value);
  if (isNaN(val)) return false;
  return String(val).length === value.length;
}

export function hasContent(value: string) {
  return value.trim().length > 0;
}

/**
 * Checks if the value is not null, undefined or an empty string
 *
 * @param value
 */
export function isValueSet(value: unknown) {
  if (value === null || value === undefined) return false;
  if (typeof value === 'string') return value.trim().length > 0;
  return true;
}

/**
 * Validates if the string is a valid port number
 * (integer between 0 and 65535)
 * @param value
 * @returns
 */
export function isPortNumber(value: string) {
  return isValidNumberBetween(0, 65535)(value);
}

/**
 * Validates if the string is a valid number
 * (integer between min and max)
 * @param value
 * @returns
 */
export function isValidNumberBetween(min: number, max: number) {
  return (value: string | number | null) => {
    if (!value) return true;
    const trimmedValue = String(value).trim();

    const num = parseInt(trimmedValue);
    const isValidRange = !isNaN(num) && num >= min && num <= max;

    const ANY_CHAR_NOT_A_DIGIT = /[^-\d]/;
    const isOnlyDigits = !ANY_CHAR_NOT_A_DIGIT.test(trimmedValue);

    return isOnlyDigits && isValidRange;
  };
}
