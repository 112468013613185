import { ApolloClient } from '@apollo/client';
import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import dayjs from 'dayjs';

import { STATUS_PAGE_URL } from '../../hooks/status-page-url';
import { captureError } from '../../utils/sentry';
import { addNotification } from '../add';

const POLL_INTERVAL = 60_000;
const STORAGE_KEY = 'vgrid.statuspage-notifications-displayed';

enum StatusImpact {
  CRITICAL = 'critical',
  MAINTENANCE = 'maintenance',
  MAJOR = 'major',
  MINOR = 'minor',
  NONE = 'none'
}

type StatusImpactType = typeof StatusImpact;

const impactMap: Record<StatusImpact, Intent | undefined> = {
  critical: Intent.DANGER,
  maintenance: Intent.PRIMARY,
  major: Intent.DANGER,
  minor: Intent.WARNING,
  none: Intent.PRIMARY
};

interface StatusPageComponent {
  created_at: string;
  description: string | null;
  id: string;
  name: string;
  page_id: string;
  position: number;
  status: string;
  updated_at: string;
}

interface StatusPageIncidentUpdate {
  body: string;
  created_at: string;
  display_at: string;
  id: string;
  incident_id: string;
  status: string;
  updated_at: string;
}

interface StatusPageIncident {
  created_at: string;
  id: string;
  impact: StatusImpactType[keyof StatusImpactType];
  incident_updates: StatusPageIncidentUpdate[];
  monitoring_at: string | null;
  name: string;
  page_id: string;
  resolved_at: string | null;
  shortlink: string;
  status: string;
  updated_at: string;
}

interface StatusPageScheduledMaintenance {
  created_at: string;
  id: string;
  impact: StatusImpactType[keyof StatusImpactType];
  incident_updates: StatusPageIncidentUpdate[];
  monitoring_at: string | null;
  name: string;
  page_id: string;
  resolved_at: string | null;
  scheduled_for: string;
  scheduled_until: string;
  shortlink: string;
  status: string;
  updated_at: string;
}

interface StatusPageResponse {
  page: {
    id: string;
    name: string;
    url: string;
    updated_at: string;
  };
  status: {
    description: string;
    indicator: StatusImpactType[keyof StatusImpactType];
  };
  components: StatusPageComponent[];
  incidents: StatusPageIncident[];
  scheduled_maintenances: StatusPageScheduledMaintenance[];
}

/**
 *
 * @param client
 */
async function poll(client: ApolloClient<any>): Promise<void> {
  const parsedItems = JSON.parse(sessionStorage.getItem(STORAGE_KEY) || '[]');
  const iteratedItems: string[] = [];
  const summary = await fetch(STATUS_PAGE_URL + '/api/v2/summary.json')
    .then((response) => response.json() as Promise<StatusPageResponse>)
    .catch(() => null);

  if (!summary) {
    return;
  }

  for (const incident of summary.incidents) {
    const key = `${incident.id}:${incident.incident_updates[0].id}`;
    iteratedItems.push(key);
    if (parsedItems.includes(key)) {
      continue;
    }

    addNotification(client, {
      force: true,
      handler: null,
      icon: IconNames.WARNING_SIGN,
      id: key,
      intent: impactMap[incident.impact] || Intent.WARNING,
      message: incident.incident_updates[0].body,
      title: 'Incident: ' + incident.name
    });
  }

  for (const maintenance of summary.scheduled_maintenances) {
    const key = `${maintenance.id}:${maintenance.incident_updates[0].id}`;
    iteratedItems.push(key);
    if (parsedItems.includes(key)) {
      continue;
    }

    const start = dayjs(maintenance.scheduled_for, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    const end = dayjs(maintenance.scheduled_until, 'YYYY-MM-DDTHH:mm:ss.SSSZ');

    let message = maintenance.incident_updates[0].body.trim();
    if (!message.endsWith('.')) {
      message += '. ';
    } else {
      message += ' ';
    }

    if (end.toDate().valueOf() < Date.now()) {
      continue;
    }

    const duration = dayjs.duration(end.diff(start)).humanize();
    if (maintenance.incident_updates[0].status === 'scheduled') {
      message += `Scheduled to start on ${start.format(
        'D MMMM YYYY @ HH:mm'
      )} and will last for ${duration}`;
    } else if (maintenance.incident_updates[0].status === 'in_progress') {
      message += `This will last for ${duration}`;
    } else {
      continue;
    }

    addNotification(client, {
      force: true,
      handler: null,
      icon: IconNames.WARNING_SIGN,
      id: key,
      intent: impactMap[maintenance.impact] || Intent.PRIMARY,
      message,
      title: 'Maintenance: ' + maintenance.name
    });
  }

  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(iteratedItems));
}

/**
 *
 * @param client
 */
export async function statusPage(
  client: ApolloClient<any>
): Promise<() => void> {
  const poller = setInterval(
    () => poll(client).catch(captureError),
    POLL_INTERVAL
  );

  poll(client).catch(captureError);

  return () => clearInterval(poller);
}
