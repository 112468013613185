import { useContext } from 'react';

import { InsightsContext } from './InsightsContext';
import { Insights } from './types';

/**
 *
 */
export function useHeatmap(): Record<string, number> {
  const insights = useContext<Insights>(InsightsContext);

  return insights.heatmap;
}
