import * as Sentry from '@sentry/react';

import { InternalError, UserError } from '../errors';

Sentry.init({
  defaultIntegrations: localStorage.getItem('DISABLE_SENTRY')
    ? undefined
    : false,
  dsn: process.env.SENTRY_DSN,
  environment: __DEV__ ? 'dev' : 'prod',
  release: process.env.RELEASE || 'dev'
});

/**
 *
 * @param error
 * @param errorInfo
 */
export function captureError(
  error: Error | any,
  errorInfo: Record<string, any> = {}
): void {
  if (error instanceof UserError) {
    return;
  }

  if (error instanceof InternalError) {
    error = error.originalError;
  }

  Sentry.configureScope((scope) => {
    for (const [key, value] of Object.entries(errorInfo)) {
      scope.setExtra(key, value);
    }
  });

  const eventId = Sentry.captureException(error);

  if (__DEV__) {
    console.error(error);
    console.log(`Sent Sentry error report: ${eventId}`);
  }
}

export default Sentry;
