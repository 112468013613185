import { CurrentUser } from '../../../components/context/current-user/get-current-user';
import { getPermissions } from '../../permissions';
import { changeLayout } from '../localResolvers/streams';
import { WHOAMI } from '../queries/authentication';

export const query = WHOAMI;

let hasInit = false;
let lastEmail = '';

/**
 *
 * @param result
 */
export function subscriber(result: { data: { whoami: CurrentUser } }): void {
  const whoami = result.data.whoami;

  if (!whoami?.code) {
    return;
  }

  if (lastEmail !== whoami.email) {
    lastEmail = whoami.email || '';
    hasInit = false;
  }

  if (hasInit) {
    return;
  }

  const { pro } = getPermissions(whoami.code);

  if (!pro) {
    changeLayout(undefined, { layout: 'twoTwo' });
  }

  hasInit = true;
}
