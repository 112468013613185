import { FetchResult, Observable } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';

const hasDevMode = Boolean(window.localStorage.getItem('DEV_MODE'));

/**
 *
 * @param error
 */
function errorHandler(error: ErrorResponse): Observable<FetchResult> | void {
  // Debug logging
  if (__DEV__ || hasDevMode) {
    if (error.graphQLErrors) {
      for (const err of error.graphQLErrors) {
        console.error(err);
      }
    }

    if (error.networkError) {
      console.error(error);
    }
  }

  // Reload page if unauthorized
  if (
    error.networkError &&
    'statusCode' in error.networkError &&
    error.networkError.statusCode === 401
  ) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }
}

export const errorLink = onError(errorHandler);
