import { useContext } from 'react';

import {
  SearchContext,
  SearchValue
} from '../../components/context/search/SearchContext';

/**
 *
 *
 */
export function useSearch(): SearchValue {
  return useContext<SearchValue>(SearchContext);
}
