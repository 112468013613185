import { Loader } from '@googlemaps/js-api-loader';
import { LatLngLiteral } from 'leaflet';

let loader: Loader | undefined;

/**
 *
 * @param placeId
 */
export async function placeIdToLatLon(
  placeId: string,
  apiKey?: string
): Promise<LatLngLiteral | undefined> {
  if (!loader) {
    loader = new Loader({
      apiKey: apiKey || process.env.MAPS_API_KEY || ''
    });
  }
  await loader.importLibrary('core');

  const geocoder = new google.maps.Geocoder();
  const { results } = await geocoder.geocode({ placeId });

  if (results.length === 0) {
    return;
  }

  const { geometry } = results[0];
  return geometry.location.toJSON();
}
