import type { ApolloClient } from '@apollo/client';
import { IconNames } from '@blueprintjs/icons';
import Push from 'push.js';

import { addNotification } from '../add';

interface VersionData {
  BUILD_TIME: string;
  FORCE?: number;
  RELEASE: string;
}

const CHECK_INTERVAL = 3e5; // 5 mins

/**
 *
 * @param client
 */
export function version(client: ApolloClient<any>): Promise<() => void> {
  /**
   *
   */
  function versionCheck(): void {
    fetch('/version.json?s=' + Date.now().toString(36), { cache: 'no-cache' })
      .then((res: Response): Promise<VersionData> => res.json())
      .then((data: VersionData): void => {
        if (data.RELEASE === process.env.RELEASE) {
          return;
        }

        addNotification(client, {
          force: true,
          handler: ['reload'],
          icon: IconNames.AUTOMATIC_UPDATES,
          id: 'new-version',
          intent: 'primary',
          message:
            'A new version of vGRID is available. Click here to load the new version.',
          title: 'Update available'
        });

        if (data.FORCE) {
          setTimeout(() => {
            window.location.reload();
          }, data.FORCE);

          if (data.FORCE > 60000) {
            setTimeout(() => {
              addNotification(client, {
                force: true,
                handler: ['reload'],
                icon: IconNames.AUTOMATIC_UPDATES,
                id: 'new-version-force',
                intent: 'primary',
                message:
                  'A new version of vGRID is available. Your page will automatically reload in 60 seconds. Click here to load the new version immediately.',
                sound: 'dingdong',
                title: 'Update available'
              });
            }, data.FORCE - 60000);
          }
        }

        clearInterval(versionTimer);
      })
      .catch((error: Error) => console.error(error));
  }

  if (Push.Permission.get() === Push.Permission.DEFAULT) {
    addNotification(client, {
      handler: ['pushRequest'],
      icon: IconNames.FLAG,
      id: 'allow-notifications',
      intent: 'primary',
      message:
        'Your permission is required to display notifications while your browser is minimised. Click here to confirm.',
      title: 'Permission required'
    });
  }

  const versionTimer: NodeJS.Timeout = setInterval(
    versionCheck,
    CHECK_INTERVAL
  );

  return Promise.resolve(() => clearInterval(versionTimer));
}
