import { FocusStyleManager } from '@blueprintjs/core';
import { createElement } from 'react';
import { createRoot } from 'react-dom/client';

import '../css/style.scss';
import { App } from '../../components/App';

// Hot reload
if (__DEV__) {
  (module as any).hot.accept();
}

if (navigator.userAgent.includes('(Windows')) {
  document.documentElement.classList.add('osWin');
} else if (navigator.userAgent.includes('(Macintosh')) {
  document.documentElement.classList.add('osMac');
}

// Render app
const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(createElement(App));

// Don't always show focus outlines on click,
// only when using keyboard navigation
FocusStyleManager.onlyShowFocusOnTabs();
