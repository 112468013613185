import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useCurrentUser } from '../../../lib/hooks/current-user';

/**
 *
 */
export const AuthenticatedIndex: FC = () => {
  const { startScreen } = useCurrentUser();

  return <Navigate replace={true} to={startScreen} />;
};
