import { ComponentType, lazy, ReactElement, Suspense } from 'react';

import { LoadingPage } from '../LoadingPage/LoadingPage';

/**
 *
 * @param factory
 * @param placeholder
 */
export function dynamicLoader<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
  placeholder?: ReactElement
): T {
  const Component = lazy<T>(factory);

  const LoadingElement = (props: any) => (
    <Suspense fallback={placeholder || <LoadingPage />}>
      <Component {...props} />
    </Suspense>
  );

  LoadingElement.displayName = 'LoadingElement';

  return LoadingElement as T;
}
