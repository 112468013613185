import { createContext } from 'react';

import type { CurrentUser } from './get-current-user';

export interface CurrentUserValue {
  readonly currentUser: CurrentUser;
  readonly logout: () => Promise<void>;
  readonly reloadCurrentUser: () => Promise<void>;
  readonly setCurrentUser: (currentUser: CurrentUser) => void;
}

/**
 *
 */
export function getAnonymousUser(): CurrentUser {
  return {
    code: '0',
    email: null,
    isSso: false,
    name: null,
    permissions: [],
    startScreen: '/'
  };
}

export const CurrentUserContext = createContext<CurrentUserValue>({
  currentUser: getAnonymousUser(),
  logout: () => {
    throw new Error('logout called outside of CurrentUserProvider');
  },
  reloadCurrentUser: () => {
    throw new Error('reloadCurrentUser called outside of CurrentUserProvider');
  },
  setCurrentUser: () => {
    throw new Error('setCurrentUser called outside of CurrentUserProvider');
  }
});
