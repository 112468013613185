import type {
  ApolloCache,
  ApolloQueryResult,
  FetchResult,
  NormalizedCacheObject
} from '@apollo/client';
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  FormGroup,
  InputGroup,
  Intent,
  TextArea
} from '@blueprintjs/core';
import { TimePrecision } from '@blueprintjs/datetime';
import { useEffect, useState, FC, Fragment } from 'react';

import {
  GET_VAULT_UPLOAD_GROUPS,
  REQUEST_FILES
} from '../../../../../lib/graphql/queries/vault';
import { useLocalisation } from '../../../../../lib/hooks/localisation';
import { omit } from '../../../../../lib/utils/omit';
import { DatePicker } from '../../../../atoms/DatePicker';
import { ErrorCallout } from '../../../../atoms/ErrorCallout';
import { ListFilterSelect } from '../../../../atoms/ListFilterSelect/ListFilterSelect';
import { RequiredLabel } from '../../../../atoms/RequiredLabel';
import { useForm } from '../../../../molecules/Form/useForm';
import { FormValues } from '../../../../molecules/Form/validate';

import styles from './RequestFiles.module.scss';
import { schema } from './RequestFiles.schema';

export interface RequestFilesDefaultValues {
  caseEventId: string;
  email: string;
  end: any;
  groupId: string;
  message: string;
  start: any;
}

interface RequestFilesProps {
  defaultValues?: RequestFilesDefaultValues;
  isOpen: boolean;
  onClose: () => void;
  onUpdate?: (
    cache: ApolloCache<NormalizedCacheObject>,
    mutationResult: FetchResult<any>
  ) => void;
  refetch?: (variables?: any) => Promise<ApolloQueryResult<any>>;
  title?: string;
}

/**
 *
 * @param props
 */
export const RequestFiles: FC<RequestFilesProps> = (props) => {
  const localised = useLocalisation().vault?.upload;

  const {
    changed,
    errors,
    disabled,
    formError,
    intents,
    loading,
    onBlur,
    onChange,
    onChangeValue,
    reset,
    submit,
    values
  } = useForm(
    REQUEST_FILES,
    schema,
    {
      caseEventId: '',
      email: '',
      end: null,
      groupId: undefined,
      message: '',
      start: null,
      ...(props.defaultValues || {})
    },
    {
      onUpdate: (cache, result) => {
        if (!result.data?.vaultRequestFiles?.id) {
          return;
        }

        props.onClose();

        if (props.onUpdate) {
          props.onUpdate(cache, result);
        }
      },
      parseValues: (values: FormValues) => ({
        // TODO: support multiple groups
        payload: { ...omit(values, ['groupId']), groupIds: [values.groupId] }
      }),
      refetch: props.refetch
    }
  );

  const [groupsLoading, setGroupsLoading] = useState<boolean>(true);
  useEffect(reset, [props.isOpen]);

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.title || 'Request files'}
    >
      {formError && <ErrorCallout error={formError} />}
      <form onSubmit={submit}>
        <DialogBody>
          <FormGroup
            intent={intents.email}
            helperText={changed.email ? errors.email : null}
            label={
              <RequiredLabel text="Email address (Recipient of Vault request)" />
            }
            labelFor="email"
          >
            <InputGroup
              autoFocus={true}
              id="email"
              name="email"
              onBlur={onBlur}
              onChange={onChange}
              placeholder="name@example.com"
              type="email"
              value={values.email}
            />
          </FormGroup>
          <FormGroup
            intent={intents.caseEventId}
            helperText={changed.caseEventId ? errors.caseEventId : null}
            label={
              <RequiredLabel
                text={localised?.caseEventId.labelText || 'Reference'}
              />
            }
            labelFor="caseEventId"
          >
            <InputGroup
              id="caseEventId"
              name="caseEventId"
              onBlur={onBlur}
              onChange={onChange}
              placeholder=""
              type="text"
              value={values.caseEventId}
            />
          </FormGroup>
          <div className={styles.dates}>
            <FormGroup
              intent={intents.start}
              helperText={changed.start ? errors.start : null}
              label="Start"
              labelFor="start"
            >
              <DatePicker
                displayFormat="D MMMM YYYY - h:mm A"
                input={{
                  id: 'start',
                  name: 'start',
                  type: 'text'
                }}
                onChangeValue={(value) => onChangeValue('start', value)}
                picker={{
                  highlightCurrentDay: true,
                  showActionsBar: true,
                  timePickerProps: {
                    showArrowButtons: true,
                    useAmPm: true
                  },
                  timePrecision: TimePrecision.MINUTE
                }}
                type="date"
                value={values.start}
              />
            </FormGroup>
            <FormGroup
              intent={intents.end}
              helperText={changed.end ? errors.end : null}
              label="End"
              labelFor="end"
            >
              <DatePicker
                displayFormat="D MMMM YYYY - h:mm A"
                input={{
                  id: 'end',
                  name: 'end',
                  type: 'text'
                }}
                onChangeValue={(value) => onChangeValue('end', value)}
                picker={{
                  highlightCurrentDay: true,
                  showActionsBar: true,
                  timePickerProps: {
                    showArrowButtons: true,
                    useAmPm: true
                  },
                  timePrecision: TimePrecision.MINUTE
                }}
                type="date"
                value={values.end}
              />
            </FormGroup>
          </div>
          <FormGroup
            intent={intents.message}
            helperText={changed.message ? errors.message : null}
            label={<RequiredLabel text="Message" />}
            labelFor="message"
          >
            <TextArea
              className={styles.message}
              id="message"
              name="message"
              onBlur={onBlur}
              onChange={onChange}
              value={values.message}
            />
          </FormGroup>
          <FormGroup
            intent={errors.groupId ? intents.groupId : Intent.NONE}
            helperText={
              errors.groupId ||
              'Which upload group this request should be added to'
            }
            label={<RequiredLabel text="Upload group" />}
            labelFor="groupId"
          >
            <ListFilterSelect
              id="groupId"
              filtersKey={'vaultUploadGroups'}
              onChange={(id: string) => {
                // TODO: support multiple groups
                onChangeValue('groupId', id);
              }}
              query={GET_VAULT_UPLOAD_GROUPS}
              setLoading={setGroupsLoading}
              value={values.groupId}
            />
          </FormGroup>
        </DialogBody>
        <DialogFooter
          actions={
            <Fragment>
              <Button
                large={true}
                minimal={true}
                onClick={props.onClose}
                text="Cancel"
              />
              <Button
                disabled={disabled}
                intent={Intent.PRIMARY}
                loading={loading || groupsLoading}
                large={true}
                text="Send request email"
                type="submit"
              />
            </Fragment>
          }
        />
      </form>
    </Dialog>
  );
};
