import Linkify from 'linkify-react';
import { Fragment } from 'react';

import { supported as Blueprint } from './blueprint';

export const supported: { [key: string]: any } = {
  Blueprint,
  Fragment,
  Linkify
};
