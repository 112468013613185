import {
  HTMLInputProps,
  Icon,
  InputGroupProps,
  InputGroup,
  Intent,
  Popover
} from '@blueprintjs/core';
import {
  DatePicker as BaseDatePicker,
  DatePickerProps as BaseDatePickerProps
} from '@blueprintjs/datetime';
import { IconNames } from '@blueprintjs/icons';
import dayjs from 'dayjs';
import { ChangeEvent, MouseEvent, useEffect, useState, FC } from 'react';

import styles from './DatePicker.module.scss';

interface DatePickerBaseProps {
  disabled?: boolean;
  displayFormat: string;
  input: InputGroupProps & HTMLInputProps;
  intent?: Intent;
  picker: BaseDatePickerProps;
  readOnly?: boolean;
}

interface DatePickerDateProps extends DatePickerBaseProps {
  onChangeValue: (value: Date | undefined) => void;
  type: 'date';
  value: Date | undefined;
}

interface DatePickerStringProps extends DatePickerBaseProps {
  format: string;
  onChangeValue: (value: string | undefined) => void;
  type: 'string';
  value: string | undefined;
}

type DatePickerProps = DatePickerDateProps | DatePickerStringProps;

/**
 *
 * @param props
 */
function getValue(props: DatePickerProps): dayjs.Dayjs | undefined {
  if (!props.value) {
    return undefined;
  }

  return dayjs(props.value, 'format' in props ? props.format : undefined);
}

/**
 *
 * @param props
 */
export const DatePicker: FC<DatePickerProps> = (props) => {
  const [value, setValue] = useState<dayjs.Dayjs | undefined>(() =>
    getValue(props)
  );

  // Update value if props.value changes
  useEffect(() => {
    if (value?.valueOf() === props.value?.valueOf()) {
      return;
    }

    setValue(getValue(props));
  }, [props.value]);

  const [isOpen, setOpen] = useState<boolean>(false);

  const inputClassNames = [styles.input];
  if (props.readOnly) {
    inputClassNames.push(styles.readOnly);
  }

  const input = (
    <InputGroup
      {...props.input}
      className={inputClassNames.join(' ')}
      disabled={props.disabled}
      intent={props.intent}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        event.preventDefault()
      }
      onClick={(event: MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
      }}
      onFocus={() => setOpen(true)}
      readOnly={true}
      rightElement={<Icon className={styles.icon} icon={IconNames.CALENDAR} />}
      value={value?.format(props.displayFormat) || ''}
    />
  );

  if (props.readOnly) {
    return input;
  }

  const picker = (
    <BaseDatePicker
      {...props.picker}
      onChange={(selectedDate: Date, isUserChange: boolean): void => {
        if (!isUserChange) {
          return;
        }

        const value = selectedDate ? dayjs(selectedDate) : undefined;

        setValue(value);

        if ('format' in props) {
          props.onChangeValue(value?.format(props.format));
        } else {
          props.onChangeValue(value?.toDate());
        }
      }}
      value={value?.toDate() || new Date()}
    />
  );

  return (
    <Popover
      className={styles.inputWrapper}
      content={picker}
      isOpen={isOpen}
      onInteraction={(nextOpenState: boolean) =>
        !props.disabled && setOpen(nextOpenState)
      }
    >
      {input}
    </Popover>
  );
};
