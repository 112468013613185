import {
  Icon,
  Menu,
  Popover,
  PopoverInteractionKind,
  Position
} from '@blueprintjs/core';
import type { IconName } from '@blueprintjs/icons';
import { FC, MouseEventHandler, ReactNode, useCallback, useMemo } from 'react';
import { useMatch } from 'react-router-dom';

import styles from './Navigation.module.scss';
import { useNavigation } from './NavigationContext';

interface NavMenuBaseProps {
  children: ReactNode;
  href: string;
  name: string;
}

interface NavMenuIconProps extends NavMenuBaseProps {
  icon: IconName;
  text?: string;
}

interface NavMenuTextProps extends NavMenuBaseProps {
  icon?: IconName;
  text: string;
}

type NavMenuProps = NavMenuIconProps | NavMenuTextProps;

const TIMEOUT = 900;

/**
 *
 * @param props
 */
export const NavMenu: FC<NavMenuProps> = (props) => {
  const { item, openedAt, setItem, setOpenedAt } = useNavigation();
  const match = useMatch(props.href + '/*');

  const classNames = useMemo<string>(() => {
    const classNames = [styles.button];

    if (item === props.name && openedAt) {
      classNames.push(styles.focus);
    }

    if (match) {
      classNames.push(styles.active);
    }

    return classNames.join(' ');
  }, [props.name, item, match, openedAt]);

  const content = useMemo(
    () => <Menu>{props.children}</Menu>,
    [props.children]
  );

  const onInteraction = useCallback(
    (nextOpenState: boolean, e?: React.SyntheticEvent<HTMLElement>) => {
      if (!e) {
        return;
      }

      e.preventDefault();

      setOpenedAt((currentOpenedAt) => {
        const now = Date.now();

        if (currentOpenedAt) {
          if (nextOpenState) {
            return currentOpenedAt;
          }

          return currentOpenedAt > now ? currentOpenedAt : null;
        }

        return nextOpenState ? now : null;
      });
    },
    [setOpenedAt]
  );

  const onMouseEnter = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault();
      setItem(props.name);
      setOpenedAt(Date.now() + TIMEOUT);
    },
    [props.name, setItem, setOpenedAt]
  );

  const onMouseLeave = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault();
      // Close the dropdown menu
      setItem(null);
      setOpenedAt(null);
    },
    [setItem, setOpenedAt]
  );

  return (
    <div
      className={styles.hitbox}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Popover
        captureDismiss={false}
        content={content}
        interactionKind={PopoverInteractionKind.CLICK}
        isOpen={item === props.name && Boolean(openedAt)}
        lazy={false}
        onInteraction={onInteraction}
        placement={Position.BOTTOM}
        transitionDuration={75}
      >
        <div className={classNames}>
          {props.icon && <Icon className={styles.icon} icon={props.icon} />}
          {props.text && <span className={styles.text}>{props.text}</span>}
        </div>
      </Popover>
    </div>
  );
};
