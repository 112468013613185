import { Day, InsightsResponse, Table } from './types';

/**
 *
 * @param data
 */
export function getTables(data?: InsightsResponse): Record<string, Table> {
  const tables: Record<string, Table> = {
    ALL: {
      fri: new Array(24).fill(0),
      mon: new Array(24).fill(0),
      sat: new Array(24).fill(0),
      sun: new Array(24).fill(0),
      thu: new Array(24).fill(0),
      tue: new Array(24).fill(0),
      wed: new Array(24).fill(0)
    }
  };

  if (!data) {
    return tables;
  }

  for (const row of data.table) {
    const { __typename, id, ...table } = row;

    // eslint-disable-next-line security/detect-object-injection
    tables[id] = table;

    for (const [day, hours] of Object.entries(table)) {
      for (const [hour, value] of Object.entries(hours)) {
        tables.ALL[day as Day][Number(hour)] += Number(value);
      }
    }
  }

  return tables;
}
