import { Counts, Duration, InsightsResponse } from './types';

/**
 *
 * @param data
 */
export function getCounts(data?: InsightsResponse): Counts {
  const counts: Counts = {
    [Duration.DAY]: {},
    [Duration.HOUR]: {},
    [Duration.MONTH]: {},
    [Duration.WEEK]: {},
    [Duration.YEAR]: {}
  };

  if (!data) {
    return counts;
  }

  const all = [
    ...data.day,
    ...data.hour,
    ...data.month,
    ...data.week,
    ...data.year
  ];

  for (const row of all) {
    const { __typename, duration, id, ...result } = row;

    // eslint-disable-next-line security/detect-object-injection
    counts[duration][id] = result;
  }

  return counts;
}
