import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Classes } from '@blueprintjs/core';
import { useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';

import {
  CLEAR_NOTIFICATIONS,
  GET_NOTIFICATIONS
} from '../../../lib/graphql/localQueries/notifications';
import { useStatusPageUrl } from '../../../lib/hooks/status-page-url';
import { handlers } from '../../../lib/notifications/handlers';
import { initNotifications } from '../../../lib/notifications/init';
import { Notification } from '../../../lib/notifications/notification-types';

import { Toast } from './Toast';

interface GetNotificationsResponse {
  notifications: Notification[];
}

interface NotificationsProps {
  anonymous?: boolean;
}

/**
 *
 */
export const Notifications: FC<NotificationsProps> = (props) => {
  const client = useApolloClient();
  const { isDefaultStatusPage } = useStatusPageUrl();

  useEffect(() => {
    const subscriptions = initNotifications(
      client,
      isDefaultStatusPage,
      props.anonymous
    );

    return () => {
      subscriptions
        .then((unsubscribe) => unsubscribe())
        .catch((error: Error) => console.error(error));
    };
  }, [client]);

  const { data } = useQuery<GetNotificationsResponse>(GET_NOTIFICATIONS);
  const [clearAll] = useMutation(CLEAR_NOTIFICATIONS);
  const { pathname } = useLocation();

  if (!data?.notifications.length || pathname === '/notifications') {
    return null;
  }

  const classNames = [Classes.TOAST_CONTAINER, 'vg-notification__list'];
  const clearClassNames = [Classes.TOAST, 'vg-notification__clear'];
  const moreClassNames = [Classes.TOAST, 'vg-notification__more'];

  const more = data.notifications.length - 5;

  return (
    <div className={classNames.join(' ')}>
      {data.notifications.slice(0, 5).map((notification) => (
        <Toast key={notification.id} {...notification} />
      ))}
      <div className="vg-notification__buttons">
        {data.notifications.length > 5 && !props.anonymous && (
          <div
            className={moreClassNames.join(' ')}
            onClick={() => handlers.link(null, '/notifications')}
            tabIndex={0}
          >
            <span>
              View {more} more notification{more === 1 ? '' : 's'}
            </span>
          </div>
        )}
        {data.notifications.length > 1 && (
          <div
            className={clearClassNames.join(' ')}
            onClick={() => clearAll()}
            tabIndex={0}
          >
            <span>Clear all</span>
          </div>
        )}
      </div>
    </div>
  );
};
