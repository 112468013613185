import { Component, ReactNode } from 'react';

import { captureError } from '../../lib/utils/sentry';

import { ErrorCallout } from './ErrorCallout';

interface ErrorBoundaryProps {
  children: ReactNode;
  report?: boolean;
  retry?: () => any;
}

interface ErrorBoundaryState {
  error: Error | false;
}

/**
 *
 */
export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    error: false
  };

  /**
   *
   * @param error
   * @param info
   */
  componentDidCatch(error: Error | null, info: Record<string, any>): void {
    captureError(error, info);

    this.setState({ error: error || false });
  }

  /**
   *
   */
  render(): ReactNode {
    if (this.state.error) {
      return (
        <ErrorCallout
          error={this.state.error}
          report={this.props.report}
          retry={this.props.retry}
        />
      );
    }

    return this.props.children;
  }
}
