import { DataProxy } from '@apollo/client';

import { Notification } from '../../notifications/notification-types';
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SETTINGS
} from '../localQueries/notifications';

interface NotificationItem extends Notification {
  __typename: 'Notification';
}

export interface NotificationSettings {
  disableComputer: boolean;
  disableTab: boolean;
  muteComputer: boolean;
}

export const NOTIFICATIONS_SETTINGS_KEY = 'vgrid.notification-settings';

/**
 *
 * @param _rootValue
 * @param args
 * @param context
 */
export function addNotification(
  _rootValue: undefined,
  args: { notification: Notification },
  context: { cache: DataProxy }
): Notification[] {
  const existing: {
    notifications: NotificationItem[];
  } | null = context.cache.readQuery({ query: GET_NOTIFICATIONS });

  const notifications: NotificationItem[] = (
    existing ? existing.notifications : []
  )
    .slice()
    .filter(
      (notification: NotificationItem) =>
        notification.id !== args.notification.id
    );

  notifications.unshift({
    icon: 'notifications',
    intent: 'primary',
    sound: undefined,
    timeout: undefined,
    ...args.notification,
    __typename: 'Notification'
  });

  context.cache.writeQuery({
    data: { notifications },
    query: GET_NOTIFICATIONS
  });

  return notifications;
}

/**
 *
 * @param _rootValue
 * @param _args
 * @param context
 */
export function clearNotifications(
  _rootValue: undefined,
  _args: Record<string, never>,
  context: { cache: DataProxy }
): void {
  context.cache.writeQuery({
    data: { notifications: [] },
    query: GET_NOTIFICATIONS
  });
}

/**
 *
 * @param _rootValue
 * @param args
 * @param context
 */
export function removeNotification(
  _rootValue: undefined,
  args: { id: string },
  context: { cache: DataProxy }
): void {
  const existing: {
    notifications: NotificationItem[];
  } | null = context.cache.readQuery({ query: GET_NOTIFICATIONS });

  const notifications: NotificationItem[] = (
    existing ? existing.notifications : []
  )
    .slice()
    .filter((notification: NotificationItem) => notification.id !== args.id);

  context.cache.writeQuery({
    data: { notifications },
    query: GET_NOTIFICATIONS
  });
}

/**
 *
 * @param _rootValue
 * @param args
 * @param context
 */
export function setNotificationsSetting(
  _rootValue: undefined,
  args: {
    key: keyof NotificationSettings;
    value: boolean;
  },
  context: { cache: DataProxy }
): void {
  const existing = context.cache.readQuery<{
    notificationsSettings: NotificationSettings;
  }>({
    query: GET_NOTIFICATIONS_SETTINGS
  });

  const notificationsSettings: NotificationSettings = {
    disableComputer: false,
    disableTab: false,
    muteComputer: false,
    ...(existing?.notificationsSettings || {}),
    [args.key]: args.value
  };

  context.cache.writeQuery({
    data: { notificationsSettings },
    query: GET_NOTIFICATIONS_SETTINGS
  });

  if (['disableComputer', 'muteComputer'].includes(args.key)) {
    localStorage.setItem(
      NOTIFICATIONS_SETTINGS_KEY,
      JSON.stringify({
        disable: notificationsSettings.disableComputer,
        mute: notificationsSettings.muteComputer
      })
    );
  }

  if (args.key === 'disableTab') {
    sessionStorage.setItem(
      NOTIFICATIONS_SETTINGS_KEY,
      JSON.stringify({
        disable: notificationsSettings.disableTab
      })
    );
  }
}
