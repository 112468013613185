import { Tag } from '@blueprintjs/core';
import { Fragment, FC } from 'react';

interface RequiredLabelProps {
  readonly text: string;
}

/**
 *
 * @param props
 */
export const RequiredLabel: FC<RequiredLabelProps> = (props) => (
  <Fragment>
    {props.text} <Tag minimal={true}>Required</Tag>
  </Fragment>
);
