import { Spinner } from '@blueprintjs/core';
import type { FC } from 'react';

import styles from './Map.module.scss';

interface MapPlaceholderProps {
  className?: string;
}

/**
 *
 */
export const MapPlaceholder: FC<MapPlaceholderProps> = (props) => (
  <div className={[styles.map, props.className].join(' ').trim()}>
    <Spinner className={styles.container} size={150} />
  </div>
);
