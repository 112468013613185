import { Card, Elevation } from '@blueprintjs/core';
import { useMemo, FC } from 'react';

import { useDebug } from '../../../../../lib/hooks/debug';
import { Duration } from '../InsightsProvider/types';
import { useCounts } from '../InsightsProvider/useCounts';
import { useSelected } from '../SelectedProvider/useSelected';

import styles from './Counts.module.scss';

interface CountsProps {
  duration: Duration;
}

/**
 *
 * @param props
 */
export const Counts: FC<CountsProps> = (props) => {
  const debug = useDebug();
  const [selected] = useSelected();

  const { correct, incorrect, reads, unsure } = useCounts(
    selected,
    props.duration
  );

  const title = useMemo(() => {
    return [
      props.duration.slice(0, 1).toUpperCase(),
      props.duration.slice(1).toLowerCase()
    ].join('');
  }, [props.duration]);

  return (
    <Card className={styles.card} elevation={Elevation.ONE}>
      <header className={styles.heading}>{title}</header>
      <div className={styles.correct}>{correct.toLocaleString()}</div>
      {debug && (
        <div className={styles.debug}>
          <div className={styles.total}>
            {(correct + incorrect + unsure).toLocaleString()}
          </div>
          <div className={styles.incorrect}>{incorrect.toLocaleString()}</div>
          <div className={styles.unsure}>{unsure.toLocaleString()}</div>
        </div>
      )}
      <div className={styles.reads}>{reads.toLocaleString()}</div>
    </Card>
  );
};
