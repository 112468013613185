import { dynamicLoader } from '../../atoms/DynamicLoader';

import { StreamViewPlaceholder } from './StreamViewPlaceholder';

export default dynamicLoader(
  () =>
    import(
      /* webpackChunkName: 'stream-view', webpackPreload: true */
      './StreamView'
    ),
  StreamViewPlaceholder
);
