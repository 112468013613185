import type { CurrentUser } from '../../../components/context/current-user/get-current-user';
import Sentry from '../../utils/sentry';
import { WHOAMI } from '../queries/authentication';

export const query = WHOAMI;

let lastEmail = '';
let hasInit = false;

/**
 *
 * @param result
 */
export function subscriber(result: { data: { whoami: CurrentUser } }): void {
  const whoami = result.data.whoami;

  if (!whoami) {
    return;
  }

  // Handle login/logout context on the websocket
  if (lastEmail !== whoami.email && hasInit) {
    lastEmail = whoami.email || '';
  }

  if (!hasInit) {
    hasInit = true;
  }

  Sentry.configureScope((scope) => {
    scope.setUser({
      email: whoami.email || undefined,
      name: whoami.name
    });
  });
}
