/**
 *
 * @param changed
 */
export function hasChanged(changed: { [name: string]: boolean }): boolean {
  for (const hasChanged of Object.values(changed)) {
    if (hasChanged) {
      return true;
    }
  }

  return false;
}

/**
 *
 * @param errors
 */
export function hasErrors(errors: { [name: string]: string | null }): boolean {
  for (const error of Object.values(errors)) {
    if (error !== null) {
      return true;
    }
  }

  return false;
}
