import { colors } from '@vgrid/colors';
import { SVGProps, FC } from 'react';

/**
 *
 */
export const VGridLogoFavicon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.6 35.41" {...props}>
    <path
      d="M15.24 34.92 29.78.49l12.43.02-14.63 34.46-12.35-.05Z"
      style={{ fill: colors.royal_blue.DEFAULT }}
    />
    <path
      d="M15.17 34.97.54.51h6.04c3.87-.01 7.37 2.3 8.87 5.87l12.06 28.55-12.35.05Z"
      style={{ fill: colors.malibu.DEFAULT }}
    />
  </svg>
);
