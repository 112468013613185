export type LayoutName =
  | 'map'
  | 'mapMontage'
  | 'bigSmall'
  | 'single'
  | 'twoTwo'
  | 'threeTwo'
  | 'threeThree'
  | 'fourThree'
  | 'fourFour';

interface LayoutType {
  label: string;
  lite: boolean;
  max: number;
  showMap: boolean;
}

type LayoutTypes = Record<LayoutName, LayoutType>;

export const layouts: LayoutTypes = {
  bigSmall: {
    label: '1 big, 5 small',
    lite: false,
    max: 6,
    showMap: false
  },

  fourFour: {
    label: '4 x 4',
    lite: false,
    max: 16,
    showMap: false
  },

  fourThree: {
    label: '4 x 3',
    lite: false,
    max: 12,
    showMap: false
  },

  map: {
    label: 'Map',
    lite: false,
    max: 0,
    showMap: true
  },

  mapMontage: {
    label: 'Map & montage',
    lite: false,
    max: 12,
    showMap: true
  },

  single: {
    label: 'Single frame',
    lite: true,
    max: 1,
    showMap: false
  },

  threeThree: {
    label: '3 x 3',
    lite: false,
    max: 9,
    showMap: false
  },

  threeTwo: {
    label: '3 x 2',
    lite: false,
    max: 6,
    showMap: false
  },

  twoTwo: {
    label: '2 x 2',
    lite: true,
    max: 4,
    showMap: false
  }
};
