import { FC } from 'react';

import { useStatusPageUrl } from '../../../lib/hooks/status-page-url';

import styles from './StatusPageLink.module.scss';

export const StatusPageLink: FC = () => {
  const { statusPageUrl } = useStatusPageUrl();

  return (
    <div className={styles.statusPageLink}>
      <a href={statusPageUrl} target="__blank">
        vGRID Status Page
      </a>
    </div>
  );
};
