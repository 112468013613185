import { useQuery } from '@apollo/client';
import { Card, Elevation, Spinner } from '@blueprintjs/core';
import { createContext, FC, Fragment, ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { customers, Localisation } from '../../data/localisation';
import { GET_ORGANISATION_CONFIG } from '../../lib/graphql/queries/organisation';
import { useCurrentUser } from '../../lib/hooks/current-user';
import { useLocalisationInit } from '../../lib/localisation/localisation-init';

interface LocalisationContextProps {
  readonly children: ReactNode;
  readonly hostname: string;
}

interface OrganisationConfigResult {
  organisationConfig: {
    items: {
      name: string;
      value: string;
    }[];
  };
}

export const LocalisationContext = createContext<Localisation>({
  ...customers.default,
  _values: {},
  get: () => undefined,
  getDecoded: () => undefined
});

/**
 *
 * @param props
 */
export const LocalisationContextProvider: FC<LocalisationContextProps> = (
  props
) => {
  const currentUser = useCurrentUser();
  const { data, loading, refetch } = useQuery<OrganisationConfigResult>(
    GET_ORGANISATION_CONFIG,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const _values =
    data?.organisationConfig.items.reduce(
      (obj, item) => {
        obj[item.name] = item.value;
        return obj;
      },
      {} as Record<string, string>
    ) || {};

  const cssUrl =
    _values['ui.web.global.cssUrl'] ||
    customers[props.hostname]?.cssUrl ||
    customers.default?.cssUrl;

  useEffect(() => {
    refetch();
  }, [currentUser]);

  useLocalisationInit(_values);

  if (loading) {
    return (
      <div className="vg-anonymous">
        <div className="vg-container vg-page vg-page--loading">
          <Card elevation={Elevation.ONE}>
            <div className="vg-spinner">
              <Spinner size={150} />
            </div>
          </Card>
        </div>
      </div>
    );
  }

  const get = (key: string) => {
    const keyParts = key.split('.');
    if (keyParts[0] !== 'ui') return undefined;
    // Try web-specific config first
    if (keyParts[1] !== 'web') {
      keyParts.splice(1, 0, 'web');

      // eslint-disable-next-line security/detect-object-injection
      return _values[keyParts.join('.')] || _values[key];
    }

    // eslint-disable-next-line security/detect-object-injection
    return _values[key];
  };

  function getDecoded<T = JSONValue>(key: string) {
    const value = get(key);
    if (!value) return undefined;
    try {
      return JSON.parse(value) as T;
    } catch (e) {
      console.error('Error decoding JSON value for ' + key);
    }

    return undefined;
  }

  const value = {
    ...(customers[props.hostname] || customers.default),
    _values,
    get,
    getDecoded
  };

  return (
    <LocalisationContext.Provider value={value}>
      <Fragment>
        {cssUrl && (
          <Helmet>
            <link rel="stylesheet" href={cssUrl} />
          </Helmet>
        )}
        {props.children}
      </Fragment>
    </LocalisationContext.Provider>
  );
};
