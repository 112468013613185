import { useQuery } from '@apollo/client';
import { Spinner } from '@blueprintjs/core';
import { FC, ReactNode, useEffect, useMemo } from 'react';

import { ErrorCallout } from '../../../../atoms/ErrorCallout';

import { getCounts } from './getCounts';
import { getHeatmap } from './getHeatmap';
import { getStreams } from './getStreams';
import { getTables } from './getTables';
import { InsightsContext } from './InsightsContext';
import { INSIGHTS } from './query';
import { Insights, InsightsResponse } from './types';

/**
 *
 * @param props
 */
export const InsightsProvider: FC<{ children: ReactNode }> = (props) => {
  const { data, error, refetch } = useQuery<InsightsResponse>(INSIGHTS, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first'
  });

  // Updates data every 30 minutes
  useEffect(() => {
    const refreshInterval = setInterval(refetch, 18e5);

    return () => clearInterval(refreshInterval);
  });

  const counts = useMemo(() => getCounts(data), [data]);
  const heatmap = useMemo(() => getHeatmap(data), [data]);
  const streams = useMemo(() => getStreams(data), [data]);
  const tables = useMemo(() => getTables(data), [data]);

  if (error) {
    return <ErrorCallout error={error} retry={refetch} />;
  }

  if (!data) {
    return <Spinner size={150} />;
  }

  const value: Insights = {
    counts,
    heatmap,
    sites: data.sites,
    streams,
    tables
  };

  return (
    <InsightsContext.Provider value={value}>
      {props.children}
    </InsightsContext.Provider>
  );
};
