import { useLocalisation } from './localisation';

export const STATUS_PAGE_URL = 'https://vgrid-nz.statuspage.io';
/**
 *
 */
export const useStatusPageUrl = () => {
  const localisation = useLocalisation();

  const getStatusPageUrl = localisation.get('ui.global.statuspage.url');

  const statusPageUrl = getStatusPageUrl || STATUS_PAGE_URL;
  const isDefaultStatusPage = statusPageUrl === STATUS_PAGE_URL;

  return {
    isDefaultStatusPage,
    statusPageUrl
  };
};
