import type { ApolloClient } from '@apollo/client';
import Push from 'push.js';

import { REMOVE_NOTIFICATION } from '../graphql/localQueries/notifications';

/**
 *
 * @param client
 * @param id
 */
export function removeNotification(
  client: ApolloClient<any>,
  id: string
): void {
  client
    .mutate({
      mutation: REMOVE_NOTIFICATION,
      variables: { id }
    })
    .catch((error: Error) => console.error(error));

  if (Push.Permission.has()) {
    Push.close(id);
  }
}
