import { colors } from '@vgrid/colors';
import { SVGProps, FC } from 'react';

interface LogoProps extends SVGProps<SVGSVGElement> {
  light?: boolean;
}

/**
 *
 *
 */
export const VGridLogo: FC<LogoProps> = (props) => {
  const { light, ...rest } = props;

  const letterFill = light ? '#ffffff' : colors.safercities_blue.DEFAULT;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 208.43 47.64"
      {...rest}
    >
      <path
        style={{ fill: colors.royal_blue.DEFAULT }}
        d="M15.97,45.72L30.51,11.29l12.43.02-14.63,34.46-12.35-.05Z"
      />
      <path
        style={{ fill: colors.malibu.DEFAULT }}
        d="M15.9,45.77L1.28,11.3h6.04c3.87-.01,7.37,2.3,8.87,5.87l12.06,28.55-12.35.05Z"
      />
      <path
        style={{ fill: letterFill }}
        d="M78.26,22.86h11.06v18.17c-2.51,1.8-5.39,3.17-8.64,4.13-3.26.96-6.51,1.44-9.77,1.44-4.59,0-8.73-.97-12.4-2.91-3.68-1.94-6.56-4.65-8.64-8.11-2.09-3.47-3.13-7.39-3.13-11.78s1.04-8.31,3.13-11.78c2.09-3.47,4.99-6.17,8.71-8.11,3.72-1.94,7.91-2.91,12.59-2.91,4.09,0,7.77.69,11.03,2.07,3.26,1.38,5.97,3.36,8.14,5.95l-7.89,7.14c-2.97-3.26-6.51-4.89-10.65-4.89-3.76,0-6.79,1.14-9.08,3.41-2.3,2.28-3.45,5.31-3.45,9.11,0,2.42.52,4.58,1.57,6.48,1.04,1.9,2.51,3.38,4.38,4.45,1.88,1.06,4.03,1.6,6.45,1.6s4.61-.48,6.7-1.44l-.1-12.03Z"
      />
      <path
        style={{ fill: letterFill }}
        d="M115.51,34.07h-6.77v11.65h-12.4V1.88h20.04c3.97,0,7.41.66,10.34,1.97,2.92,1.32,5.18,3.19,6.77,5.64,1.59,2.44,2.38,5.31,2.38,8.61s-.74,5.94-2.22,8.3c-1.48,2.36-3.6,4.21-6.36,5.54l9.46,13.78h-10.55c-1.71,0-3.3-.84-4.26-2.25l-6.42-9.4ZM121.4,13.34c-1.29-1.13-3.22-1.69-5.76-1.69h-6.89v12.84h6.89c2.55,0,4.47-.55,5.76-1.66,1.29-1.11,1.94-2.68,1.94-4.73s-.65-3.63-1.94-4.76Z"
      />
      <path
        style={{ fill: letterFill }}
        d="M142.37,1.88h12.4v43.85h-12.4V1.88Z"
      />
      <path
        style={{ fill: letterFill }}
        d="M161.76,1.88h20.73c4.8,0,9.06.9,12.78,2.69,3.72,1.8,6.6,4.34,8.64,7.64,2.05,3.3,3.07,7.16,3.07,11.59s-1.02,8.29-3.07,11.59c-2.05,3.3-4.93,5.85-8.64,7.64-3.72,1.8-7.98,2.69-12.78,2.69h-15.57c-2.85,0-5.16-2.31-5.16-5.16V1.88ZM182,35.83c3.76,0,6.77-1.06,9.05-3.19,2.28-2.13,3.41-5.07,3.41-8.83s-1.14-6.7-3.41-8.83c-2.28-2.13-5.29-3.19-9.05-3.19h-7.83v24.05h7.83Z"
      />
    </svg>
  );
};
