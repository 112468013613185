import {
  Callout,
  Card,
  Classes,
  Elevation,
  FormGroup,
  H4,
  Intent,
  Switch
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { useFeatureFlags } from '../../../../lib/hooks/feature-flags';
import { usePermissions } from '../../../../lib/hooks/permissions';

import styles from './BetaFeatures.module.scss';

/**
 *
 */
export const BetaFeatures: FC = () => {
  const {
    admin: { superadmin }
  } = usePermissions();

  const { featureFlags, features, setFeatureFlag } = useFeatureFlags();

  const allowedFeatures = (
    Object.entries(features) as Entries<typeof features>
  ).filter(([_key, feature]) => !feature.superadmin || superadmin);
  const hasFeatures = allowedFeatures.length > 0;

  return (
    <div className="vg-container vg-page">
      <Helmet>
        <title>Beta Features - vGRID</title>
      </Helmet>

      <div className="vg-page__header">
        <ul className={Classes.BREADCRUMBS}>
          <li>
            <Link className={Classes.BREADCRUMB} to="/">
              vGRID
            </Link>
          </li>
          <li>
            <Link className={Classes.BREADCRUMB} to="/account">
              Account
            </Link>
          </li>
          <li>
            <span
              className={classNames(
                Classes.BREADCRUMB,
                Classes.BREADCRUMB_CURRENT
              )}
            >
              Beta Features
            </span>
          </li>
        </ul>
      </div>

      <Card elevation={Elevation.ONE}>
        <Callout intent={Intent.PRIMARY} icon={IconNames.BUG}>
          <H4>Beta Features</H4>
          These features are in the testing phase, so while we expect them to
          work, they may have some bugs. Your feedback helps us make them
          better, so please let us know if you encounter any issues. Enjoy
          exploring!
        </Callout>
        {!hasFeatures ? (
          <div className={classNames('vg-admin__form', styles.form)}>
            <Callout intent={Intent.SUCCESS}>
              No beta features are currently available.
            </Callout>
          </div>
        ) : (
          <div className={classNames('vg-admin__form', styles.form)}>
            {allowedFeatures.map(([key, feature]) => (
              <FormGroup
                key={key}
                label={feature.name}
                helperText={feature.description}
              >
                <Switch
                  // eslint-disable-next-line security/detect-object-injection
                  checked={featureFlags[key]}
                  className="vg-form-group--inline"
                  large
                  onChange={(event) => {
                    setFeatureFlag(key, event.currentTarget.checked);
                  }}
                />
              </FormGroup>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
};
