import { Card, Classes, Elevation, Spinner } from '@blueprintjs/core';
import classNames from 'classnames';
import type { FC } from 'react';
import { Link } from 'react-router-dom';

/**
 *
 */
export const LoadingPage: FC = () => (
  <div className="vg-container vg-page vg-page--loading">
    <div className="vg-page__header">
      <ul className={classNames(Classes.OVERFLOW_LIST, Classes.BREADCRUMBS)}>
        <li>
          <Link className={Classes.BREADCRUMB} to="/">
            vGRID
          </Link>
        </li>
        <li>
          <span
            className={classNames(
              Classes.BREADCRUMBS_COLLAPSED,
              Classes.SKELETON
            )}
          >
            &nbsp;
          </span>
        </li>
      </ul>
    </div>
    <Card elevation={Elevation.ONE}>
      <div className="vg-spinner">
        <Spinner size={150} />
      </div>
    </Card>
  </div>
);
