import type { DetailedHTMLProps, ImgHTMLAttributes, FC } from 'react';

import { useLocalisation } from '../../../lib/hooks/localisation';

type CustomerLogoProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

/**
 *
 * @param props
 */
export const CustomerLogo: FC<CustomerLogoProps> = (props) => {
  const localisation = useLocalisation();
  const url = localisation.logoUrl || localisation.get('ui.global.logoUrl');

  return url ? <img src={url} {...props} /> : null;
};
