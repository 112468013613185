import { createContext, Dispatch, SetStateAction } from 'react';

import { SearchResult } from '../../organisms/Header/Search/types';

export type SearchValue = [
  SearchResult | null,
  Dispatch<SetStateAction<SearchResult | null>>
];

/**
 *
 */
export const SearchContext = createContext<SearchValue>([null, () => null]);
