import { createElement, FC, ReactNode, useEffect, useState } from 'react';

import { DebugContext } from './DebugContext';

const DEV_KEY = 'DEV_MODE';

/**
 *
 */
function isDebug(): boolean {
  return Boolean(window.localStorage.getItem(DEV_KEY));
}

/**
 *
 * @param props
 */
export const DebugProvider: FC<{ children: ReactNode }> = (props) => {
  const [value, setValue] = useState<boolean>(isDebug);

  useEffect(() => {
    window.enableDebug = () => {
      setValue(true);
      window.localStorage.setItem(DEV_KEY, '1');
    };

    window.disableDebug = () => {
      setValue(false);
      window.localStorage.removeItem(DEV_KEY);
    };

    // Capture change from another tab
    // Storage event doesn't get triggered for current tab

    function checkDebug(): void {
      setValue(isDebug());
    }

    window.addEventListener('storage', checkDebug, false);

    return window.removeEventListener('storage', checkDebug);
  }, []);

  return createElement(DebugContext.Provider, { value }, props.children);
};
