import { useApolloClient } from '@apollo/client';
import { Button, ButtonGroup, Classes, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Push from 'push.js';
import { FC, Fragment, MouseEventHandler, useCallback } from 'react';

import { handlers } from '../../../lib/notifications/handlers';
import { Notification } from '../../../lib/notifications/notification-types';
import { removeNotification } from '../../../lib/notifications/remove';

/**
 *
 * @param props
 */
export const Toast: FC<Notification> = (props) => {
  const classNames = [Classes.TOAST, Classes.intentClass(props.intent)];
  const client = useApolloClient();

  const onClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event): void => {
      event.preventDefault();
      event.stopPropagation();

      removeNotification(client, props.id);

      if (Push.Permission.has()) {
        Push.close(props.id);
      }

      if (props.handler) {
        const handler = handlers[props.handler[0]];
        handler(client, ...props.handler.slice(1));
      }
    },
    [props.id]
  );

  const onDismiss = useCallback<MouseEventHandler<HTMLElement>>(
    (event): void => {
      event.preventDefault();
      event.stopPropagation();

      removeNotification(client, props.id);

      if (Push.Permission.has()) {
        Push.close(props.id);
      }
    },
    [props.id]
  );

  const message = props.message.split('\n').map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));

  return (
    <div className={classNames.join(' ')} onClick={onClick} tabIndex={0}>
      <Icon icon={props.icon} />
      <div className={Classes.TOAST_MESSAGE}>
        <div className="vg-notification__title">{props.title}</div>
        <div className="vg-notification__message">{message}</div>
      </div>
      <ButtonGroup minimal={true}>
        <Button icon={IconNames.CROSS} onClick={onDismiss} title="Dismiss" />
      </ButtonGroup>
    </div>
  );
};
