import { FC, Fragment, memo } from 'react';

import type { Cluster } from '../types';

import { Cluster as ClusterEl } from './Cluster';

interface ClusterWrapperProps {
  clusters?: Cluster[];
}

export const ClusterWrapper: FC<ClusterWrapperProps> = memo((props) => {
  if (!props.clusters?.length) {
    return null;
  }

  return (
    <Fragment>
      {props.clusters.map((cluster) => (
        <ClusterEl
          position={[cluster.lat, cluster.lng]}
          key={cluster.id}
          count={cluster.count}
          zoom={cluster.zoom}
        />
      ))}
    </Fragment>
  );
});
