import { isEmail, minLength } from '../../../lib/utils/validation';

export const schema = {
  email: {
    formatter: (email: string): string => email.toLowerCase(),
    validators: [
      {
        message: 'Email is a required field',
        test: true
      },
      {
        message: 'Not a valid email address',
        test: isEmail
      }
    ]
  },

  message: {
    validators: [
      {
        message: 'Message is a required field',
        test: true
      },
      {
        message: 'Message must be at least 10 characters long',
        test: minLength(10)
      }
    ]
  },

  name: {
    validators: [
      {
        message: 'Name is a required field',
        test: true
      },
      {
        message: 'Name must be at least 5 characters long',
        test: minLength(5)
      }
    ]
  }
};
