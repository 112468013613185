import type { ApolloClient } from '@apollo/client';

import { anpr } from './subscriptions/anpr';
import { remoteCommands } from './subscriptions/remote-commands';
import { statusPage } from './subscriptions/status-page';
import { version } from './subscriptions/version';

/**
 *
 * @param client
 */
export async function initNotifications(
  client: ApolloClient<any>,
  isDefaultStatusPage: boolean,
  anonymous?: boolean
): Promise<() => void> {
  const subscriptionPromises = [version(client)];

  if (isDefaultStatusPage) {
    subscriptionPromises.push(statusPage(client));
  }

  if (!anonymous) {
    subscriptionPromises.push(anpr(client), remoteCommands(client));
  }

  const subscriptions = await Promise.all(subscriptionPromises);

  return (): void => {
    for (const unsubscribe of subscriptions) {
      unsubscribe();
    }
  };
}
