import { NotificationSound } from '../notification-types';

const files: Record<NotificationSound, string> = {
  dingdong: require('./dingdong.wav'),
  woopwoop: require('./woopwoop.wav')
};

export const audio: Partial<Record<NotificationSound, HTMLAudioElement>> = {};

for (const [type, file] of Object.entries(files)) {
  try {
    const element: HTMLAudioElement = new Audio(file);
    element.load();

    audio[type as NotificationSound] = element;
  } catch (error) {
    console.error(error);
  }
}
