import { useContext } from 'react';

import { DebugContext } from '../../components/context/debug/DebugContext';

/**
 *
 */
export function useDebug(): boolean {
  return useContext<boolean>(DebugContext);
}
