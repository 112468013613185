import { Fragment } from 'react';
import { FeatureLayer, ImageMapLayer, TiledMapLayer } from 'react-esri-leaflet';
import VectorBasemapLayer from 'react-esri-leaflet/plugins/VectorBasemapLayer';
import VectorTileLayer from 'react-esri-leaflet/plugins/VectorTileLayer';
import { LayersControl, TileLayer, WMSTileLayer } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

export const supported: { [key: string]: any } = {
  ESRI: {
    FeatureLayer,
    ImageMapLayer,
    TiledMapLayer,
    VectorBasemapLayer,
    VectorTileLayer
  },
  Fragment,
  LayersControl,
  ReactLeafletGoogleLayer,
  TileLayer,
  WMSTileLayer
};
