import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  Intent,
  TextArea
} from '@blueprintjs/core';
import { FC, Fragment, ReactElement } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { SET_SESSION_PURPOSE } from '../../../lib/graphql/queries/session';
import { useLocalisation } from '../../../lib/hooks/localisation';
import { Form } from '../../molecules/Form';
import type { FormSchemaRequiredField } from '../../molecules/Form/validate';
import { JsonContent } from '../../organisms/JsonContent/JsonContent';

import styles from './SessionContext.module.scss';

const schema: FormSchemaRequiredField<'validators'> = {
  purpose: {
    validators: [
      {
        message: 'Your session purpose must be at least 5 characters long',
        test: (value: string) => value.length >= 5
      }
    ]
  }
};

const defaultValues = {
  reason: ''
};

export const SessionContext: FC<{ children: ReactElement }> = ({
  children
}) => {
  const localisation = useLocalisation();
  const [showSessionPurpose, setShowSessionPurpose] = useSessionStorage(
    'vgrid.require-purpose',
    !!localisation.get('ui.session.purpose.required')
  );

  if (!showSessionPurpose) {
    return <Fragment>{children}</Fragment>;
  }

  const purposeNote = localisation.get('ui.session.purpose.note');
  const purposeTitle =
    localisation.get('ui.session.purpose.title') ||
    'Please enter a purpose for your session';
  const purposePlaceholder =
    localisation.get('ui.session.purpose.placeholder') || 'Purpose of session';

  const purposeValidation = localisation.get('ui.session.purpose.validation');

  if (purposeValidation) {
    schema.purpose.validators.push({
      message: 'Invalid',
      // eslint-disable-next-line security/detect-non-literal-regexp
      test: (value: string) => new RegExp(purposeValidation).test(value)
    });
  }

  return (
    <Dialog
      className={styles.sessionDialog}
      isOpen={true}
      isCloseButtonShown={false}
      title={purposeTitle}
    >
      <Form
        mutation={SET_SESSION_PURPOSE}
        schema={schema}
        defaultValues={defaultValues}
        onCompleted={() => setShowSessionPurpose(false)}
      >
        {(formProps) => (
          <Fragment>
            <div className={Classes.DIALOG_BODY}>
              {purposeNote && (
                <JsonContent
                  componentKey="purpose-note"
                  content={purposeNote}
                />
              )}
              <FormGroup
                helperText={formProps.errors.purpose || 'Required'}
                intent={formProps.intents.purpose}
                labelFor="purpose"
              >
                <TextArea
                  autoFocus={true}
                  fill={true}
                  id="purpose"
                  intent={formProps.intents.purpose}
                  name="purpose"
                  onChange={formProps.onChange}
                  placeholder={purposePlaceholder}
                  required={true}
                  rows={2}
                  value={formProps.values.purpose}
                />
              </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  disabled={formProps.disabled}
                  intent={Intent.PRIMARY}
                  loading={formProps.loading}
                  text="Submit"
                  type="submit"
                />
              </div>
            </div>
          </Fragment>
        )}
      </Form>
    </Dialog>
  );
};
