import { Button, Intent, MenuItem } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';
import { FC, useEffect, useState } from 'react';

import styles from './StaticListSelect.module.scss';

export interface ListEntity {
  id: string;
  name: string;
  icon?: IconName;
}

export interface ChangeEventValue {
  currentTarget: {
    name: string;
    value: string;
  };
}

interface ListSelectOptions {
  matchTargetWidth?: boolean;
}

interface StaticListSelectProps {
  /**
   * When set, if `items` contains a single item,
   * and the item is not disabled by the prop `itemDisabled`,
   * `onChange` will be called with this single item as an argument.
   */
  autoSelectOnlyItem?: boolean;
  className?: string;
  disabled?: boolean;
  fill?: boolean;
  filterable?: boolean;
  id: string;
  intent?: Intent;
  items: ListEntity[];
  large?: boolean;
  minimal?: boolean;
  onChange: (event: ChangeEventValue) => void;
  options?: ListSelectOptions;
  rightIcon?: IconName;
  icon?: IconName;
  /**
   * Function that determines which items in the list are disabled (non-selectable)
   */
  itemDisabled?: (item: ListEntity) => boolean;
  /**
   * Controlled value of this component.
   *
   * Will be matched against id's from the `items` array to
   * determine the `selected` item.
   */
  value: string | undefined;
}

const DEFAULT_TEXT = 'Select...';
const NO_ITEMS_TEXT = 'No items to select';

/**
 *
 * @param query
 * @param item
 * @param _index
 * @param exactMatch
 * @returns
 */
const filterOptions: ItemPredicate<ListEntity> = (
  query,
  item,
  _index,
  exactMatch
) => {
  const normalizedText = item.name.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  return exactMatch
    ? normalizedText === normalizedQuery
    : normalizedText.indexOf(normalizedQuery) >= 0;
};

/**
 *
 * @param item
 * @param itemProps
 * @returns
 */
const renderOption: ItemRenderer<ListEntity> = (
  item,
  { handleClick, handleFocus, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={item.id}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={item.name}
      icon={item.icon}
    />
  );
};

/**
 *
 */
const noResults = (
  <MenuItem disabled={true} text="No results." roleStructure="listoption" />
);

/**
 *
 * @param props
 * @returns
 */
export const StaticListSelect: FC<StaticListSelectProps> = (props) => {
  const [query, setQuery] = useState<string>('');
  const selectedItem = props.items.find((item) => item.id === props.value);

  const [activeItem, setActiveItem] = useState<ListEntity | null>(
    selectedItem ?? null
  );

  const text =
    props.items.length === 0
      ? NO_ITEMS_TEXT
      : selectedItem?.name ?? DEFAULT_TEXT;

  useEffect(() => {
    setActiveItem(selectedItem ?? null);
  }, [selectedItem?.id]);

  // Auto select only item logic
  useEffect(() => {
    if (!props.autoSelectOnlyItem || props.items.length !== 1) {
      return;
    }

    if (selectedItem) {
      return;
    }

    const item = props.items[0];

    if (props.itemDisabled && props.itemDisabled(item)) {
      return;
    }

    props.onChange({
      currentTarget: {
        name: props.id,
        value: item.id
      }
    });
  }, [props.onChange, props.itemDisabled, props.items, selectedItem?.id]);

  return (
    <Select<ListEntity>
      activeItem={activeItem}
      className={styles.container}
      disabled={props.disabled || props.items.length === 0}
      fill={props.fill}
      filterable={props.filterable ?? false}
      itemDisabled={props.itemDisabled}
      itemPredicate={filterOptions}
      itemRenderer={renderOption}
      items={props.items}
      itemsEqual={'id'}
      noResults={noResults}
      // NOTE: if `items` reference is not stable between renders, this is called
      // on every render with the first item of the new items array.
      // https://github.com/palantir/blueprint/issues/4562
      onActiveItemChange={setActiveItem}
      onItemSelect={(item, ev) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        props.onChange({
          currentTarget: {
            name: props.id,
            value: item.id
          }
        });
      }}
      onQueryChange={setQuery}
      popoverContentProps={{
        className: styles.popoverContent
      }}
      popoverProps={{
        matchTargetWidth: props.options?.matchTargetWidth
      }}
      query={query}
      // Workaround. See onActiveItemChange
      resetOnQuery={false}
    >
      <Button
        className={props.className}
        disabled={props.disabled ?? props.items.length === 0}
        fill={props.fill}
        intent={props.intent}
        large={props.large}
        minimal={props.minimal}
        // Workaround. see onActiveItemChange
        // reset whe dropdown is opened
        onClick={() => {
          setActiveItem(selectedItem ?? null);
          setQuery('');
        }}
        rightIcon={props.rightIcon ?? IconNames.CARET_DOWN}
        icon={props.icon}
        text={text}
        title={text}
      />
    </Select>
  );
};
