import { HTMLTable } from '@blueprintjs/core';
import { FC, ReactNode } from 'react';

import styles from './DetailTable.module.scss';

interface DetailTableProps {
  readonly children: ReactNode;
  readonly header: string;
}

/**
 *
 * @param props
 */
export const DetailTable: FC<DetailTableProps> = (props) => (
  <HTMLTable className={styles.detailTable} striped={true}>
    <colgroup>
      <col width="40%" />
      <col width="60%" />
    </colgroup>
    <thead>
      <tr>
        <th colSpan={2}>{props.header}</th>
      </tr>
    </thead>
    <tbody>{props.children}</tbody>
  </HTMLTable>
);
