import { gql } from '@apollo/client';

export const EJECT_SESSION = gql`
  mutation ejectSession($key: String!) {
    ejectSession(key: $key)
  }
`;

export const GET_SESSIONS = gql`
  query getSessions {
    activeUsers {
      client
      key
      lastPage
      user {
        id
        name
      }
      userAgent {
        browserName
        browserVersion
        id
        osName
        osVersion
      }
      videoSessions {
        start
        streamId
      }
    }
  }
`;

export const RELOAD_SESSION = gql`
  mutation reloadSession($key: String!) {
    reloadSession(key: $key)
  }
`;

export const SET_SESSION_PURPOSE = gql`
  mutation sessionPurpose($purpose: String!) {
    sessionPurpose(purpose: $purpose)
  }
`;
