import { gql } from '@apollo/client';

export const ADD_NOTIFICATION = gql`
  mutation addNotification($notification: Object!) {
    addNotification(notification: $notification) @client
  }
`;

export const CLEAR_NOTIFICATIONS = gql`
  mutation clearNotifications {
    clearNotifications @client
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getNotifications {
    notifications @client {
      id
      handler
      icon
      intent
      message
      title
    }
  }
`;

export const GET_NOTIFICATIONS_SETTINGS = gql`
  query getNotificationsSettings {
    notificationsSettings @client {
      disableComputer
      disableTab
      muteComputer
    }
  }
`;

export const REMOVE_NOTIFICATION = gql`
  mutation removeNotification($id: ID!) {
    removeNotification(id: $id) @client
  }
`;

export const SET_NOTIFICATIONS_SETTING = gql`
  mutation setNotificationsSetting($key: String!, $value: Boolean!) {
    setNotificationsSetting(key: $key, value: $value) @client {
      disableComputer
      disableTab
      muteComputer
    }
  }
`;
