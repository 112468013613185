import { LatLngBoundsLiteral, LatLngLiteral } from 'leaflet';
import { FC, useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';

import { useLocalisation } from '../../../../lib/hooks/localisation';
import { MapOptions } from '../Map';

interface SetMapOptionsProps {
  loaded: boolean;
  marker?: LatLngLiteral;
  options: MapOptions;
}

export const SetMapOptions: FC<SetMapOptionsProps> = (props) => {
  const map = useMap();
  const localisation = useLocalisation();
  const [markerPosition, setMarkerPosition] = useState<
    LatLngLiteral | undefined
  >(props.marker);

  // Sync the map with the options
  useEffect(() => {
    if ('bounds' in props.options && props.options.bounds) {
      const newBounds: LatLngBoundsLiteral = [
        [props.options.bounds.south, props.options.bounds.west],
        [props.options.bounds.north, props.options.bounds.east]
      ];

      if (!props.loaded || !map.getBounds().equals(newBounds)) {
        map.fitBounds(newBounds);
      }
    } else if ('center' in props.options && props.options.center) {
      if (!props.options.center.lat && !props.options.center.lng) {
        const localisedCenter = localisation.getDecoded<
          [number, number, number]
        >('ui.global.map.center');
        if (localisedCenter) {
          map.setView(
            [localisedCenter[0], localisedCenter[1]],
            localisedCenter[2] + (props.options.zoomOffset || 0)
          );
        } else {
          map.fitWorld();
        }
      } else {
        map.setView(
          props.options.center,
          props.options.zoom || map.getZoom() || 17
        );
      }
    }
  }, [props.loaded, props.options]);

  // Center the map when there's a marker
  useEffect(() => {
    if (
      !props.marker ||
      (props.marker.lat === markerPosition?.lat &&
        props.marker.lng === markerPosition.lng)
    )
      return;

    setMarkerPosition(props.marker);
    map.setView(props.marker, 15);
  }, [markerPosition, props.marker]);
  return null;
};
