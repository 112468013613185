import { gql } from '@apollo/client';

export interface AnprSiteMapResponse {
  anprSiteMap: {
    id: string;
  };
}

export const GET_ANPR_SITE_MAP = gql`
  query getAnprSiteMap($id: String!) {
    anprSiteMap(id: $id) @client {
      id
    }
  }
`;
