import { Icon } from '@blueprintjs/core';
import type { IconName } from '@blueprintjs/icons';
import { MouseEventHandler, useCallback, FC } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Navigation.module.scss';
import { useNavigation } from './NavigationContext';

interface NavButtonBaseProps {
  href: string;
  name: string;
}

interface NavButtonIconProps extends NavButtonBaseProps {
  icon: IconName;
  text?: string;
}

interface NavButtonTextProps extends NavButtonBaseProps {
  icon?: IconName;
  text: string;
}

type NavButtonProps = NavButtonIconProps | NavButtonTextProps;

/**
 *
 * @param props
 */
export const NavButton: FC<NavButtonProps> = (props) => {
  const { setItem } = useNavigation();

  const onMouseEnter = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.stopPropagation();
      setItem(props.name);
    },
    [props.name, setItem]
  );

  const onMouseLeave = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.stopPropagation();
      setItem(null);
    },
    [setItem]
  );

  return (
    <div
      className={styles.hitbox}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <NavLink
        className={({ isActive }) =>
          isActive ? `${styles.button} ${styles.active}` : styles.button
        }
        tabIndex={-1}
        to={props.href}
      >
        {props.icon && <Icon className={styles.icon} icon={props.icon} />}
        {props.text && <span className={styles.text}>{props.text}</span>}
      </NavLink>
    </div>
  );
};
