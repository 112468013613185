import { Classes, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { FC } from 'react';

import { useSites } from '../InsightsProvider/useSites';
import { useSelected } from '../SelectedProvider/useSelected';

import { SiteNode } from './SiteNode';

interface StreamListProps {
  className: string;
}

/**
 *
 * @param props
 */
export const StreamList: FC<StreamListProps> = (props) => {
  const [selected, setSelected] = useSelected();
  const sites = useSites();

  return (
    <div className={props.className}>
      <div className={Classes.TREE}>
        <ul className={[Classes.TREE_NODE_LIST, Classes.TREE_ROOT].join(' ')}>
          <li
            className={[
              Classes.TREE_NODE,
              selected ? '' : Classes.TREE_NODE_SELECTED
            ].join(' ')}
            onClick={() => setSelected(null)}
          >
            <div className={Classes.TREE_NODE_CONTENT}>
              <span className={Classes.TREE_NODE_CARET_NONE}>&nbsp;</span>
              <Icon
                className={[
                  Classes.TREE_NODE_ICON,
                  Classes.INTENT_PRIMARY
                ].join(' ')}
                icon={IconNames.HEATMAP}
              />
              <span className={Classes.TREE_NODE_LABEL}>All streams</span>
            </div>
          </li>
          {sites.map((site) => (
            <SiteNode key={site.id} site={site} />
          ))}
        </ul>
      </div>
    </div>
  );
};
