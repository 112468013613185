import { DivIcon, LatLngExpression } from 'leaflet';
import { FC, useCallback } from 'react';
import { Marker, useMap } from 'react-leaflet';

import styles from './Cluster.module.scss';

interface ClusterProps {
  position: LatLngExpression;
  count: number;
  zoom: number;
}

/**
 *
 * @param count
 */
export const getClusterIcon = (count: number) => {
  return new DivIcon({
    className: styles.cluster,
    html: `<span>${count}</span>`,
    iconAnchor: [20, 20],
    iconSize: [40, 40]
  });
};

/**
 *
 * @param props
 */
export const Cluster: FC<ClusterProps> = (props) => {
  const { position, count } = props;
  const map = useMap();

  const click = useCallback(() => {
    map.flyTo(props.position, props.zoom, {
      animate: true,
      duration: Math.min(0.75, (props.zoom - map.getZoom()) * 0.1)
    });
  }, [map, position]);

  const icon = getClusterIcon(count);

  return <Marker eventHandlers={{ click }} icon={icon} position={position} />;
};
