import {
  ApolloCache,
  ApolloClient,
  ApolloError,
  ApolloQueryResult,
  BaseMutationOptions,
  DocumentNode,
  FetchResult,
  NormalizedCacheObject,
  useMutation
} from '@apollo/client';
import { Intent } from '@blueprintjs/core';
import { FormEvent, SyntheticEvent, useCallback, useReducer } from 'react';

import {
  reducer,
  FormReducer,
  FormState,
  ChangeActionPayload
} from './reducer';
import {
  FieldSchema,
  FieldValue,
  FormSchema,
  FormValues,
  validate
} from './validate';

export interface FormOptions {
  ignoreNoChanges?: boolean;
  invertedValues?: string[];
  onCompleted?: (
    data: any,
    clientOptions?: BaseMutationOptions<any, any>
  ) => void;
  onUpdate?: (
    cache: ApolloCache<NormalizedCacheObject>,
    mutationResult: FetchResult<any>,
    client: ApolloClient<any>
  ) => void;
  parseValues?: (values: FormValues) => any;
  refetch?: (variables?: any) => Promise<ApolloQueryResult<any>>;
}

export interface FormResult {
  changed: { [name: string]: boolean };
  disabled: boolean;
  errors: { [name: string]: string | null };
  formError: null | string;
  intents: { [name: string]: Intent };
  loading: boolean;
  onBlur: (event: FormEvent<HTMLElement>) => void;
  onBulkChangeValue: (fields: Record<string, any>) => void;
  onChange: (event: FormEvent<HTMLElement>) => void;
  onChangeValue: (name: string, value: any) => void;
  reset: () => void;
  submit: (event: SyntheticEvent<any>) => void;
  values: FormValues;
}

/**
 *
 * @param schema
 * @param defaultValues
 */
function getInitialState(
  schema: FormSchema,
  defaultValues: FormValues
): FormState {
  /* eslint-disable security/detect-object-injection */

  const state: FormState = {
    changed: {},
    errors: {},
    hasChanges: false,
    hasErrors: false,
    intents: {},
    values: { ...defaultValues }
  };

  for (const key in state.values) {
    state.changed[key] = false;
    state.intents[key] = Intent.NONE;
    state.errors[key] = schema[key]
      ? validate(state.values[key], schema[key].validators, state.values)
      : null;
  }

  return state;
}

/**
 *
 * @param mutation
 * @param schema
 * @param defaultValues
 * @param options
 */
export function useForm(
  mutation: DocumentNode,
  schema: FormSchema,
  defaultValues: FormValues,
  options: FormOptions = {}
): FormResult {
  const [state, dispatch] = useReducer<FormReducer, FormState>(
    reducer,
    {
      changed: {},
      errors: {},
      hasChanges: false,
      hasErrors: false,
      intents: {},
      values: {}
    },
    () => getInitialState(schema, defaultValues)
  );

  const [mutate, { error, loading, client }] = useMutation(mutation, {
    onCompleted: options.onCompleted,
    update: (cache, mutationResult) => {
      options.onUpdate && options.onUpdate(cache, mutationResult, client);
    }
  });

  const disabled = options.ignoreNoChanges
    ? Object.values(state.errors).some(Boolean)
    : !state.hasChanges || state.hasErrors;

  let formError = null;
  if (error) {
    formError = 'Sorry, something went wrong.';

    if (error.graphQLErrors) {
      const fieldErrors: any[] = error.graphQLErrors.filter(
        (error) => error.extensions.code === 'USER_INPUT_ERROR'
      );

      const globalErrors: any[] = error.graphQLErrors.filter(
        (error) => error.extensions.code !== 'USER_INPUT_ERROR'
      );

      if (fieldErrors.length) {
        formError =
          'An error occurred with one or more fields. Please check these and try again.';
      }

      if (globalErrors.length) {
        formError = globalErrors[0].message;
      }

      const forbiddenError =
        error.graphQLErrors.filter(
          (error) => error.extensions.code === 'FORBIDDEN'
        ).length > 0;

      if (forbiddenError) {
        formError = 'You do not have permission to perform this action.';
      }
    }
  }

  const onBulkChangeValue = (fields: Record<string, any>): void => {
    const changes: ChangeActionPayload[] = [];
    const currentValues: FormValues = { ...state.values, ...fields };

    Object.entries(fields).forEach(([name, value]) => {
      const fieldSchema: FieldSchema = schema[name];

      if (fieldSchema && fieldSchema.formatter) {
        value = fieldSchema.formatter(value);
      }

      let error: string | null = null;
      if (fieldSchema) {
        error = validate(value, fieldSchema.validators, currentValues);
      }

      const dependencies = fieldSchema?.dependencies || [];
      for (const dep of dependencies) {
        // Don't check dependencies that are already being changed
        if (fields[dep]) continue;

        const depSchema: FieldSchema = schema[dep];
        const depError = depSchema
          ? validate(currentValues[dep], depSchema.validators, currentValues)
          : null;

        changes.push({
          changed: currentValues[dep] !== defaultValues[dep],
          error: depError,
          key: dep,
          value: currentValues[dep]
        });
      }

      changes.push({
        changed: value !== defaultValues[name],
        error,
        key: name,
        value
      });
    });

    dispatch({
      payload: changes,
      type: 'FormBulkChangeAction'
    });
  };

  const onChangeValue = (name: string, value: any): void => {
    const fieldSchema: FieldSchema = schema[name];

    if (fieldSchema && fieldSchema.formatter) {
      value = fieldSchema.formatter(value);
    }

    let error: string | null = null;
    if (fieldSchema) {
      error = validate(value, fieldSchema.validators, state.values);
    }

    const dependencies = fieldSchema?.dependencies || [];
    for (const dep of dependencies) {
      const depSchema: FieldSchema = schema[dep];

      const depError = depSchema
        ? validate(state.values[dep], depSchema.validators, {
            ...state.values,
            [name]: value
          })
        : null;

      dispatch({
        payload: {
          changed: state.values[dep] !== defaultValues[dep],
          error: depError,
          key: dep,
          value: state.values[dep]
        },
        type: 'FormChangeAction'
      });
    }

    dispatch({
      payload: {
        changed: value !== defaultValues[name],
        error,
        key: name,
        value
      },
      type: 'FormChangeAction'
    });
  };

  const onChange = (event: FormEvent<HTMLElement>): void => {
    const target: HTMLInputElement = event.currentTarget as HTMLInputElement;
    let value: FieldValue = target.value;

    if (target.type === 'checkbox') {
      value = target.checked;
      if (options.invertedValues?.includes(target.name)) {
        value = !target.checked;
      }
    }

    onChangeValue(target.name, value);
  };

  const reset = useCallback((): void => {
    dispatch({
      payload: getInitialState(schema, defaultValues),
      type: 'FormReset'
    });
  }, [dispatch, schema, defaultValues]);

  const submit = (event: SyntheticEvent<any>): void => {
    event.preventDefault();
    event.stopPropagation();

    if (disabled) {
      return;
    }

    if (!state.hasErrors) {
      const values = state.values;

      for (const [key, value] of Object.entries(values)) {
        const preSubmitFormatter = schema[key]?.preSubmitFormatter;
        if (preSubmitFormatter) {
          values[key] = preSubmitFormatter(value);
        }
      }

      const variables = options.parseValues
        ? options.parseValues(values)
        : values;
      mutate({ variables })
        .then(() => options.refetch && options.refetch())
        .catch((error: ApolloError): void => {
          const errors: Record<string, string> = {};
          for (const x of error.graphQLErrors) {
            if (
              x.extensions.code === 'USER_INPUT_ERROR' &&
              x.extensions.field
            ) {
              errors[x.extensions.field] = error.message;
            }
          }

          dispatch({
            payload: errors,
            type: 'FormErrorsAction'
          });
        });

      return;
    }

    const keys: string[] = [];
    for (const key in state.errors) {
      if (state.errors[key] !== null) {
        keys.push(key);
      }
    }

    dispatch({
      payload: keys,
      type: 'FormErrorIntentsAction'
    });
  };

  return {
    changed: state.changed,
    disabled,
    errors: state.errors,
    formError,
    intents: state.intents,
    loading,
    onBlur: onChange,
    onBulkChangeValue,
    onChange,
    onChangeValue,
    reset,
    submit,
    values: { ...defaultValues, ...state.values }
  };
}
