import { Navbar } from '@blueprintjs/core';
import type { FC } from 'react';

import styles from './Header.module.scss';
import { Logos } from './Logos/Logos';
import { Navigation } from './Navigation/Navigation';
import { Search } from './Search/Search';

/**
 *
 */
export const Header: FC = () => {
  return (
    <Navbar className={styles.header}>
      <Logos />
      <Search />
      <Navigation />
    </Navbar>
  );
};
