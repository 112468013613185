import { ApolloLink, Operation, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';

import { errorLink } from './error-link';
import { apiVersion, clientInstance, clientVersion } from './meta';
import { retryLink } from './retry-link';
import { WebSocketLink } from './websocket-link';

const WS_QUERIES = ['mutation.pageView', 'mutation.sessionPurpose'];

/**
 *
 * @param op
 */
function test(op: Operation): boolean {
  const definition = getMainDefinition(op.query);

  return definition.kind !== 'OperationDefinition'
    ? false
    : definition.operation === 'subscription' ||
        WS_QUERIES.includes(
          definition.operation + '.' + definition.name?.value
        );
}

/**
 *
 * @param subscriptions
 */
export function getNetworkLink(subscriptions: boolean): ApolloLink {
  const httpLink = createUploadLink({
    credentials: 'same-origin',
    headers: {
      'Apollo-Require-Preflight': 'true', // apollo server csrf prevention
      'X-Api-Version': apiVersion,
      'X-Client-Instance': clientInstance,
      'X-Client-Version': clientVersion
    },
    // Allow override - primarily for testing
    uri: window.__VGRID_API_URL__ ?? `https://${location.hostname}/api`
  });

  const networkLink = ApolloLink.from([errorLink, retryLink, httpLink]);

  if (!subscriptions) {
    return networkLink;
  }

  const wsLink = new WebSocketLink({
    connectionParams: { clientInstance, version: 2 },
    // Allow override - primarily for testing
    url: window.__VGRID_WS_URL__ ?? `wss://${location.hostname}/ws`
  });

  return split(test, wsLink, networkLink);
}
