import { useContext, useMemo } from 'react';

import { InsightsContext } from './InsightsContext';
import { Insights, Table } from './types';

const initial = {
  fri: new Array(24).fill(0),
  mon: new Array(24).fill(0),
  sat: new Array(24).fill(0),
  sun: new Array(24).fill(0),
  thu: new Array(24).fill(0),
  tue: new Array(24).fill(0),
  wed: new Array(24).fill(0)
};

/**
 *
 * @param a
 * @param b
 */
function sum(a: Table, b: Table): Table {
  /* eslint-disable security/detect-object-injection */

  return {
    fri: a.fri.map((v, i) => v + b.fri[i]),
    mon: a.mon.map((v, i) => v + b.mon[i]),
    sat: a.sat.map((v, i) => v + b.sat[i]),
    sun: a.sun.map((v, i) => v + b.sun[i]),
    thu: a.thu.map((v, i) => v + b.thu[i]),
    tue: a.tue.map((v, i) => v + b.tue[i]),
    wed: a.wed.map((v, i) => v + b.wed[i])
  };
}

/**
 *
 * @param selected
 */
export function useTable(selected: string | null): Table {
  const insights = useContext<Insights>(InsightsContext);

  return useMemo<Table>(() => {
    const tables = insights.tables;
    const streams = insights.streams;

    // All
    if (!selected) {
      return Object.values(tables).reduce(sum, initial);
    }

    // Stream
    if (tables[selected]) {
      return tables[selected];
    }

    // Site
    const streamIds = Object.values(streams)
      .filter((stream) => stream.siteId === selected)
      .map((stream) => stream.id);

    return Object.entries(tables)
      .filter(([id]) => streamIds.includes(id))
      .map(([_id, data]) => data)
      .reduce(sum, initial);
  }, [insights, selected]);
}
