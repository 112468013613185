import Linkify from 'linkify-react';
import type { Opts } from 'linkifyjs';
import { memo } from 'react';

interface LinkifiedTextProps {
  options?: Partial<Opts>;
  tagName?: string;
  text: string;
}

/**
 *
 * @param props
 */
export const LinkifiedText = memo(
  (props: LinkifiedTextProps) => (
    <Linkify options={props.options} tagName={props.tagName || 'p'}>
      {props.text}
    </Linkify>
  ),
  (prevProps, nextProps) => prevProps.text === nextProps.text
);
