import dayjs from 'dayjs';

import { isEmail, maxLength } from '../../../../../lib/utils/validation';
import { formatDate } from '../../../../molecules/Form/formatters';
import type { FormSchema } from '../../../../molecules/Form/validate';

export const schema: FormSchema = {
  caseEventId: {
    validators: [
      {
        message: 'Case/Event is a required field',
        test: true
      },
      {
        message: 'Please input less than 50 characters',
        test: maxLength(50)
      }
    ]
  },

  email: {
    validators: [
      {
        message: 'Email is a required field',
        test: true
      },
      {
        message: 'Not a valid email address',
        test: isEmail
      }
    ]
  },

  end: {
    dependencies: ['start'],
    formatter: formatDate,
    validators: [
      {
        message: 'End must be after start',
        test: (value: Date, values: Record<string, any>): boolean => {
          if (!value || !values.start) return true;
          return dayjs(value).isAfter(dayjs(values.start));
        }
      }
    ]
  },

  message: {
    validators: [
      {
        message: 'Message is a required field',
        test: true
      }
    ]
  },

  start: {
    dependencies: ['end'],
    formatter: formatDate,
    validators: [
      {
        message: 'Start must be before end',
        test: (value: Date, values: Record<string, any>) => {
          if (!value || !values.end) return true;
          return dayjs(value).isBefore(dayjs(values.end));
        }
      }
    ]
  }
};
