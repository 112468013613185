import { gql } from '@apollo/client';

// WS Subscriptions
export const REMOTE_COMMAND = gql`
  subscription remoteCommand {
    remoteCommand {
      command
      data
    }
  }
`;
