import { FC, Fragment, ReactNode } from 'react';

import { AuthenticatedIndex } from './AuthenticatedIndex';

interface ProtectedRouteProps {
  readonly allowed: boolean;
  readonly children: ReactNode;
}

/**
 *
 * @param props
 */
export const ProtectedRoute: FC<ProtectedRouteProps> = (props) => {
  const { allowed } = props;

  if (!allowed) {
    return <AuthenticatedIndex />;
  }

  return <Fragment>{props.children}</Fragment>;
};
