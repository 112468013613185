import { Card, Elevation } from '@blueprintjs/core';
import type { FC } from 'react';

import { useDebug } from '../../../../../lib/hooks/debug';

import styles from './Counts.module.scss';

/**
 *
 */
export const Legend: FC = () => {
  const debug = useDebug();

  return (
    <Card className={styles.card} elevation={Elevation.ONE}>
      <header className={styles.heading}>Duration</header>
      <div className={styles.correct}>{debug ? 'Confirmed' : 'Alerts'}</div>
      {debug && (
        <div className={styles.debug}>
          <div className={styles.total}>Total</div>
          <div className={styles.incorrect}>Incorrect</div>
          <div className={styles.unsure}>Unsure</div>
        </div>
      )}
      <div className={styles.reads}>Reads</div>
    </Card>
  );
};
