import { useEffect, useRef } from 'react';

import { captureError } from '../utils/sentry';

export const useWakeLock = () => {
  const wakeLock = useRef<WakeLockSentinel | null>(null);
  const supported = 'wakeLock' in navigator;

  useEffect(() => {
    if (!supported) return;

    if ('wakeLock' in navigator) {
      const getLock = () => {
        navigator.wakeLock
          .request('screen')
          .then((lock) => {
            wakeLock.current = lock;
            console.log('***Wake Lock is active***');

            wakeLock.current.addEventListener('release', () => {
              console.log('***Wake Lock released***');
            });
          })
          .catch(() => {
            console.log('***Wake Lock unable to be obtained***');
          });
      };

      document.addEventListener('visibilitychange', () => {
        if (wakeLock !== null && document.visibilityState === 'visible') {
          getLock();
        }
      });

      getLock();
    }

    return () => {
      if (wakeLock.current !== null) {
        wakeLock.current.release().catch(captureError);
      }
    };
  }, []);
};
