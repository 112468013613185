import {
  apiVersion,
  clientInstance,
  clientVersion
} from '../../../lib/graphql/link/meta';

export interface CurrentUser {
  readonly code: string;
  readonly email: string | null;
  readonly isSso: boolean | null;
  readonly name: string | null;
  readonly permissions: ReadonlyArray<string>;
  readonly startScreen: string;
}

/**
 *
 */
export async function getCurrentUser(): Promise<CurrentUser | null> {
  const response = await fetch('/api', {
    body: '{"operationName":"whoami","variables":{},"query":"query whoami {whoami{code,email,isSso,name,permissions,startScreen}}"}',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Version': apiVersion,
      'X-Client-Instance': clientInstance,
      'X-Client-Version': clientVersion
    },
    method: 'POST',
    mode: 'same-origin',
    redirect: 'error'
  });

  const result = await response.json();

  if (result.errors) {
    throw new Error('Something went wrong');
  }

  return result.data?.whoami || null;
}
