import { createElement, FC, ReactNode, useState } from 'react';

import { SearchResult } from '../../organisms/Header/Search/types';

import { SearchContext } from './SearchContext';

/**
 *
 * @param props
 */
export const SearchProvider: FC<{ children: ReactNode }> = (props) => {
  const [item, setItem] = useState<SearchResult | null>(null);

  return createElement(
    SearchContext.Provider,
    { value: [item, setItem] },
    props.children
  );
};
