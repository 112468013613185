import { Card, Elevation, Spinner } from '@blueprintjs/core';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { captureError } from '../../../lib/utils/sentry';

import { CurrentUserContext, getAnonymousUser } from './current-user-context';
import { CurrentUser, getCurrentUser } from './get-current-user';
import { logoutCurrentUser } from './logout-current-user';

interface CurrentUserProviderProps {
  readonly children: ReactNode;
  readonly fallback: ReactNode;
}

/**
 *
 */
export const CurrentUserProvider: FC<CurrentUserProviderProps> = (props) => {
  const [currentUser, setUser] = useState<CurrentUser>();

  const logout = useCallback<() => Promise<void>>(async () => {
    await logoutCurrentUser();
    localStorage.clear();
    sessionStorage.clear();
    setUser(getAnonymousUser());
  }, [setUser]);

  const setCurrentUser = useCallback<(user: CurrentUser | null) => void>(
    (user) => setUser(user || getAnonymousUser()),
    [setUser]
  );

  const reloadCurrentUser = useCallback<() => Promise<void>>(async () => {
    const user = await getCurrentUser().catch(() => getAnonymousUser());

    setCurrentUser(user);
  }, [setCurrentUser]);

  useEffect(() => {
    reloadCurrentUser().catch(captureError);
  }, []);

  if (!currentUser) {
    return (
      <div className="vg-anonymous">
        <div className="vg-container vg-page vg-page--loading">
          <Card elevation={Elevation.ONE}>
            <div className="vg-spinner">
              <Spinner size={150} />
            </div>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <CurrentUserContext.Provider
      value={{ currentUser, logout, reloadCurrentUser, setCurrentUser }}
    >
      {currentUser.email ? props.children : props.fallback}
    </CurrentUserContext.Provider>
  );
};
