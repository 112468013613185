import { useContext } from 'react';

import {
  CurrentUserContext,
  CurrentUserValue
} from '../../components/context/current-user/current-user-context';
import type { CurrentUser } from '../../components/context/current-user/get-current-user';

/**
 *
 */
export function useCurrentUser(): CurrentUser {
  const { currentUser } = useContext<CurrentUserValue>(CurrentUserContext);

  return currentUser;
}
