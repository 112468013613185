import { Classes, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { FC } from 'react';

import { Stream } from '../InsightsProvider/types';
import { useSelected } from '../SelectedProvider/useSelected';

interface StreamNodeProps {
  stream: Stream;
}

/**
 *
 * @param props
 */
export const StreamNode: FC<StreamNodeProps> = (props) => {
  const [selected, setSelected] = useSelected();

  return (
    <li
      className={[
        Classes.TREE_NODE,
        props.stream.id === selected ? Classes.TREE_NODE_SELECTED : ''
      ].join(' ')}
    >
      <div
        className={[
          Classes.TREE_NODE_CONTENT,
          Classes.TREE_NODE_CONTENT + '-1'
        ].join(' ')}
        onClick={() => setSelected(props.stream.id)}
      >
        <span className={Classes.TREE_NODE_CARET_NONE}>&nbsp;</span>
        <Icon
          className={[Classes.TREE_NODE_ICON, Classes.INTENT_PRIMARY].join(' ')}
          icon={IconNames.MOBILE_VIDEO}
        />
        <span className={Classes.TREE_NODE_LABEL}>{props.stream.name}</span>
      </div>
    </li>
  );
};
