import { useContext } from 'react';

import {
  CurrentUserContext,
  CurrentUserValue
} from '../../components/context/current-user/current-user-context';
import { getPermissions, Permissions } from '../permissions';

/**
 *
 */
export function usePermissions(): Permissions {
  const { currentUser } = useContext<CurrentUserValue>(CurrentUserContext);

  return getPermissions(currentUser.code);
}
