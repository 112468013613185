import { gql } from '@apollo/client';

export const ACTIVATE_ACCOUNT = gql`
  mutation activateAccount(
    $email: String!
    $password: String!
    $token: String!
  ) {
    activateAccount(email: $email, password: $password, token: $token)
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!, $password: String!, $token: String!) {
    resetPassword(email: $email, password: $password, token: $token)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $id: String
    $password: String!
    $currentPassword: String
  ) {
    changePassword(
      id: $id
      currentPassword: $currentPassword
      password: $password
    )
  }
`;

export const WHOAMI = gql`
  query whoami {
    whoami {
      code
      name
      email
      permissions
      startScreen
    }
  }
`;
