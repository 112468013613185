import { ApolloClient } from '@apollo/client';

import { getAnonymousUser } from '../../components/context/current-user/current-user-context';

import { cache } from './cache';
import { getNetworkLink } from './link';
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SETTINGS
} from './localQueries/notifications';
import {
  addNotification,
  clearNotifications,
  removeNotification
} from './localResolvers/notifications';
import { WHOAMI } from './queries/authentication';

/**
 *
 */
export function getAnonymousClient(): ApolloClient<any> {
  const client = new ApolloClient({
    cache,
    connectToDevTools: __DEV__,
    link: getNetworkLink(false),
    resolvers: {
      Mutation: {
        addNotification,
        clearNotifications,
        removeNotification
      }
    }
  });

  client.writeQuery({
    data: { whoami: getAnonymousUser() },
    query: WHOAMI
  });

  /**
   * Notifications
   * =============================================================================
   */

  client.writeQuery({
    data: {
      notifications: []
    },
    query: GET_NOTIFICATIONS
  });

  client.writeQuery({
    data: {
      notificationsSettings: {
        disableComputer: false,
        disableTab: false,
        muteComputer: false
      }
    },
    query: GET_NOTIFICATIONS_SETTINGS
  });

  return client;
}
