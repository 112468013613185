import { InMemoryCache } from '@apollo/client/cache';

export const cache = new InMemoryCache({
  resultCaching: true,
  typePolicies: {
    AnprInsightsCounts: {
      keyFields: (value) => [value.id, value.duration].join(':')
    },
    AnprNotificationSettingsResource: {
      keyFields: (value) => [value.id, value.parentId || 'root'].join(':')
    },
    AnprPoiMeta: {
      merge: true
    },
    AnprStream: {
      fields: {
        _meta: {
          merge: true
        }
      }
    },
    Edge: {
      fields: {
        sites: {
          merge: (_existing, incoming) => incoming
        }
      }
    },
    PlayingGlimpse: {
      keyFields: (value) => [value.id, value.started].join('@')
    },
    PlayingStream: {
      keyFields: (value) => [value.id, value.started].join('@')
    },
    Query: {
      fields: {
        activeUsers: {
          merge: (_existing, incoming) => incoming
        },
        notifications: {
          merge: (_existing, incoming) => incoming
        }
      }
    },
    ResourcePermissionList: {
      keyFields: false // disable normalisation, embed within parent
    }
  }
});
