import { ApolloClient, gql, Observable } from '@apollo/client';
import { IconNames } from '@blueprintjs/icons';

import type { CurrentUser } from '../../../components/context/current-user/get-current-user';
import { WHOAMI } from '../../graphql/queries/authentication';
import { getPermissions } from '../../permissions';
import { addNotification } from '../add';

interface AnprNotification {
  icon: 'unknown-vehicle';
  id: string;
  intent: 'danger';
  message: string;
  sound: 'woopwoop';
  title: string;
}

interface AnprNotificationEvent {
  data?: {
    anprNotification: AnprNotification;
  } | null;
}

const SUBSCRIBE_ANPR_NOTIFICATION = gql`
  subscription anprNotification {
    anprNotification {
      icon
      id
      intent
      message
      sound
      title
    }
  }
`;

/**
 *
 * @param client
 */
export async function anpr(client: ApolloClient<any>): Promise<() => void> {
  const result = await client.query<{ whoami: CurrentUser }>({
    fetchPolicy: 'cache-only',
    query: WHOAMI
  });

  const permissions = getPermissions(result.data.whoami.code);

  if (!permissions?.anpr.alerts) {
    return () => null;
  }

  const observer: Observable<AnprNotificationEvent> = client.subscribe({
    query: SUBSCRIBE_ANPR_NOTIFICATION
  });

  const subscription = observer.subscribe((value) => {
    const event = value.data?.anprNotification;

    if (!event) {
      return;
    }

    addNotification(client, {
      handler: ['link', '/anpr/alerts/' + event.id],
      icon: event.icon || IconNames.UNKNOWN_VEHICLE,
      id: event.id,
      intent: event.intent || 'danger',
      message: event.message,
      sound: event.sound || 'woopwoop',
      title: event.title || 'Stolen vehicle'
    });
  });

  return () => subscription.unsubscribe();
}
