import { useContext } from 'react';

import { SelectedContext, SelectedValue } from './SelectedContext';

/**
 *
 */
export function useSelected(): SelectedValue {
  return useContext<SelectedValue>(SelectedContext);
}
