import { FormSchema } from '../../../lib/hooks/form/validate';
import { maxLength } from '../../../lib/utils/validation';
import { VaultUploadLocalisation } from '../../localisation';

const schema: FormSchema = {
  caseEventId: {
    validators: [
      {
        message: 'Case/Event is a required field',
        test: true
      },
      {
        message: 'Please input less than 50 characters',
        test: maxLength(50)
      }
    ]
  }
};

export const vault: VaultUploadLocalisation = {
  upload: {
    caseEventId: {
      labelText: 'Case / Event'
    },
    ownerId: {
      labelText: 'Case owner'
    },
    schema
  },
  uploadList: {
    searchPlaceholder: 'Search by Case / Event (minimum 3 characters)'
  }
};
