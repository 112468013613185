import { useMutation, useQuery } from '@apollo/client';
import { HotkeyConfig, PopoverPosition, useHotkeys } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Suggest } from '@blueprintjs/select';
import { useCallback, useMemo, useRef, useState, FC } from 'react';
import type { Ref } from 'react';

import {
  GET_PLAYING_STREAMS,
  SET_LAYOUT,
  SET_PLAYING_STREAM
} from '../../../../lib/graphql/localQueries';
import { useLocalisation } from '../../../../lib/hooks/localisation';
import { useSearch } from '../../../../lib/hooks/search';
import { placeIdToLatLon } from '../../../../lib/utils/google';
import { isMac } from '../../../../lib/utils/is-mac';
import { captureError } from '../../../../lib/utils/sentry';

import { NoResults } from './NoResults';
import styles from './Search.module.scss';
import { SearchRow } from './SearchRow';
import { SearchResult } from './types';
import { useSearchResults } from './useSearchResults';

interface GetPlayingStreamsResponse {
  layout: string;
}

interface SearchSuggestProps {
  placeholder: string;
}

/**
 *
 * @param props
 */
export const SearchSuggest: FC<SearchSuggestProps> = (props) => {
  const [query, setQuery] = useState<string>('');
  const [selected, setSelected] = useSearch();
  const localisation = useLocalisation();

  const items = useSearchResults(query);

  const { data } = useQuery<GetPlayingStreamsResponse>(GET_PLAYING_STREAMS, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-only'
  });
  const [setLayout] = useMutation(SET_LAYOUT);
  const [playStream] = useMutation(SET_PLAYING_STREAM);

  const searchCombo = isMac() ? 'cmd+f' : 'ctrl+f';
  const search: HotkeyConfig = {
    combo: searchCombo,
    global: true,
    label: 'Search',
    onKeyDown: () => ref.current?.focus(),
    preventDefault: true
  };

  const ref = useRef<HTMLInputElement>(null);
  const hotkeys = useMemo<HotkeyConfig[]>(() => [search], []);

  useHotkeys(hotkeys);

  const onItemSelect = useCallback(
    (item: SearchResult) => {
      if (item.type === 'place') {
        if (data?.layout && !['map', 'mapMontage'].includes(data.layout)) {
          setLayout({ variables: { layout: 'mapMontage' } }).catch(
            captureError
          );
        }

        if (item.placeType === 'google') {
          return placeIdToLatLon(
            item.id,
            localisation.get('ui.global.map.googleApiKey')
          ).then((latLon) => {
            if (latLon) {
              setSelected({
                ...item,
                lat: latLon.lat,
                lon: latLon.lng,
                placeType: 'generic'
              });
            }
          });
        }
        return setSelected(item);
      }

      playStream({
        variables: { id: item.id, oldPosition: null, type: 'PlayingStream' }
      }).catch(captureError);
    },
    [data?.layout]
  );

  return (
    <Suggest<SearchResult>
      className={styles.search}
      inputProps={{
        inputRef: ref as Ref<HTMLInputElement>,
        leftIcon: IconNames.SEARCH,
        placeholder: `${props.placeholder} (${searchCombo
          .split('+')
          .join(' + ')})`,
        type: 'search'
      }}
      inputValueRenderer={(item) => item.text}
      itemRenderer={(item, itemProps) => (
        <SearchRow key={item.id} item={item} itemProps={itemProps} />
      )}
      items={items}
      itemsEqual="id"
      noResults={<NoResults length={query.length} />}
      onItemSelect={onItemSelect}
      onQueryChange={setQuery}
      popoverProps={{
        position: PopoverPosition.BOTTOM,
        usePortal: false
      }}
      query={query}
      selectedItem={selected}
    />
  );
};
