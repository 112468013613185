import {
  ApolloError,
  ApolloLink,
  Operation,
  FetchResult,
  Observable
} from '@apollo/client/core';
import { print } from 'graphql';
import { createClient, ClientOptions, Client } from 'graphql-ws';

interface LikeCloseEvent {
  /** Returns the WebSocket connection close code provided by the server. */
  readonly code: number;
  /** Returns the WebSocket connection close reason provided by the server. */
  readonly reason: string;
}

function isNonNullObject(obj: any): obj is Record<string | number, any> {
  return obj !== null && typeof obj === 'object';
}

function isLikeCloseEvent(val: unknown): val is LikeCloseEvent {
  return isNonNullObject(val) && 'code' in val && 'reason' in val;
}

/**
 *
 */
export class WebSocketLink extends ApolloLink {
  /**
   *
   */
  private _client: Client;

  /**
   *
   */
  private _pingTimeout?: NodeJS.Timeout;

  /**
   *
   */
  private _websocket?: WebSocket;

  /**
   *
   * @param options
   */
  constructor(options: ClientOptions) {
    super();

    this._client = createClient({
      ...options,
      isFatalConnectionProblem: () => {
        // Never consider it fatal
        return false;
      },
      keepAlive: 30_000,
      retryAttempts: 30
    });
    this._client.on('connected', this._onConnected.bind(this));
    this._client.on('ping', this._onPing.bind(this));
    this._client.on('pong', this._onPong.bind(this));
  }

  /**
   *
   * @param operation
   */
  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this._client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          complete: sink.complete.bind(sink),
          error: (err: any) => {
            if (err instanceof Error) {
              return sink.error(err);
            }

            if (isLikeCloseEvent(err)) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`
                )
              );
            }

            return sink.error(
              new ApolloError({
                graphQLErrors: Array.isArray(err) ? err : [err]
              })
            );
          },
          next: sink.next.bind(sink)
        }
      );
    });
  }

  /**
   *
   * @param socket
   */
  private _onConnected(socket: unknown): void {
    this._websocket = socket as WebSocket;
  }

  /**
   *
   * @param received
   */
  private _onPing(received: boolean): void {
    if (!received) {
      this._pingTimeout = setTimeout(() => {
        if (this._websocket?.readyState === WebSocket.OPEN) {
          this._websocket.close(4408, 'Request Timeout');
        }
      }, 5_000); // wait 5 seconds for the pong and then close the connection
    }
  }

  /**
   *
   */
  private _onPong(): void {
    if (this._pingTimeout) {
      clearTimeout(this._pingTimeout);
    }
  }
}
