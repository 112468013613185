import { MenuItem } from '@blueprintjs/core';
import {
  ItemListPredicate,
  ItemRenderer,
  Suggest,
  SuggestProps
} from '@blueprintjs/select';
import Fuse from 'fuse.js';
import { KeyboardEvent, useCallback, useMemo, FC } from 'react';

import { OffenceItem, offenceList } from './offenceList';

interface AnprOffenceSelectProps extends Partial<SuggestProps<OffenceItem>> {
  onChange: (value: string) => void;
  value: string;
}

/**
 *
 * @param props
 */
export const AnprOffenceSelect: FC<AnprOffenceSelectProps> = (props) => {
  const { onChange, value, ...extraProps } = props;

  const selectedItem = useMemo(
    () => offenceList.filter((item) => item.text === props.value),
    [props.value]
  );

  const onInputKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter') {
        return;
      }

      event.preventDefault();
      event.currentTarget.blur();
    },
    []
  );

  return (
    <Suggest<OffenceItem>
      {...extraProps}
      inputProps={{
        ...extraProps.inputProps,
        onKeyUp: onInputKeyPress,
        placeholder: 'Search for Offence Code...'
      }}
      fill={true}
      inputValueRenderer={(item) => item.text}
      items={offenceList}
      itemListPredicate={filterOffence}
      itemRenderer={renderOffence}
      noResults={<MenuItem disabled={true} text="No results" />}
      onItemSelect={(item) => props.onChange(item.text)}
      selectedItem={selectedItem.length > 0 ? selectedItem[0] : null}
    />
  );
};

const filterOffence: ItemListPredicate<OffenceItem> = (query, items) => {
  if (!query) return items;

  const fuse = new Fuse(items, {
    keys: ['text'],
    threshold: 0.2
  });

  return fuse.search(query).map((result) => result.item);
};

const renderOffence: ItemRenderer<OffenceItem> = (
  offence,
  { handleClick, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={offence.index}
      onClick={handleClick}
      text={offence.text}
    />
  );
};
