import { gql } from '@apollo/client';

// Queries

export const GET_ORGANISATION_CONFIG = gql`
  query organisationConfig {
    organisationConfig {
      items {
        name
        value
      }
    }
  }
`;

export const GET_ORGANISATION_CONFIG_ADMIN = gql`
  query organisationConfigAdmin {
    organisationConfigAdmin {
      items {
        active
        name
        value
      }
    }
  }
`;

// Mutations

export const ADD_ORGANISATION_CONFIG = gql`
  mutation addOrganisationConfig($payload: OrganisationConfigInput!) {
    addOrganisationConfig(payload: $payload)
  }
`;

export const REMOVE_ORGANISATION_CONFIG = gql`
  mutation removeOrganisationConfig($name: String!) {
    removeOrganisationConfig(name: $name)
  }
`;

export const UPDATE_ORGANISATION_CONFIG = gql`
  mutation updateOrganisationConfig($payload: OrganisationConfigInput!) {
    updateOrganisationConfig(payload: $payload)
  }
`;
