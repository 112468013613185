import {
  Card,
  Elevation,
  Popover,
  PopoverInteractionKind
} from '@blueprintjs/core';
import { useCallback, useMemo, FC } from 'react';

import { useTable } from '../InsightsProvider/useTable';
import { useSelected } from '../SelectedProvider/useSelected';

import styles from './Table.module.scss';

const classNames = [
  styles.levelOne,
  styles.levelTwo,
  styles.levelThree,
  styles.levelFour,
  styles.levelFive
];

const hours = new Array(24).fill(null).map((_v, i) => (
  <th key={i} scope="col">
    {i}
  </th>
));

/**
 *
 */
export const Table: FC = () => {
  const [selected] = useSelected();
  const stream = useTable(selected);

  const total = useMemo(() => {
    return Object.values(stream).reduce((a, b) => {
      return a + b.reduce((x: number, y: number) => x + y, 0);
    }, 0);
  }, [stream]);

  const scale = useMemo(() => {
    const max = Math.max(
      ...Object.values(stream).map((values) => Math.max(...values))
    );

    return max / 4 || 1;
  }, [stream]);

  const getNode = useCallback(
    (value: number, i: number) => (
      <td key={i} className={classNames[Math.ceil(value / scale)]}>
        <Popover
          content={
            <div className={styles.count}>
              {Math.round((value / total) * 10_000) / 100}%
            </div>
          }
          disabled={!value}
          fill={true}
          interactionKind={PopoverInteractionKind.HOVER}
          placement="top"
        >
          <span>&nbsp;</span>
        </Popover>
      </td>
    ),
    [scale]
  );

  return (
    <Card className={styles.card} elevation={Elevation.ONE}>
      <table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            {hours}
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">M</th>
            {stream.mon.map(getNode)}
            <th scope="row">M</th>
          </tr>
          <tr>
            <th scope="row">T</th>
            {stream.tue.map(getNode)}
            <th scope="row">T</th>
          </tr>
          <tr>
            <th scope="row">W</th>
            {stream.wed.map(getNode)}
            <th scope="row">W</th>
          </tr>
          <tr>
            <th scope="row">T</th>
            {stream.thu.map(getNode)}
            <th scope="row">T</th>
          </tr>
          <tr>
            <th scope="row">F</th>
            {stream.fri.map(getNode)}
            <th scope="row">F</th>
          </tr>
          <tr>
            <th scope="row">S</th>
            {stream.sat.map(getNode)}
            <th scope="row">S</th>
          </tr>
          <tr>
            <th scope="row">S</th>
            {stream.sun.map(getNode)}
            <th scope="row">S</th>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>&nbsp;</th>
            {hours}
            <th>&nbsp;</th>
          </tr>
        </tfoot>
      </table>
    </Card>
  );
};
