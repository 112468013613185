import { ApolloProvider } from '@apollo/client';
import { useMemo, FC } from 'react';

import { getAnonymousClient } from '../../../lib/graphql/anonymous-client';
import { StatusPageLink } from '../../atoms/StatusPageLink';
import { LocalisationContextProvider } from '../../context/LocalisationContext';

import { AnonymousRoutes } from './AnonymousRoutes';

/**
 *
 * @param props
 */
export const Anonymous: FC = () => {
  const client = useMemo(() => getAnonymousClient(), []);

  return (
    <ApolloProvider client={client}>
      <LocalisationContextProvider hostname={location.hostname}>
        <div className="vg-anonymous">
          <AnonymousRoutes />
          <StatusPageLink />
        </div>
      </LocalisationContextProvider>
    </ApolloProvider>
  );
};
