import { MenuItemProps, MenuItem } from '@blueprintjs/core';
import { FC } from 'react';
import { useLinkClickHandler, useMatch } from 'react-router-dom';

interface LinkMenuItemProps extends MenuItemProps {
  href: string;
  path?: string;
}

/**
 *
 * @param props
 */
export const LinkMenuItem: FC<LinkMenuItemProps> = (props) => {
  const { href, path, ...menuProps } = props;
  const match = useMatch(path || href + '/*');
  const onClick = useLinkClickHandler(props.href);

  return (
    <MenuItem
      {...menuProps}
      active={!!match}
      onClick={onClick as any}
      tabIndex={-1}
    />
  );
};
