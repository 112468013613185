import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useRef, FC } from 'react';

import { useFullscreen } from '../../../../lib/hooks/fullscreen';

import { Counts } from './Counts/Counts';
import { Legend } from './Counts/Legend';
import styles from './Insights.module.scss';
import { InsightsMap } from './InsightsMap/InsightsMap';
import { InsightsProvider } from './InsightsProvider/InsightsProvider';
import { Duration } from './InsightsProvider/types';
import { SelectedProvider } from './SelectedProvider/SelectedProvider';
import { StreamList } from './StreamList/StreamList';
import { Table } from './Table/Table';

/**
 *
 */
export const Insights: FC = () => {
  const ref = useRef(null);
  const { isFullscreen, request } = useFullscreen(ref);

  return (
    <InsightsProvider>
      <div className={styles.insights}>
        <SelectedProvider>
          <StreamList className={styles.streamsList} />
          <div className={styles.wrapper} ref={ref}>
            <div className={styles.results}>
              {isFullscreen || (
                <Icon
                  className={styles.hitbox}
                  icon={IconNames.FULLSCREEN}
                  onClick={() => request()}
                  title="Fullscreen"
                />
              )}
              <div className={styles.counts}>
                <Legend />
                <Counts duration={Duration.HOUR} />
                <Counts duration={Duration.DAY} />
                <Counts duration={Duration.WEEK} />
                <Counts duration={Duration.MONTH} />
                <Counts duration={Duration.YEAR} />
              </div>
              <div className={styles.visual}>
                <InsightsMap />
                <Table />
              </div>
            </div>
          </div>
        </SelectedProvider>
      </div>
    </InsightsProvider>
  );
};
