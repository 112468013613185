import {
  Button,
  DialogBody,
  DialogFooter,
  FormGroup,
  InputGroup,
  Intent,
  TextArea
} from '@blueprintjs/core';
import { FC, Fragment, useEffect } from 'react';

import { useCurrentUser } from '../../../lib/hooks/current-user';
import type { FormResult } from '../../molecules/Form/useForm';

interface HelpFormProps extends FormResult {
  close: () => void;
  isOpen: boolean;
}

/**
 *
 * @param props
 */
export const HelpForm: FC<HelpFormProps> = (props) => {
  const { email, name } = useCurrentUser();

  useEffect(props.reset, [props.isOpen]);

  return (
    <Fragment>
      <DialogBody>
        <FormGroup
          helperText={name ? null : props.errors.name || 'Required'}
          intent={props.intents.name}
          labelFor="name"
        >
          <InputGroup
            autoFocus={!name}
            disabled={!!name}
            id="name"
            intent={props.intents.name}
            name="name"
            onChange={props.onChange}
            placeholder="Name"
            required={!name}
            value={name || props.values.name}
          />
        </FormGroup>
        <FormGroup
          helperText={email ? null : props.errors.email || 'Required'}
          intent={props.intents.email}
          labelFor="email"
        >
          <InputGroup
            disabled={!!email}
            id="email"
            intent={props.intents.email}
            name="email"
            onChange={props.onChange}
            placeholder="Email"
            required={!email}
            type="email"
            value={email || props.values.email}
          />
        </FormGroup>
        <FormGroup
          helperText={props.errors.message || 'Required'}
          intent={props.intents.message}
          labelFor="message"
        >
          <TextArea
            autoFocus={!!email}
            fill={true}
            id="message"
            intent={props.intents.message}
            name="message"
            onChange={props.onChange}
            placeholder="Message"
            required={true}
            rows={8}
            value={props.values.message}
          />
        </FormGroup>
      </DialogBody>
      <DialogFooter
        actions={
          <Fragment>
            <Button
              disabled={props.disabled}
              intent={Intent.PRIMARY}
              loading={props.loading}
              onClick={props.submit}
              text="Send"
              type="submit"
            />
            <Button
              intent={Intent.NONE}
              minimal={true}
              onClick={props.close}
              text="Cancel"
              type="reset"
            />
          </Fragment>
        }
      />
    </Fragment>
  );
};
