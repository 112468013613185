import { gql } from '@apollo/client';

export const SEARCH_PLACES = gql`
  query searchPlaces($query: String) {
    searchPlaces(query: $query) {
      id
      lat
      lon
      primary
      secondary
      text
      type
    }
  }
`;

export const SEARCH_STREAMS = gql`
  query searchStreams($query: String) {
    searchStreams(query: $query) {
      active
      id
      name
      site {
        id
        name
      }
    }
  }
`;
