import { ApolloProvider } from '@apollo/client';
import { useMemo, FC } from 'react';

import { getAuthenticatedClient } from '../../../lib/graphql/authenticated-client';
import { useCurrentUser } from '../../../lib/hooks/current-user';
import { ErrorBoundary } from '../../atoms/ErrorBoundary';
import { LocalisationContextProvider } from '../../context/LocalisationContext';
import { SearchProvider } from '../../context/search';
import { SessionContext } from '../../context/session-context';
import { Header } from '../../organisms/Header';
import { Notifications } from '../../organisms/Notifications';

import { AuthenticatedRoutes } from './AuthenticatedRoutes';

/**
 *
 *
 */
export const Authenticated: FC = () => {
  const user = useCurrentUser();
  const client = useMemo(() => getAuthenticatedClient(user), [user]);

  return (
    <ApolloProvider client={client}>
      <LocalisationContextProvider hostname={location.hostname}>
        <SessionContext>
          <SearchProvider>
            <Header />
            <Notifications />
            <ErrorBoundary>
              <AuthenticatedRoutes />
            </ErrorBoundary>
          </SearchProvider>
        </SessionContext>
      </LocalisationContextProvider>
    </ApolloProvider>
  );
};
