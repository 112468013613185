/**
 *
 * @param length
 */
export function randomString(length: number): string {
  const values: Uint8Array = new Uint8Array(Math.ceil(length / 2));
  window.crypto.getRandomValues(values);

  return Array.from(values)
    .map<string>((num: number) => num.toString(16))
    .join('')
    .slice(0, length);
}
