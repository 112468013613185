import { Colors, Icon, Popover, PopoverProps } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import { DivIcon, DomUtil, type LatLngExpression } from 'leaflet';
import { FC, Fragment, useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Marker as RLMarker, useMap } from 'react-leaflet';

import styles from './Marker.module.scss';

interface MarkerDivProps {
  colour?: string;
  icon?: IconName;
  popover?: PopoverProps;
  text?: string;
}

export interface MarkerProps extends MarkerDivProps {
  onClick?: () => void;
  opacity?: number;
  position: LatLngExpression;
}

/**
 *
 * @param props
 */
const MarkerDiv: FC<MarkerDivProps> = (props) => {
  const content = (
    <Fragment>
      <svg className={styles.pin} viewBox="0 0 16 16">
        <path
          d="M8 2.1c1.1 0 2.2.5 3 1.3.8.9 1.3 1.9 1.3 3.1S11.8 9 11 9.8l-3 3.1-3-3.1c-.8-.8-1.3-2-1.3-3.3 0-1.2.4-2.2 1.3-3.1.8-.8 1.9-1.3 3-1.3z"
          fill={props.colour}
        />
        {Boolean(props.icon || props.text) || (
          <circle cx={8} cy={6.5} r={1.5} />
        )}
      </svg>
      {props.icon && (
        <div className={styles.details}>
          <Icon icon={props.icon} />
        </div>
      )}
      {props.text && (
        <div className={styles.details}>
          <span>{props.text}</span>
        </div>
      )}
    </Fragment>
  );

  if (props.popover && props.popover.content) {
    let popContent = props.popover.content;
    if (typeof popContent === 'string') {
      popContent = (
        <div className={styles.popoverTextContent}>{props.popover.content}</div>
      );
    }

    return (
      <Popover
        fill={true}
        interactionKind="hover"
        hoverCloseDelay={50}
        {...props.popover}
        content={popContent}
      >
        {content}
      </Popover>
    );
  }

  return content;
};

MarkerDiv.defaultProps = {
  colour: Colors.BLUE3
};

/**
 *
 * @param props
 */
export const useGetMarkerDivIcon = (
  props: Omit<MarkerProps, 'onClick' | 'position'>
) => {
  const elementRef = useRef<HTMLDivElement>(
    DomUtil.create('div', styles.marker)
  );
  const element = elementRef.current;

  if (props.opacity !== undefined) {
    element.setAttribute('style', `opacity: ${props.opacity}`);
  }
  const icon = new DivIcon({ className: '', html: element });

  // Remove the element when the component unmounts
  useEffect(() => {
    return () => {
      DomUtil.remove(element);
    };
  }, []);

  const portal = createPortal(<MarkerDiv {...props} />, element);
  return { icon, portal };
};

/**
 * @param props
 */
export const Marker: FC<MarkerProps> = (props) => {
  const { position } = props;
  const map = useMap();

  const { icon, portal } = useGetMarkerDivIcon(props);

  const click = useCallback(() => {
    if (props.onClick) {
      props.onClick();
    }
  }, [map, position]);

  return (
    <Fragment>
      {portal}
      <RLMarker eventHandlers={{ click }} position={position} icon={icon} />
    </Fragment>
  );
};

Marker.defaultProps = {
  opacity: 1
};
