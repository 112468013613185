import { latLngBounds } from 'leaflet';

import type { Point } from './types';

interface MapBounds {
  east: number;
  north: number;
  south: number;
  west: number;
}

/**
 *
 * @param points
 */
export function pointsToBounds(points: Point[]): MapBounds {
  const bounds = latLngBounds(points);

  return {
    east: bounds.getEast(),
    north: bounds.getNorth(),
    south: bounds.getSouth(),
    west: bounds.getWest()
  };
}
