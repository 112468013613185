import { Classes, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useMemo, FC } from 'react';

import { Site } from '../InsightsProvider/types';
import { useStreams } from '../InsightsProvider/useStreams';
import { useSelected } from '../SelectedProvider/useSelected';

import { StreamNode } from './StreamNode';

interface SiteNodeProps {
  site: Site;
}

/**
 *
 * @param props
 */
export const SiteNode: FC<SiteNodeProps> = (props) => {
  const [selected, setSelected] = useSelected();
  const streams = useStreams();

  const children = useMemo(() => {
    return Object.values(streams).filter(
      (stream) => stream.siteId === props.site.id
    );
  }, [streams]);

  return (
    <li
      className={[
        Classes.TREE_NODE,
        props.site.id === selected ? Classes.TREE_NODE_SELECTED : ''
      ].join(' ')}
    >
      <div
        className={Classes.TREE_NODE_CONTENT}
        onClick={() => setSelected(props.site.id)}
      >
        <span className={Classes.TREE_NODE_CARET_NONE}>&nbsp;</span>
        <Icon
          className={[Classes.TREE_NODE_ICON, Classes.INTENT_PRIMARY].join(' ')}
          icon={IconNames.MAP_MARKER}
        />
        <span className={Classes.TREE_NODE_LABEL}>{props.site.name}</span>
      </div>
      <div className={Classes.TREE}>
        <ul className={Classes.TREE_NODE_LIST}>
          {children.map((stream) => (
            <StreamNode key={stream.id} stream={stream} />
          ))}
        </ul>
      </div>
    </li>
  );
};
