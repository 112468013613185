import { randomString } from '../../utils/random';

/**
 *
 */
function getInstanceId(): string {
  let instanceId: string | null = sessionStorage.getItem(
    'vgrid-client-instance'
  );

  if (!instanceId) {
    instanceId = randomString(16);
    sessionStorage.setItem('vgrid-client-instance', instanceId);
  }

  return instanceId;
}

export const apiVersion = __API_VERSION__;
export const clientInstance = getInstanceId();
export const clientVersion = `vGRID-DesktopWeb/${process.env.RELEASE} (${process.env.BUILD_TIME})`;
