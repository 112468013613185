import { useContext, useMemo } from 'react';

import { InsightsContext } from './InsightsContext';
import { Duration, DurationCounts, Insights } from './types';

const initial: DurationCounts = {
  correct: 0,
  incorrect: 0,
  reads: 0,
  unsure: 0
};

/**
 *
 * @param a
 * @param b
 */
function sum(a: DurationCounts, b: DurationCounts): DurationCounts {
  return {
    correct: a.correct + b.correct,
    incorrect: a.incorrect + b.incorrect,
    reads: a.reads + b.reads,
    unsure: a.unsure + b.unsure
  };
}

/**
 *
 * @param selected
 * @param duration
 */
export function useCounts(
  selected: string | null,
  duration: Duration
): DurationCounts {
  const insights = useContext<Insights>(InsightsContext);

  return useMemo(() => {
    /* eslint-disable security/detect-object-injection */

    const counts = insights.counts[duration];
    const streams = insights.streams;

    // All
    if (!selected) {
      return Object.values(counts).reduce(sum, initial);
    }

    // Stream
    if (counts[selected]) {
      return counts[selected];
    }

    // Site
    const streamIds = Object.values(streams)
      .filter((stream) => stream.siteId === selected)
      .map((stream) => stream.id);

    return Object.entries(counts)
      .filter(([id]) => streamIds.includes(id))
      .map(([_id, data]) => data)
      .reduce(sum, initial);
  }, [duration, insights, selected]);
}
