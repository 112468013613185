import { ApolloClient } from '@apollo/client';
import Push from 'push.js';
import Visibility from 'visibilityjs';

import { ADD_NOTIFICATION } from '../graphql/localQueries/notifications';

import { audio } from './audio';
import { getSettings } from './get-settings';
import { handlers } from './handlers';
import { Notification } from './notification-types';
import { removeNotification } from './remove';

const noop = () => null;

/**
 *
 * @param client
 * @param notification
 */
export function addNotification(
  client: ApolloClient<any>,
  notification: Notification
): void {
  const { disabled, muted } = getSettings(client);

  if (disabled && !notification.force) {
    return;
  }

  client
    .mutate({
      mutation: ADD_NOTIFICATION,
      variables: { notification }
    })
    .catch((error: Error) => console.error(error));

  // External notification
  if (Visibility.hidden() && Push.Permission.has()) {
    const handler = notification.handler;

    Push.create(notification.title, {
      body: notification.message,
      onClick: handler
        ? () => {
            handlers[handler[0]].call(window, client, ...handler.slice(1));
            window.focus();
            Push.close(notification.id);
            removeNotification(client, notification.id);
          }
        : undefined,
      tag: notification.id,
      timeout: notification.timeout
    }).catch((error: Error) => console.error(error));
  }

  // Play notification sound
  const sound = notification.sound ? audio[notification.sound] : null;
  if (sound && !muted) {
    sound.play().catch(noop);
  }

  // Timeout
  if (notification.timeout) {
    setTimeout(
      () => removeNotification(client, notification.id),
      notification.timeout
    );
  }
}
