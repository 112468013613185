import { DocumentNode } from 'graphql';

import * as grid from './grid';
import * as sentry from './sentry';
import * as streams from './streams';

export interface IWatcher {
  query: DocumentNode;
  subscriber: (result: any) => void;
}

export const watchers: IWatcher[] = [grid, sentry, streams];
