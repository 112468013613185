import { FC } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  Location
} from 'react-router-dom';

import AccountActivation from '../../pages/anonymous/AccountActivation';
import ForgotPassword from '../../pages/anonymous/ForgotPassword';
import Login from '../../pages/anonymous/Login';
import ResetPassword from '../../pages/anonymous/ResetPassword';
import ExportApproval from '../../pages/anpr/ExportApproval';

type AnonymousLocation = Location<{
  ignoreRedirect?: boolean;
  target?: string;
} | null>;

/**
 *
 */
export const AnonymousRoutes: FC = () => {
  const {
    state: locationState,
    pathname,
    search
  } = useLocation() as AnonymousLocation;

  const redirect = locationState?.ignoreRedirect
    ? undefined
    : locationState?.target ?? pathname + search;

  return (
    <Routes>
      <Route index={true} element={<Login redirect={redirect} />} />

      <Route path="/activate/:token/:email" element={<AccountActivation />} />

      <Route path="/forgot-password" element={<ForgotPassword />} />

      <Route
        path="/forgot-password/:token/:email"
        element={<ResetPassword />}
      />

      <Route
        path="/vault-anpr-approval"
        element={<ExportApproval standalone={true} />}
      />

      <Route
        path="*"
        element={
          <Navigate
            to="/"
            state={{ ...locationState, target: pathname + search }}
          />
        }
      />
    </Routes>
  );
};
