import { gql } from '@apollo/client';

export const GET_PLAYING_STREAMS = gql`
  query getPlayingStreams {
    grid @client {
      id
      started
    }
    layout @client
    stretch @client
  }
`;

export const CLEAR_LAYOUT = gql`
  mutation clearLayout {
    clearLayout @client
  }
`;

export const CLOSE_STREAM = gql`
  mutation closeStream($index: Int!) {
    closeStream(index: $index) @client
  }
`;

export const SET_PLAYING_STREAM = gql`
  mutation SetPlayingStream(
    $id: String!
    $oldPosition: Boolean
    $position: Int
    $started: Int
    $type: String!
  ) {
    setPlayingStream(
      id: $id
      oldPosition: $oldPosition
      position: $position
      started: $started
      type: $type
    ) @client
  }
`;

export const SET_LAYOUT = gql`
  mutation ChangeLayout($layout: String!) {
    changeLayout(layout: $layout) @client
  }
`;

export const SET_STRETCH = gql`
  mutation SetStretch($stretch: Boolean!) {
    setStretch(stretch: $stretch) @client
  }
`;
