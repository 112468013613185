import useResizeObserver from '@react-hook/resize-observer';
import { FC } from 'react';
import { useMapEvent } from 'react-leaflet';

import { MapBaseOptions } from '../Map';

export const BoundsChanged: FC<MapBaseOptions> = (props) => {
  const map = useMapEvent('moveend', () => {
    if (!props.onBoundsChanged) {
      return;
    }

    const bounds = map.getBounds();
    const zoom = map.getZoom();

    const east = bounds.getEast();
    const north = bounds.getNorth();
    const south = bounds.getSouth();
    const west = bounds.getWest();

    props.onBoundsChanged(
      { east, north, south, west },
      zoom,
      props.currentLevel ?? null
    );
  });

  // Invalidate the map size when the container resizes
  const container = map.getContainer();

  useResizeObserver(container, () => {
    map.invalidateSize();
  });

  return null;
};
