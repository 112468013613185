import { useLocalStorage } from 'usehooks-ts';

interface Feature {
  name: string;
  description: string;
  superadmin?: boolean;
}

type FeatureType = 'streamsOnFullMap';

const features: Record<FeatureType, Feature> = {
  streamsOnFullMap: {
    description:
      'Allows you to add floating streams to the full map view, resizing and moving them around as needed.',
    name: 'Streams on full map view'
  }
} as const;

/**
 *
 */
export const useFeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = useLocalStorage(
    'vgrid.featureFlags',
    Object.keys(features).reduce(
      (obj, key) => {
        // eslint-disable-next-line security/detect-object-injection
        obj[key as FeatureType] = false;
        return obj;
      },
      {} as Record<FeatureType, boolean>
    )
  );

  // TODO: This doesn't currently remove old feature flags if they are removed from the features object.
  const setFeatureFlag = (key: FeatureType, enabled: boolean) => {
    setFeatureFlags({
      ...featureFlags,
      [key]: enabled
    });
  };

  return {
    featureFlags,
    features,
    setFeatureFlag
  };
};
