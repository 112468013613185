import type { SVGProps, FC } from 'react';
import { Link } from 'react-router-dom';

import { VGridLogo } from '../VGridLogo/VGridLogo';

type LogoProps = SVGProps<SVGSVGElement>;

/**
 *
 * @param props
 */
export const Logo: FC<LogoProps> = (props) => (
  <Link to={props.href || '/'}>
    <VGridLogo {...props} />
  </Link>
);
