import { Classes } from '@blueprintjs/core';
import { FC } from 'react';

import { AnprOffenceSelect } from '../../../components/molecules/AnprOffenceSelect';
import { offenceList } from '../../../components/molecules/AnprOffenceSelect/offenceList';
import { ReferenceInputProps } from '../../../components/pages/anpr/QuickSearch/SearchForm/SearchForm';
import { QuickSearchLocalisation } from '../../localisation';

const infoCallout = (
  <ul className={Classes.LIST_UNSTYLED}>
    <li>
      All search requests are logged and must be used for legitimate Police
      purposes.
    </li>
    <li>Quick Search returns the latest 30 days worth of data.</li>
  </ul>
);

const InputElement: FC<ReferenceInputProps> = (props) => (
  <AnprOffenceSelect
    onChange={props.onChange}
    value={props.value}
    inputProps={{
      id: 'reference',
      intent: props.intent,
      name: 'reference',
      required: true
    }}
  />
);

const schema = {
  reference: {
    validators: [
      {
        message: 'Offence Code is a required field',
        test: true
      },
      {
        message: 'Offence code must be at least 4 characters',
        test: (value: string): boolean => {
          return offenceList.some((offenceItem) => offenceItem.text === value);
        }
      }
    ]
  }
};

export const quickSearch: QuickSearchLocalisation = {
  infoCallout,
  onChangeFocusSubmit: true,
  reference: {
    helperText: 'Please enter an offence code',
    InputElement,
    labelText: 'Offence Code',
    required: true
  },
  schema
};
