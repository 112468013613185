/**
 * Wrapped error
 */
class WrappedError extends Error {
  public originalError: Error;

  /**
   *
   * @param originalError
   */
  constructor(originalError: Error) {
    super(originalError.message);

    this.originalError = originalError;
  }
}

/**
 * Internal error
 */
export class InternalError extends WrappedError {
  /**
   *
   * @param originalError
   * @param message
   */
  constructor(originalError: Error, message?: string) {
    super(originalError);

    this.name = 'InternalError';

    if (message) {
      this.message = message;
    }
  }
}

/**
 * User error
 */
export class UserError extends WrappedError {
  /**
   *
   * @param originalError
   * @param message
   */
  constructor(originalError: Error, message?: string) {
    super(originalError);

    this.name = 'UserError';

    if (message) {
      this.message = message;
    }
  }
}
