import { ApolloClient } from '@apollo/client';

import { router } from '../../components/App';
import type { CurrentUser } from '../../components/context/current-user/get-current-user';

import { cache } from './cache';
import { getNetworkLink } from './link';
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SETTINGS
} from './localQueries/notifications';
import { GET_PLAYING_STREAMS } from './localQueries/streams';
import { resolvers } from './localResolvers';
import {
  NOTIFICATIONS_SETTINGS_KEY,
  NotificationSettings
} from './localResolvers/notifications';
import { WHOAMI } from './queries/authentication';
import { REMOTE_COMMAND } from './queries/remote-command';
import { watchers, IWatcher } from './watchers';

/**
 *
 * @param user
 */
export function getAuthenticatedClient(user: CurrentUser): ApolloClient<any> {
  const client = new ApolloClient({
    cache,
    connectToDevTools: __DEV__,
    link: getNetworkLink(true),
    resolvers
  });

  client.writeQuery({
    data: { whoami: user },
    query: WHOAMI
  });

  /**
   * Notifications
   * =============================================================================
   */

  client.writeQuery({
    data: {
      notifications: []
    },
    query: GET_NOTIFICATIONS
  });

  client.writeQuery({
    data: {
      notificationsSettings: {
        disableComputer: false,
        disableTab: false,
        muteComputer: false
      }
    },
    query: GET_NOTIFICATIONS_SETTINGS
  });

  setTimeout((): void => {
    const notificationsSettings: NotificationSettings = {
      disableComputer: false,
      disableTab: false,
      muteComputer: false
    };

    const computer = localStorage.getItem(NOTIFICATIONS_SETTINGS_KEY);
    const tab = sessionStorage.getItem(NOTIFICATIONS_SETTINGS_KEY);

    if (computer) {
      try {
        const value = JSON.parse(computer);
        notificationsSettings.disableComputer = value.disable || false;
        notificationsSettings.muteComputer = value.mute || false;
      } catch (e) {
        localStorage.removeItem(NOTIFICATIONS_SETTINGS_KEY);
      }
    }

    if (tab) {
      try {
        const value = JSON.parse(tab);
        notificationsSettings.disableTab = value.disable || false;
      } catch (e) {
        sessionStorage.removeItem(NOTIFICATIONS_SETTINGS_KEY);
      }
    }

    client.writeQuery({
      data: {
        notificationsSettings
      },
      query: GET_NOTIFICATIONS_SETTINGS
    });
  }, 100);

  window.addEventListener('storage', (): void => {
    const computer = localStorage.getItem(NOTIFICATIONS_SETTINGS_KEY);

    if (!computer) {
      return;
    }

    const notificationsSettings: Partial<NotificationSettings> = {
      disableComputer: false,
      muteComputer: false
    };

    try {
      const value = JSON.parse(computer);
      notificationsSettings.disableComputer = value.disable || false;
      notificationsSettings.muteComputer = value.mute || false;
    } catch (e) {
      localStorage.removeItem(NOTIFICATIONS_SETTINGS_KEY);

      return;
    }

    const existing = client.readQuery<{
      notificationsSettings: NotificationSettings;
    }>({
      query: GET_NOTIFICATIONS_SETTINGS
    });

    client.writeQuery({
      data: {
        notificationsSettings: {
          disableComputer: false,
          disableTab: false,
          muteComputer: false,
          ...(existing?.notificationsSettings || {}),
          ...notificationsSettings
        }
      },
      query: GET_NOTIFICATIONS_SETTINGS
    });
  });

  /**
   * Streams
   * =============================================================================
   */

  client.writeQuery({
    data: {
      grid: new Array(16).fill(null),
      layout: 'mapMontage',
      stretch: true
    },
    query: GET_PLAYING_STREAMS
  });

  setTimeout((): void => {
    if (
      window.location.pathname === '/streams' &&
      window.location.search.includes('openStream')
    ) {
      const searchParams = new URLSearchParams(window.location.search);
      const streamId = searchParams.get('openStream');

      if (streamId) {
        client.writeQuery({
          data: {
            grid: [
              {
                __typename: 'PlayingStream',
                id: streamId,
                started: Date.now()
              },
              ...new Array(16).fill(null)
            ].slice(0, 16),
            layout: 'single',
            stretch: true
          },
          query: GET_PLAYING_STREAMS
        });

        router.navigate('/streams');

        return;
      }
    }

    const data: string | null = localStorage.getItem('vgrid_streams');

    if (!data) {
      return;
    }

    let streams: any;
    try {
      streams = JSON.parse(data);
    } catch (e) {
      localStorage.removeItem('vgrid_streams');

      return;
    }

    client.writeQuery({
      data: {
        ...streams,
        grid: [...streams.grid, ...new Array(16).fill(null)].slice(0, 16)
      },
      query: GET_PLAYING_STREAMS
    });
  }, 100);

  /**
   * Remote commands
   * =============================================================================
   */

  interface RemoteCommand {
    remoteCommand: {
      command: 'ALERT' | 'LOGOUT' | 'RELOAD';
      data: string;
    };
  }

  const remoteCommandObserver = client.subscribe<RemoteCommand>({
    query: REMOTE_COMMAND
  });

  remoteCommandObserver.subscribe((message): void => {
    if (!message.data) {
      return;
    }

    const command: string = message.data.remoteCommand.command;
    /* let data: any = null;

    try {
      data = JSON.parse(message.data.remoteCommand.data);
    } catch (error) {
      // do nothing
    } */

    switch (command) {
      case 'ALERT':
        // Do nothing, it's handled in an alert module
        break;

      case 'LOGOUT':
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        break;

      case 'RELOAD':
        window.location.reload();
        break;
    }
  });

  /**
   * Watchers
   */

  watchers.forEach((watcher: IWatcher): void => {
    client.watchQuery({ query: watcher.query }).subscribe(watcher.subscriber);
  });

  return client;
}
