import {
  apiVersion,
  clientInstance,
  clientVersion
} from '../../../lib/graphql/link/meta';

/**
 *
 */
export async function logoutCurrentUser(): Promise<void> {
  await fetch('/api', {
    body: '{"query":"mutation logoutCurrentSecurityDevice{logoutCurrentSecurityDevice}"}',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Version': apiVersion,
      'X-Client-Instance': clientInstance,
      'X-Client-Version': clientVersion
    },
    method: 'POST',
    mode: 'same-origin',
    redirect: 'error'
  });
}
