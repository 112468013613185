import { Intent } from '@blueprintjs/core';
import update, { Spec } from 'immutability-helper';
import { Reducer } from 'react';

import { hasChanged, hasErrors } from './helpers';
import { FormValues } from './validate';

export interface FormState {
  changed: Record<string, boolean>;
  errors: Record<string, string | null>;
  hasChanges: boolean;
  hasErrors: boolean;
  intents: Record<string, Intent>;
  values: FormValues;
}

export interface ChangeActionPayload {
  changed: boolean;
  error: string | null;
  key: string;
  value: any;
}

interface FormBulkChangeAction {
  payload: ChangeActionPayload[];
  type: 'FormBulkChangeAction';
}

interface FormChangeAction {
  payload: ChangeActionPayload;
  type: 'FormChangeAction';
}

interface FormErrorIntentsAction {
  payload: string[];
  type: 'FormErrorIntentsAction';
}

interface FormErrorsAction {
  payload: Record<string, string>;
  type: 'FormErrorsAction';
}

interface FormResetAction {
  payload: FormState;
  type: 'FormReset';
}

type FormAction =
  | FormBulkChangeAction
  | FormChangeAction
  | FormErrorIntentsAction
  | FormErrorsAction
  | FormResetAction;

export type FormReducer = Reducer<FormState, FormAction>;

/**
 *
 * @param prevState
 * @param action
 */
export const reducer: FormReducer = (
  prevState: FormState,
  action: FormAction
): FormState => {
  /* eslint-disable security/detect-object-injection */
  if (action.type === 'FormBulkChangeAction') {
    const next = action.payload.reduce(
      (state, field) => {
        const { changed, error, key, value } = field;

        state.changed[key] = {
          $set: changed
        };
        state.errors[key] = {
          $set: error
        };
        state.intents[key] = {
          $set: error ? Intent.DANGER : Intent.SUCCESS
        };
        state.values[key] = {
          $set: value
        };

        return state;
      },
      {
        changed: {},
        errors: {},
        intents: {},
        values: {}
      } as {
        changed: Record<string, Spec<boolean>>;
        errors: Record<string, Spec<string | null>>;
        intents: Record<string, Spec<Intent>>;
        values: Record<string, Spec<FormValues>>;
      }
    );

    const nextState = update(prevState, next);

    nextState.hasChanges = hasChanged(nextState.changed);
    nextState.hasErrors = hasErrors(nextState.errors);

    return nextState;
  }

  if (action.type === 'FormChangeAction') {
    const nextState = update(prevState, {
      changed: {
        [action.payload.key]: {
          $set: action.payload.changed
        }
      },
      errors: {
        [action.payload.key]: {
          $set: action.payload.error
        }
      },
      intents: {
        [action.payload.key]: {
          $set: action.payload.error ? Intent.DANGER : Intent.SUCCESS
        }
      },
      values: {
        [action.payload.key]: {
          $set: action.payload.value
        }
      }
    });

    nextState.hasChanges = hasChanged(nextState.changed);
    nextState.hasErrors = hasErrors(nextState.errors);

    return nextState;
  }

  if (action.type === 'FormErrorIntentsAction') {
    return update(prevState, {
      hasErrors: {
        $set: true
      },
      intents: {
        $apply: (intents: Record<string, Intent>): Record<string, Intent> => {
          for (const key of action.payload) {
            intents[key] = Intent.DANGER;
          }

          return intents;
        }
      }
    });
  }

  if (action.type === 'FormErrorsAction') {
    return update(prevState, {
      errors: {
        $apply: (
          errors: Record<string, string | null>
        ): Record<string, string | null> => {
          for (const key in action.payload) {
            errors[key] = action.payload[key];
          }

          return errors;
        }
      },
      hasErrors: {
        $set: true
      },
      intents: {
        $apply: (intents: Record<string, Intent>): Record<string, Intent> => {
          for (const key in action.payload) {
            intents[key] = Intent.DANGER;
          }

          return intents;
        }
      }
    });
  }

  if (action.type === 'FormReset') {
    return update(action.payload, {});
  }

  return prevState;
};
