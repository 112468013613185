import { MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemRendererProps } from '@blueprintjs/select';
import type { FC } from 'react';

import { SearchResult } from './types';

interface SearchRowProps {
  item: SearchResult;
  itemProps: ItemRendererProps;
}

/**
 *
 * @param props
 */
export const SearchRow: FC<SearchRowProps> = (props) => (
  <MenuItem
    active={props.itemProps.modifiers.active}
    disabled={props.itemProps.modifiers.disabled}
    icon={
      props.item.type === 'place'
        ? IconNames.GEOLOCATION
        : IconNames.MOBILE_VIDEO
    }
    label={props.item.secondary}
    onClick={props.itemProps.handleClick}
    text={props.item.primary}
  />
);
