import { Dialog } from '@blueprintjs/core';
import { FC } from 'react';

import {
  HELP_ANONYMOUS,
  HELP_AUTHENTICATED
} from '../../../lib/graphql/queries/debug';
import { useCurrentUser } from '../../../lib/hooks/current-user';
import { Form } from '../../molecules/Form';

import { HelpForm } from './HelpForm';
import { schema } from './HelpForm.schema';

interface HelpDialogProps {
  readonly isOpen: boolean;
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 *
 */
export const HelpDialog: FC<HelpDialogProps> = (props) => {
  const context = useCurrentUser();
  const { isOpen, setOpen } = props;

  const defaultValues = {
    email: context.email || '',
    message: '',
    name: context.name || ''
  };

  const parseValues = (values: any) => ({
    email: context.email ? undefined : values.email,
    message: values.message,
    name: context.name ? undefined : values.name,
    url: window.location.href
  });

  return (
    <Dialog
      className="vg-help-dialog"
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      title="Help"
    >
      <Form
        defaultValues={defaultValues}
        mutation={context.email ? HELP_AUTHENTICATED : HELP_ANONYMOUS}
        onUpdate={(): void => setOpen(false)}
        parseValues={parseValues}
        schema={schema}
      >
        {(formProps) => (
          <HelpForm
            close={() => setOpen(false)}
            isOpen={isOpen}
            {...formProps}
          />
        )}
      </Form>
    </Dialog>
  );
};
