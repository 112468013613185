import { Classes, Icon, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import { ReactElement } from 'react';

const classes: Record<string, string[]> = {
  [Classes.TREE]: [Classes.TREE, Classes.ELEVATION_0],
  [Classes.TREE_ROOT]: [Classes.TREE_NODE_LIST, Classes.TREE_ROOT]
};

const contentStyle = {
  backgroundColor: 'transparent',
  cursor: 'default',
  opacity: 0.5
};

const iconStyle = {
  color: 'rgb(138, 155, 168)'
};

/**
 *
 */
export const StreamViewPlaceholder: ReactElement = (
  <div className="vg-streams__container vg-streams--showSidebar">
    <div className="vg-streams__sidebar__container">
      <div className="vg-streams__sidebar__panel">
        <div className="vg-streams__sidebar__header">
          <span>Streams</span>
        </div>
        <div className="vg-streams__sidebar__content">
          <div className={classes[Classes.TREE].join(' ')}>
            <ul className={classes[Classes.TREE_ROOT].join(' ')}>
              <li className={Classes.TREE_NODE}>
                <div className={Classes.TREE_NODE_CONTENT} style={contentStyle}>
                  <span
                    className={classNames(
                      Classes.ICON_STANDARD,
                      Classes.TREE_NODE_CARET,
                      Classes.TREE_NODE_CARET_CLOSED
                    )}
                  />
                  <Icon
                    className={Classes.TREE_NODE_ICON}
                    icon={IconNames.FOLDER_CLOSE}
                    style={iconStyle}
                  />
                  <div className={Classes.TREE_NODE_LABEL}>
                    <span className={Classes.SKELETON}>{'x'.repeat(25)}</span>
                  </div>
                </div>
              </li>
              <li className={Classes.TREE_NODE}>
                <div className={Classes.TREE_NODE_CONTENT} style={contentStyle}>
                  <span
                    className={classNames(
                      Classes.ICON_STANDARD,
                      Classes.TREE_NODE_CARET,
                      Classes.TREE_NODE_CARET_CLOSED
                    )}
                  />
                  <Icon
                    className={Classes.TREE_NODE_ICON}
                    icon={IconNames.FOLDER_CLOSE}
                    style={iconStyle}
                  />
                  <div className={Classes.TREE_NODE_LABEL}>
                    <span className={Classes.SKELETON}>{'x'.repeat(17)}</span>
                  </div>
                </div>
              </li>
              <li className={Classes.TREE_NODE}>
                <div className={Classes.TREE_NODE_CONTENT} style={contentStyle}>
                  <span
                    className={classNames(
                      Classes.ICON_STANDARD,
                      Classes.TREE_NODE_CARET,
                      Classes.TREE_NODE_CARET_CLOSED
                    )}
                  />
                  <Icon
                    className={Classes.TREE_NODE_ICON}
                    icon={IconNames.MAP_MARKER}
                    style={iconStyle}
                  />
                  <div className={Classes.TREE_NODE_LABEL}>
                    <span className={Classes.SKELETON}>{'x'.repeat(21)}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className={classNames('vg-grid__container', Classes.DARK)}>
      <div className="vg-grid-wrapper">
        <div className="vg-grid__item" style={{ justifyContent: 'center' }}>
          <Spinner size={150} />
        </div>
      </div>
      <div className="vg-streams__control-bar__container">
        <div className={Classes.NAVBAR} />
      </div>
    </div>
  </div>
);
