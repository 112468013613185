import { useContext } from 'react';

import { InsightsContext } from './InsightsContext';
import { Insights, Streams } from './types';

/**
 *
 */
export function useStreams(): Streams {
  const insights = useContext<Insights>(InsightsContext);

  return insights.streams;
}
