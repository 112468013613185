import { MenuItem } from '@blueprintjs/core';
import type { FC } from 'react';

import styles from './Search.module.scss';

interface NoResultsProps {
  length: number;
}

/**
 *
 */
export const NoResults: FC<NoResultsProps> = () => (
  <MenuItem
    className={styles.disabled}
    disabled={true}
    text={
      length < 3
        ? 'At least 3 characters are required'
        : 'No results matched your query'
    }
  />
);
