import update from 'immutability-helper';

import {
  layouts,
  LayoutName
} from '../../../components/pages/streams/Grid/layouts';
import { cache } from '../cache';
import { GET_PLAYING_STREAMS } from '../localQueries';

interface SetPlayingOptions {
  id: string;
  oldPosition: number | null;
  position?: number;
  started: number;
  type: 'PlayingGlimpse' | 'PlayingStream';
}

interface StreamInstance {
  id: string;
  started: number;
  __typename: 'PlayingGlimpse' | 'PlayingStream';
}

interface StreamsData {
  grid: Array<StreamInstance | null>;
  layout: LayoutName;
  stretch: boolean;
}

function getPlayingStreams(): StreamsData {
  let data: StreamsData = {
    grid: new Array(16).fill(null),
    layout: 'mapMontage',
    stretch: true
  };

  try {
    const temp: StreamsData | null = cache.readQuery({
      query: GET_PLAYING_STREAMS
    });

    if (temp) {
      data = temp;
    }
  } catch (e) {
    //
  }

  return data;
}

/**
 *
 * @param _rootValue
 * @param args
 *   @param args.grid
 *   @param args.layout
 */
export function changeLayout(
  _rootValue: undefined,
  args: { layout: LayoutName }
): any {
  if (layouts[args.layout]) {
    const existing: StreamsData = getPlayingStreams();
    const max: number = layouts[args.layout].max;

    cache.writeQuery({
      data: update(existing, {
        grid: {
          $set:
            existing.grid.length >= max
              ? existing.grid
              : existing.grid.concat(
                  new Array(max - existing.grid.length).fill(null)
                )
        },
        layout: {
          $set: args.layout
        }
      }),
      query: GET_PLAYING_STREAMS
    });
  }

  return args.layout;
}

/**
 *
 */
export function clearLayout(): boolean {
  const existing: StreamsData = getPlayingStreams();

  cache.writeQuery({
    data: update(existing, {
      grid: {
        $set: new Array(16).fill(null)
      }
    }),
    query: GET_PLAYING_STREAMS
  });

  return true;
}

/**
 *
 * @param _rootValue
 * @param args
 *   @param args.index
 */
export function closeStream(
  _rootValue: undefined,
  args: { index: number }
): boolean {
  cache.writeQuery({
    data: update(getPlayingStreams(), {
      grid: {
        [args.index]: {
          $set: null
        }
      }
    }),
    query: GET_PLAYING_STREAMS
  });

  return true;
}

/**
 *
 * @param _rootValue
 * @param options
 *   @param options.id
 *   @param options.oldPosition
 *   @param options.position
 *   @param options.started
 *   @param options.type
 */
export function setPlayingStream(
  _rootValue: undefined,
  options: SetPlayingOptions
): StreamInstance | void {
  const changes: Array<{ i: number; stream: StreamInstance }> = [];
  const existing: StreamsData = getPlayingStreams();

  const newStream: StreamInstance = {
    __typename: options.type,
    id: options.id,
    started: options.started || Date.now()
  };

  if (options.position === undefined) {
    // No room for new stream
    if (
      existing.grid.filter((v: StreamInstance | null): boolean => v !== null)
        .length === existing.grid.length
    ) {
      return;
    }

    // Check where to put the element we're adding (auto place)
    existing.grid.forEach((stream: any, index: number): void => {
      if (!stream && options.position === undefined) {
        options.position = index;
      }
    });
  }

  if (options.position !== undefined) {
    if (options.oldPosition !== undefined && options.oldPosition !== null) {
      changes.push({
        i: options.oldPosition,
        stream: existing.grid[options.position] as StreamInstance
      });
    }

    changes.push({
      i: options.position,
      stream: newStream
    });
  }

  cache.writeQuery({
    data: update(existing, {
      grid: {
        $splice: changes.map<[number, number, StreamInstance]>(
          (row: { i: number; stream: StreamInstance }) => [row.i, 1, row.stream]
        )
      }
    }),
    query: GET_PLAYING_STREAMS
  });

  return newStream;
}

/**
 *
 * @param _rootValue
 * @param args
 *   @param args.stretch
 */
export function setStretch(
  _rootValue: undefined,
  args: { stretch: boolean }
): boolean {
  const existing: StreamsData = getPlayingStreams();

  cache.writeQuery({
    data: update(existing, {
      stretch: {
        $set: args.stretch
      }
    }),
    query: GET_PLAYING_STREAMS
  });

  return args.stretch;
}
