import { extend } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import type { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { useWakeLock } from '../lib/hooks/wake-lock';

import { ErrorBoundary } from './atoms/ErrorBoundary';
import { CurrentUserProvider } from './context/current-user';
import { DebugProvider } from './context/debug';
import { Anonymous } from './router/anonymous/Anonymous';
import { Authenticated } from './router/authenticated/Authenticated';

extend(customParseFormat);
extend(duration);
extend(relativeTime);

/**
 *
 */
const Vgrid: FC = () => {
  useWakeLock();

  return (
    <DebugProvider>
      <ErrorBoundary>
        <DndProvider backend={HTML5Backend}>
          <Helmet>
            <title>vGRID</title>
          </Helmet>
          <CurrentUserProvider fallback={<Anonymous />}>
            <Authenticated />
          </CurrentUserProvider>
        </DndProvider>
      </ErrorBoundary>
    </DebugProvider>
  );
};

// Nested routes are allowed
// We export the router so we can navigate outside of react components
export const router = createBrowserRouter([
  // match everything with "*"
  { element: <Vgrid />, path: '*' }
]);

export const App: FC = () => <RouterProvider router={router} />;
