import { gql } from '@apollo/client';

export const ALERT_TEST = gql`
  mutation alertTest {
    alertTest
  }
`;

export const HELP_ANONYMOUS = gql`
  mutation help(
    $email: String!
    $message: String!
    $name: String!
    $url: String!
  ) {
    help(email: $email, message: $message, name: $name, url: $url)
  }
`;

export const HELP_AUTHENTICATED = gql`
  mutation help($message: String!, $url: String!) {
    help(message: $message, url: $url)
  }
`;

export const PAGE_VIEW = gql`
  mutation pageView($path: String!) {
    pageView(path: $path)
  }
`;
