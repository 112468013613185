import { InsightsResponse } from './types';

/**
 *
 * @param data
 */
export function getHeatmap(data?: InsightsResponse): Record<string, number> {
  if (!data) {
    return {};
  }

  const heatmap: Record<string, number> = {};

  for (const row of data.map) {
    heatmap[row.id] = row.count;
  }

  return heatmap;
}
