export interface OffenceItem {
  text: string;
  index: number;
}

export const offenceList: OffenceItem[] = [
  '1100 - Homicide',
  '1200 - Kidnapping And Abduction',
  '1300 - Robbery',
  '1400 - Grievous Assaults',
  '1500 - Serious Assaults',
  '1600 - Minor Assaults',
  '1700 - Intimidation/threats',
  '1800 - Group Assemblies',
  '1A - Alarm',
  '1B - Bomb Scare',
  '1C - Car/Person Acting Suspiciously',
  '1E - Emergency/Disaster/Spill',
  '1F - Assist Fire/Ambulance/Traffic',
  '1G - Solvent Abuse',
  '1H - Drunk Home',
  '1I - Blockage/Breakdown On Highway',
  '1K - Drunk Custody/Detox Centre',
  '1L - Land Rescue',
  '1M - Mental Health',
  '1N - Noise Control',
  '1P - Premises Insecure',
  '1R - Breach of the Peace',
  '1S - Sudden Death',
  '1U - Traffic Offending',
  '1V - Vehicle Collision',
  '1W - Water/Sea Rescue/Emergency',
  '1X - Threatens/Attempts Suicide',
  '1Z - Other Incident',
  '2200 - Sexual Affronts Othe',
  '2600 - Sexual Attacks',
  '2700 - Abnormal Sex',
  '2800 - Immoral Behaviour',
  '2900 - Immoral Behaviour/Miscellaneous',
  '2C - Civil Dispute',
  '2M - Missing Person',
  '2N - Civil Court Process',
  '2O - Court Orders',
  '2P - Public Relations',
  '2R - Recovery Motor Vehicle',
  '2S - Summons',
  '2T - Warrant to Arrest/Fines Enforce',
  '2U - Warrant of Seizure',
  '2W - Arrest Warrant (other)',
  '2Y - Stock/Animals',
  '2Z - Other Service Request/Response',
  '3100 - Drugs (not Cannabis)',
  '3200 - Drugs (Cannabis)',
  '3500 - Disorder',
  '3600 - Vagrancy Offences',
  '3700 - Family Offences',
  '3800 - Family Offences',
  '3900 - Liquor Offences',
  '3R - Road Checkpoint',
  '3T - Turnover',
  '3W - Watching/Observations',
  '3Z - Other Preventive Task',
  '4100 - Burglary',
  '4200 - Car Conversion',
  '4210 - Unlawful Taking/Conversion',
  '4300 - Theft',
  '4500 - Fraud',
  '4600 - Computer Crime',
  '4900 - Accessory After The Fact',
  '4Q - Enquiry/Investigation',
  '4X - Execute Search Warrant',
  '5100 - Destruction of Property',
  '5110 - Arson',
  '5200 - Endangering',
  '5810 - Gambling Act',
  '5900 - Drugs (New Drugs)',
  '5F - Family Harm',
  '5H - EM Bail Visit/Check',
  '5I - Instruction and Training',
  '5K - Bail Check',
  '5M - Parole Recall Warrant',
  '5V - Second Hand Dealer Check',
  '6100 - Trespass',
  '6200 - Littering',
  '6300 - Animals Endangering',
  '6500 - Postal Abuses',
  '6800 - Firearms Offences',
  '6910 - Sentencing Act 2002',
  '6D - Bail Breach',
  '6E - EM Bail Breach',
  '6I - Unauthorised Street and Drag Racing',
  '7100 - Against Justice',
  '7200 - Births/deaths and Marriages',
  '7330 - Immigration/Maritime',
  '7400 - Racial',
  '7500 - Against National Interest/treason',
  '7600 - By Law Breaches',
  '7900 - Justice Special',
  '7A - Controlled Purchase Operation',
  '8PA - Pandemic 72hr Check',
  '8PB - Pandemic Person Check',
  '8PC - Pandemic Business Check',
  '8PD - Pandemic Check Via Telephone Only',
  '8PG - Pandemic Mass Gathering',
  '8PL - Directed Patrol',
  '8PM - Reassurance Essential Facility',
  '8P - Pandemic Response',
  '8PZ - Pandemic Checkpoint',
  'Pursuit of Vehicle',
  'Pre-Production Search'
].map((offence, index) => ({ index, text: offence }));
