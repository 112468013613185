import { Classes, FormGroup, InputGroup } from '@blueprintjs/core';
import { Fragment } from 'react';

import { LinkifiedText } from '../../../components/atoms/LinkifiedText';
import { RequiredLabel } from '../../../components/atoms/RequiredLabel';
import { StaticListSelect } from '../../../components/atoms/StaticListSelect';
import { AnprOffenceSelect } from '../../../components/molecules/AnprOffenceSelect';
import { DetailTableRow } from '../../../components/molecules/DetailTable';
import { FormResult } from '../../../components/molecules/Form/useForm';
import { FormValues } from '../../../components/molecules/Form/validate';
import { maxLength, minLength } from '../../../lib/utils/validation';
import { PlatesOfInterestLocalisation } from '../../localisation';

const POLICE_EMAIL = /^\w+\.\w+@police\.govt\.nz$/;
const SAFERCITIES_EMAIL = /^\w+@safercities\.com$/;

const REASON_FOR_TRACKING_MAP_SHORT = {
  searchSurveillance: 'Search Surveillance',
  seriousRisk: 'Serious Risk / Threat',
  warrant: 'Tracking Warrant'
} as const;

const reasonForTrackingOptions = [
  { id: '', name: 'Select a reason' },
  { id: 'warrant', name: 'Tracking Warrant' },
  {
    id: 'searchSurveillance',
    name: 'Section 48 - Search and Surveillance Act'
  },
  {
    id: 'seriousRisk',
    name: 'Serious Risk / Threat to life, public health or public safety'
  }
];

const formElements = ({
  form,
  disabled
}: {
  form: FormResult;
  disabled: boolean;
}) => (
  <Fragment>
    <FormGroup
      intent={form.intents.fileEventNo}
      helperText={form.changed.fileEventNo ? form.errors.fileEventNo : null}
      label={<RequiredLabel text="Police File / Event #" />}
      labelFor="fileEventNo"
    >
      <InputGroup
        disabled={disabled}
        id="fileEventNo"
        intent={form.intents.fileEventNo}
        name="fileEventNo"
        onBlur={form.onBlur}
        onChange={form.onChange}
        placeholder="Police File Number / Event Number"
        value={form.values.fileEventNo || ''}
      />
    </FormGroup>
    <FormGroup
      intent={form.intents.authoriser}
      helperText={form.changed.authoriser ? form.errors.authoriser : null}
      label={<RequiredLabel text="Authoriser Email" />}
      labelFor="authoriser"
    >
      <InputGroup
        disabled={disabled}
        id="authoriser"
        intent={form.intents.authoriser}
        name="authoriser"
        onBlur={form.onBlur}
        onChange={form.onChange}
        placeholder="Email of authorising officer (e.g first.last@police.govt.nz)"
        value={form.values.authoriser || ''}
      />
    </FormGroup>
    <FormGroup
      intent={form.intents.reasonForTracking}
      helperText={
        form.changed.warrant
          ? form.errors.reasonForTracking
          : 'Please select a reason for tracking'
      }
      label={<RequiredLabel text="Reason For Tracking" />}
      labelFor="reasonForTracking"
    >
      <StaticListSelect
        disabled={disabled}
        id="reasonForTracking"
        items={reasonForTrackingOptions}
        onChange={(event) =>
          form.onChangeValue('reasonForTracking', event.currentTarget.value)
        }
        value={form.values.reasonForTracking}
      />
    </FormGroup>
    {form.values.reasonForTracking === 'warrant' && (
      <FormGroup
        intent={form.intents.warrant}
        helperText={form.changed.warrant ? form.errors.warrant : null}
        label={<RequiredLabel text="Warrant Number" />}
        labelFor="warrant"
      >
        <InputGroup
          disabled={disabled}
          id="warrant"
          intent={form.intents.warrant}
          name="warrant"
          onBlur={form.onBlur}
          onChange={form.onChange}
          placeholder="Warrant Number"
          value={form.values.warrant || ''}
        />
      </FormGroup>
    )}
    <FormGroup
      helperText={
        form.changed.offenceCode
          ? form.errors.offenceCode
          : 'Please enter an offence code'
      }
      intent={form.intents.offenceCode}
      label={<RequiredLabel text="Offence Code" />}
      labelFor="offenceCode"
    >
      <AnprOffenceSelect
        onChange={(value) => {
          form.onChangeValue('offenceCode', value);
        }}
        value={form.values.offenceCode}
        inputProps={{
          id: 'offenceCode',
          intent: form.intents.offenceCode,
          name: 'offenceCode',
          required: true
        }}
      />
    </FormGroup>
  </Fragment>
);

export const platesOfInterest: PlatesOfInterestLocalisation = {
  addPlateText: (
    <Fragment>
      <p>
        As per NZ Police&apos;s ANPR Policy (draft), tracking may only be used
        with the appropriate authority:
      </p>
      <ul className={Classes.LIST} style={{ paddingLeft: '15px' }}>
        <li>Under a Tracking Warrant</li>
        <li>Under Section 48 of the Search & Surveillance Act</li>
        <li>
          Where there is a need to prevent or lessen a serious threat to
          someone&apos;s life or a serious threat to public health or public
          safety, but no offence is suspected.
        </li>
      </ul>
    </Fragment>
  ),
  form: {
    confirmText: (
      <Fragment>
        <li>
          I confirm that I have correctly followed any internal policy and have
          the necessary authority or warrant to undertake a lawful search.
        </li>
        <li>
          If required, I will provide a relevant warrant as soon as practicable
          and am aware that my searches may be audited
        </li>
      </Fragment>
    ),
    defaultValues: (values: FormValues) => {
      const extraData = {
        authoriser: '',
        fileEventNo: '',
        offenceCode: '',
        reasonForTracking: '',
        warrant: '',
        ...(values.extra || {})
      };

      return { ...values, ...extraData };
    },
    elements: formElements,
    // Warning: If you update this, you'll need to update the JSON schema
    parseValues: (values: any) => ({
      extra: {
        ...(values.extra || {}),
        authoriser: values.authoriser,
        fileEventNo: values.fileEventNo,
        offenceCode: values.offenceCode,
        reasonForTracking: values.reasonForTracking,
        warrant: values.reasonForTracking === 'warrant' ? values.warrant : ''
      }
    })
  },
  otherDetails: (extra: Record<string, any>) => (
    <Fragment>
      {extra.fileEventNo && (
        <DetailTableRow header="Police File / Event #">
          {extra.fileEventNo}
        </DetailTableRow>
      )}
      {extra.offenceCode && (
        <DetailTableRow header="Offence Code">
          {extra.offenceCode}
        </DetailTableRow>
      )}
      {extra.authoriser && (
        <DetailTableRow header="Authoriser">
          <LinkifiedText tagName="span" text={extra.authoriser} />
        </DetailTableRow>
      )}
      {extra.reasonForTracking && (
        <DetailTableRow header="Reason for Tracking">
          {
            REASON_FOR_TRACKING_MAP_SHORT[
              extra.reasonForTracking as keyof typeof REASON_FOR_TRACKING_MAP_SHORT
            ]
          }
        </DetailTableRow>
      )}
      {extra.warrant && (
        <DetailTableRow header="Warrant #">{extra.warrant}</DetailTableRow>
      )}
    </Fragment>
  ),
  schema: {
    authoriser: {
      validators: [
        {
          message:
            'Authoriser email must be a valid Police email (e.g first.last@police.govt.nz)',
          test: (value) =>
            POLICE_EMAIL.test(value) || SAFERCITIES_EMAIL.test(value)
        },
        {
          message: 'Authoriser email is required',
          test: true
        }
      ]
    },
    fileEventNo: {
      validators: [
        {
          message: 'Case / Event must be longer than 3 characters',
          test: minLength(4)
        },
        {
          message: 'Case / Event must be shorter than 255 characters',
          test: maxLength(255)
        },
        {
          message: 'Case / Event number is required',
          test: true
        }
      ]
    },
    offenceCode: {
      validators: [
        {
          message: 'Offence Code is a required field',
          test: true
        },
        {
          message: 'Offence code must be at least 4 characters',
          test: (value: string): boolean => {
            return value.length > 3;
          }
        }
      ]
    },
    reasonForTracking: {
      dependencies: ['warrant'],
      validators: [
        {
          message: 'Reason for tracking is required',
          test: true
        }
      ]
    },
    warrant: {
      validators: [
        {
          message: 'Warrant number must be shorter than 255 characters',
          test: (value: string, formValues: any) =>
            formValues.reasonForTracking !== 'warrant' || maxLength(255)(value)
        },
        {
          message: 'Warrant number must be longer than 4 characters',
          test: (value: string, formValues: any) =>
            formValues.reasonForTracking !== 'warrant' || minLength(4)(value)
        }
      ]
    }
  }
};
