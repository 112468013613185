import { useQuery } from '@apollo/client';
import { Spinner, SpinnerSize } from '@blueprintjs/core';
import { DocumentNode } from 'graphql';
import { memo, useEffect, useState, FC } from 'react';

import { StaticListSelect } from '../StaticListSelect';

interface Option {
  default?: boolean;
  id: string;
  name: string;
}

interface OptionWithPermissions extends Option {
  _meta: {
    permissions: string[];
  };
}

interface ListFilterSelectProps {
  allOption?: Option;
  disabled?: boolean;
  filtersKey: string;
  id: string;
  large?: boolean;
  onChange: (id: string, replace?: boolean) => void;
  permission?: string;
  query: DocumentNode;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * Skip rendering if there is only one `option` returned from `query`.
   *
   * `onChange` will be run with `allOption` if set.
   * If not, will use the `option` from `query`.
   */
  hideIfSingleOption?: boolean;
  value: string;
}

export const ListFilterSelect: FC<ListFilterSelectProps> = memo(
  ({
    allOption,
    disabled,
    filtersKey,
    id,
    large,
    onChange,
    permission,
    query,
    setLoading,
    hideIfSingleOption,
    value
  }) => {
    const [options, setOptions] = useState<Option[]>(
      allOption ? [allOption] : []
    );
    const { data, loading } = useQuery<{
      [filtersKey: string]: OptionWithPermissions[];
    }>(query, {
      fetchPolicy: 'cache-and-network'
    });

    const [queryLoading, setQueryLoading] = useState<boolean>(true);

    useEffect(() => {
      if (!data || loading) return;

      // eslint-disable-next-line security/detect-object-injection
      const options = data[filtersKey]?.filter(
        (entity) => !permission || entity._meta.permissions.includes(permission)
      );

      // Theoretically we should never get here - its here just in case
      if (options.length === 0) {
        setOptions([]);
        setLoading(false);
        setQueryLoading(false);
        return;
      }

      if (options.length === 1) {
        const opt = hideIfSingleOption ? allOption ?? options[0] : options[0];
        setOptions([opt]);
        onChange(opt.id, true);
        setLoading(false);
        setQueryLoading(false);
        return;
      }

      setOptions(allOption ? [allOption, ...options] : options);

      // when creating/editing plates do not set
      if (!value) {
        onChange(allOption ? allOption.id : options[0].id, true);
      } else {
        const defaultOption = options.find((option) => option.default);
        if (defaultOption) {
          onChange(defaultOption.id, true);
        }
      }

      setLoading(false);
      setQueryLoading(false);
    }, [data, loading]);

    // Loading state is kept internally as we need to wait for the useEffect setOptions change
    if (queryLoading) {
      return (
        <div>
          <Spinner size={SpinnerSize.SMALL} />
        </div>
      );
    }

    if (options.length === 0) {
      return null;
    }

    if (options.length === 1 && hideIfSingleOption) {
      return null;
    }

    return (
      <StaticListSelect
        disabled={disabled || options.length < 2}
        filterable
        id={id}
        items={options}
        large={large ?? false}
        onChange={(event) => onChange(event.currentTarget.value)}
        value={value}
      />
    );
  },
  (prevProps, nextProps) =>
    // TODO Extend prop comparison to rest of props
    prevProps.disabled === nextProps.disabled &&
    prevProps.filtersKey === nextProps.filtersKey &&
    prevProps.large === nextProps.large &&
    prevProps.permission === nextProps.permission &&
    prevProps.query === nextProps.query &&
    prevProps.value === nextProps.value
);
