import { useContext } from 'react';

import { InsightsContext } from './InsightsContext';
import { Insights, Site } from './types';

/**
 *
 */
export function useSites(): Site[] {
  const insights = useContext<Insights>(InsightsContext);

  return insights.sites;
}
