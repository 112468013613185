import {
  createContext,
  createElement,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from 'react';

type NavItem = string | null;
type OpenedAt = number | null;

interface NavigationState {
  item: NavItem;
  openedAt: OpenedAt;
  setItem: Dispatch<SetStateAction<NavItem>>;
  setOpenedAt: Dispatch<SetStateAction<OpenedAt>>;
}

const NavigationContext = createContext<NavigationState>({
  item: null,
  openedAt: null,
  setItem: () => null,
  setOpenedAt: () => null
});

/**
 *
 * @param props
 */
export const NavigationProvider: FC<{ children: ReactNode }> = (props) => {
  const [item, setItem] = useState<NavItem>(null);
  const [openedAt, setOpenedAt] = useState<OpenedAt>(null);

  return createElement(
    NavigationContext.Provider,
    {
      value: {
        item,
        openedAt,
        setItem,
        setOpenedAt
      }
    },
    props.children
  );
};

/**
 *
 */
export function useNavigation(): NavigationState {
  return useContext<NavigationState>(NavigationContext);
}
